import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import AddNewProduct from '../CanvasPages/AddNewProduct';
import { debounce } from 'lodash';
import { priceFormat } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';
import { getHeaderSimpleToken } from '../Utility/utils';
import 'react-toastify/dist/ReactToastify.css';

const CommonHealthEvents = () => {
	const [dataList, setDataList] = useState([]);
	const [dataFilterList, setDataFilterList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const [openCanvas, setOpenCanvas] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [formTitle, setFormTitle] = useState('Agregar registro');

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};
	
	const onlyClose = async () => {
		setOpenCanvas(false);
		setEditItem(null);
	};

	const deleteData = async (id) => {
		setDeleteModal(false);

		const response = await fetch(`${process.env.REACT_APP_API_URL}products/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const status = await response.json();

		if (status.message === 'Ok') {
			var list = [];
			for (var i = 0; i < dataList.length; i++) {
				var item = dataList[i];
				if (item._id !== id) {
					list.push(item);
				}
			}

			toast.success('La operación se realizó con éxito', toastStyle);

			setDataList(list);
			setDataFilterList(list);
			setEditItem(null);
		} else {
			toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
			setEditItem(null);
		}
	};

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = Object.keys(dataList)
				.filter((key) =>
					dataList[key].studentName
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search),
				)
				.map((i) => dataList[i]);
			setDataFilterList(filterResult);
		} else {
			setDataFilterList(dataList);
		}
	}, 700);

	const refreshList = (newItem) => {
		var isEdit = false;
		var list = [];
		for (var i = 0; i < dataList.length; i++) {
			var item = dataList[i];

			if (item._id !== newItem._id) {
				list.push(item);
			} else {
				newItem.id = item.id;
				list.push(newItem);
				isEdit = true;
			}
		}

		if (!isEdit) {
			newItem.id = dataList.length + 1;
			list.push(newItem);
		}

		setDataList(list);
		setDataFilterList(list);
	};

	async function getProductsData() {
		fetch(`${process.env.REACT_APP_API_URL}products`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setDataList(rawData.data);
					setDataFilterList(rawData.data);
				} else {
					setDataList([]);
					setDataFilterList([]);
				}
			});
	}
	useEffect(() => {
		getProductsData();
	}, []);

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Productos</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											delayedHandleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6 h4 text-primary'>
											<i className='fas fa-boxes' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
										<div className='col-6 container d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												id='button-style-3'
												onClick={() => {
													setFormTitle('Agregar registro');
													setOpenCanvas(!openCanvas);
												}}>
												<i className='fas fa-plus me-1'></i>
												Agregar registro
											</button>
											<button
												type='button'
												className='btn btn-outline-info waves-effect waves-light'
												id='export-btn'>
												<i className='fas fa-cloud-download-alt me-1'></i>
												Exportar
											</button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th
														className='sort'
														data-sort='customer_name'></th>
													<th className='sort' data-sort='email'>
														Clave
													</th>
													<th className='sort' data-sort='email'>
														Código
													</th>
													<th
														className='sort text-center'
														data-sort='phone'>
														Nombre
													</th>
													<th
														className='sort text-center'
														data-sort='phone'>
														Descripción
													</th>
													<th
														className='sort text-center'
														data-sort='date'>
														Precio
													</th>
													<th
														className='sort text-center'
														data-sort='status'>
														Stock
													</th>
													<th
														className='sort text-center'
														data-sort='status'>
														Categoría
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Estatus
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(dataList, currentPage, perPage).map(
													(item) => (
														<tr key={item.id + 1}>
															<td>
																<div></div>
															</td>
															<td>
																<div>{item.code}</div>
															</td>
															<td className='text-center'>
																<div>{item.barcode}</div>
															</td>
															<td className='text-center'>
																<div>{item.name}</div>
															</td>
															<td className='text-center'>
																<div>{item.description}</div>
															</td>
															<td className='text-center'>
																<div>
																	{priceFormat(
																		parseInt(item.price),
																	)}
																</div>
															</td>
															<td className='text-center font-weight-bold'>
																<div>
																	<span
																		className={
																			(item.stock >= 6 &&
																				'text-success') ||
																			(item.stock <= 5 &&
																				item.stock >= 1 &&
																				'text-warning') ||
																			(item.stock === 0 &&
																				'text-danger')
																		}>
																		{item.stock}
																	</span>
																</div>
															</td>
															<td className='text-center'>
																<div>{item.category}</div>
															</td>
															<td>
																<div className=' row justify-content-center '>
																	<span
																		id='span-badge'
																		className={
																			(item.status ===
																				'Activo' &&
																				'badge badge-soft-success') ||
																			(item.status ===
																				'Pausado' &&
																				'badge badge-soft-warning') ||
																			(item.status ===
																				'Desactivado' &&
																				'badge badge-soft-danger')
																		}>
																		{item.status}
																	</span>
																</div>
															</td>
															<td className='text-center'>
																<button
																	type='button'
																	className='btn btn-success waves-effect waves-light me-2'
																	id='create-btn'
																	onClick={() => {
																		setEditItem(item);
																		setFormTitle(
																			'Editar registro',
																		);
																		setOpenCanvas(!openCanvas);
																	}}>
																	<i className=' fas fa-pencil-alt me-1' />
																	Editar
																</button>
																<button
																	type='button'
																	className='btn btn-outline-danger waves-effect waves-light me-2'
																	id='create-btn'
																	onClick={() => {
																		setEditItem(item);
																		setDeleteModal(true);
																	}}>
																	<i className='fas fa-trash-alt me-1' />
																	Borrar
																</button>
															</td>
														</tr>
													),
												)}
											</tbody>
										</table>
										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={dataFilterList}
									label='registros'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
								<ToastContainer />
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<AddNewProduct
				onlyClose={onlyClose}
				openCanvas={openCanvas}
				cleanForm={true}
				editItem={editItem}
				refreshList={refreshList}
				tittle={formTitle}
			/>

			<Modal
				isOpen={deleteModal}
				toggle={() => {
					setDeleteModal(false);
					setEditItem(null);
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>Eliminar registro</h5>
					<button
						type='button'
						className='btn-close'
						onClick={() => {
							setDeleteModal(false);
							setEditItem(null);
						}}
						aria-label='Close'></button>
				</div>
				<div className='modal-body'>
					<div className='mb-3'>
						<p>¿Estás seguro de querer eliminar el registro?</p>
					</div>
				</div>
				<div className='modal-footer'>
					<button
						type='button'
						className='btn btn-secondary'
						data-bs-dismiss='modal'
						onClick={() => {
							setDeleteModal(false);
							setEditItem(null);
						}}>
						No
					</button>
					<button
						type='button'
						className='btn btn-primary'
						onClick={() => {
							deleteData(editItem._id);
						}}>
						Si
					</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default CommonHealthEvents;

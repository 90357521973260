import React, { useEffect, useState } from 'react';
import SchoolList from '../DashboardSuper/SchoolList';
import { Container } from 'reactstrap';
import AddNewSchoolAdmin from '../CanvasPages/AddNewSchoolAdmin';
import { getHeaderSimpleToken } from '../Utility/utils';

const CommonSchoolSpace = () => {
	const [openCanvas, setOpenCanvas] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [stateData, setStateData] = useState([]);

	const onlyClose = async () => {
		setOpenCanvas(false);
		setEditItem(null);
	};

	const openForm = async () => {
		setOpenCanvas(true);
		setEditItem(null);
	};

	async function getStateData() {
		fetch(`${process.env.REACT_APP_API_URL}state`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setStateData(rawData.data);
				} else {
					setStateData([]);
				}
			});
	}

	useEffect(() => {
		getStateData();
	}, []);

	return (
		<React.Fragment>
			<div className='page-content'>
				<div
					className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'
					style={{ marginBottom: '12px', display: 'flex', alignItems: 'baseline' }}>
					<div className='span-tittle-text' style={{ fontSize: '28px' }}>
						Escuelas por estado
					</div>
				</div>

				<Container fluid={true}>
					<SchoolList addNewSchool={openForm} />
				</Container>
			</div>

			<AddNewSchoolAdmin
				onlyClose={onlyClose}
				openCanvas={openCanvas}
				cleanForm={true}
				editItem={editItem}
				tittle={'Nueva escuela'}
				stateList={stateData}
			/>
		</React.Fragment>
	);
};

export default CommonSchoolSpace;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Input,
	Form,
	FormFeedback,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { getHeaderSimpleToken } from '../Utility/utils';
import { ToastContainer, toast } from 'react-toastify';

const AddNewGroupLevel = ({ openCanvas, tittle, editItem, refreshList, onlyClose }) => {
	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Por favor, ingrese el nombre'),
		}),
		onSubmit: async () => {
			setLoading(true);

			if (tittle === 'Editar grupo') {
				const formData = new FormData();
				formData.append('name', formik.values.name);

				await axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}grouplevel/${editItem._id}`,
					data: formData,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								name: formik.values.name,
							};

							refreshList(newItem);
							setLoading(false);
							// toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			} else {
				const body = {
					name: formik.values.name,
				};

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}grouplevel`,
					data: body,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							refreshList({
								_id: response.content._id,
								name: formik.values.name
							});
							setLoading(false);
							formik.setValues({});
							formik.resetForm();
							// toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
								toast.warning('Información duplicada', toastStyle);
							} else {
								toast.error('Error al crear', toastStyle);
							}
						}
					})
					.catch((error) => {
						setLoading(false);
						toast.error('Error de conexión', toastStyle);
					});
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);

		if (editItem !== null) {
			formik.setValues({
				id: editItem._id,
				name: editItem.name,
			});
		}
	}, [openCanvas]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			style={{
				height: '50%',
				width: '35%',
				top: '50%',
			}}
			id='off-canvas-teacher'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
									<CardBody>
										<div className='row'>
											<div className='col-12'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
							</Card>
						</div>
					</div>
				</Container>

				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewGroupLevel.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewGroupLevel.defaultProps = {
	openCanvas: false,
};

export default AddNewGroupLevel;

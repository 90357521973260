import { Container, Row, Col, Card, CardBody, CardHeader, Input } from 'reactstrap';
import React, { useEffect, useState } from "react";
import { getHeaderSimpleToken } from '../Utility/utils';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import CalendarTeacherSchedule from '../CanvasPages/CalendarTeacherSchedule';

export const CommonTeachersSchedule = () => {

	const [dataFilterList, setDataFilterList] = useState([]);
	const [formTitle, setFormTitle] = useState('Agregar registro');
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const [openCanvas, setOpenCanvas] = useState(false);
	const [editItem, setEditItem] = useState(null);

	const onlyClose = async () => {
		setOpenCanvas(false);
		setEditItem(null);
	};


	const getTeachersData = async () => {

		const response = await fetch(`${process.env.REACT_APP_API_URL}teacherSchedule/getTeacherScheduleStatusList`, {
			headers: getHeaderSimpleToken(),
		});

		const { message, data } = await response.json();
		if (message === 'Ok') {

			setDataFilterList(data);
		}

	}
	const handleSearch = async (value) => {

		const response = await fetch(`${process.env.REACT_APP_API_URL}teacherSchedule/getTeachersByName?name=${value}`, {
			headers: getHeaderSimpleToken(),
		});

		const { message, data } = await response.json();
		if (message === 'Ok') {
			setDataFilterList(data);
		}
	}


	useEffect(() => {
		getTeachersData();
	}, []);

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Docentes</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											handleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-10  h4 text-primary '>
											<i className='fas fa-chalkboard-teacher	' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
									
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th
														className='sort text-center'
														data-sort='customer_name'>
														No.
													</th>
													<th className='sort' data-sort='phone'>
														Nombre del docente
													</th>
													<th className='sort text-center' data-sort='date'>
														Horario asignado
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(
													dataFilterList,
													currentPage,
													perPage,
												).map((item, idx) => (
													<tr key={item._id}>
														<td className='text-center'>
															<div>{idx + 1}</div>
														</td>
														<td>
															<div className=''>
																<div className='ms-2'>
																	{`${item.name} ${item.lastName}`}
																</div>
															</div>
														</td>
														<td>
															<div className='text-center'>{item.hasSchedule ? "Si" : "No"}</div>
														</td>

														<td className='text-center'>
															<button
																type='button'
																className='btn btn-success waves-effect waves-light'
																id='create-btn'
																onClick={() => {
																	setEditItem(item);
																	setFormTitle('Editar registro');
																	setOpenCanvas(!openCanvas);
																}}>
																<i className='fas fa-pencil-alt me-1' />
																Editar
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={dataFilterList}
									label='registros'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			{
				editItem && (
					<CalendarTeacherSchedule
						onlyClose={onlyClose}
						openCanvas={openCanvas}
						tittle={formTitle}
						teacherId={editItem._id}
					/>)
			}
		</React.Fragment>

	);
}
import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Instituciones pages

import SuperDashboard from "../Pages/CommonPages/CommonSuperDashboard";
import CommonSchoolSpace from "../Pages/CommonPages/CommonSchoolSpace";
import CommonEducativeOfferSpace from "../Pages/CommonPages/CommonEducativeOfferSpace";

import Personnel from "../Pages/CommonPages/CommonPersonnelEvents";
import Schools from "../Pages/CommonPages/CommonSchoolsEvents";
import EducativeOffer from "../Pages/CommonPages/CommonEducativeOfferEvents";
import Students from "../Pages/CommonPages/CommonStudentsEvents";
import Parents from "../Pages/CommonPages/CommonParentsEvents";
import Teachers from "../Pages/CommonPages/CommonTeachersEvents";
import Markets from "../Pages/CommonPages/CommonMarketsEvents";
import Health from "../Pages/CommonPages/CommonHealthEvents";
import Products from "../Pages/CommonPages/CommonProductsEvents";
import Pos from "../Pages/POS/PosPage";
import Grades from "../Pages/CommonPages/CommonSchoolGradesEvents";
import Settings from "../Pages/CommonPages/CommonSettingsEvents";
import CommonStudentGrades from '../Pages/CommonPages/CommonStudentGrades';

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";
import { CommonTeachersSchedule } from "../Pages/CommonPages/CommonTeachersSchedule";
import ComingSoonTwo from "../Pages/Utility/ComingSoon-Two-Page";

const superAdminRoutes = [
  { path: "/dashboardAdmin", component: <SuperDashboard /> },
  { path: "/adminSchool", component: <CommonSchoolSpace /> },
  { path: "/adminEducativeOffer", component: <CommonEducativeOfferSpace /> },
  { path: "/adminPersonnel", component: <ComingSoonTwo /> },
];

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Instituciones
  { path: "/personnel", component: <Personnel /> },
  { path: "/schools", component: <Schools /> },
  { path: "/educativeOffer", component: <EducativeOffer /> },
  { path: "/students", component: <Students /> },
  { path: "/parents", component: <Parents /> },
  { path: "/teachers", component: <Teachers /> },
  { path: "/markets", component: <Markets /> },
  { path: "/health", component: <Health /> },
  { path: "/products", component: <Products /> },
  { path: "/pos", component: <Pos /> },
  { path: "/schoolgrades", component: <Grades /> },
  { path: "/settings", component: <Settings /> },
  {path: "/teacherSchedules", component: <CommonTeachersSchedule /> },
  { path: "/grades", component: <CommonStudentGrades />, },
  { path: "/users", component: <ComingSoonTwo />, },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { superAdminRoutes, authProtectedRoutes, publicRoutes };

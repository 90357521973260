/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeLayout } from '../../store/actions';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	Input,
	Spinner,
	FormFeedback,
	Form,
} from 'reactstrap';
import PropTypes, { number } from 'prop-types';
import axios from 'axios';
import { getHeaderSimpleToken } from '../Utility/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//constants
import { layoutTypes } from '../../constants/layout';

const AddNewEducativeOfferAdmin = (props) => {
	const navigate = useNavigate();
	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			sepCode: '',
			educativeOfferType: '',
			turn: '',
			studentAmount: '',
			teacherAmount: '',
			classroomAmount: '',
			cooperativeAmount: '',
			numberOfExams: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Por favor, ingrese el nombre'),
			sepCode: Yup.string().required('Por favor, ingrese la clave SEP'),
			educativeOfferType: Yup.string().required('Por favor, seleccione una oferta educativa'),
			turn: Yup.string().required('Por favor, seleccione un turno'),
		}),
		onSubmit: () => {
			if (props.tittle === 'Editar registro') {
				onUpdate();
			} else {
				onCreate();
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(props.openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.openCanvas]);

	const onCreate = async () => {
		setLoading(true);
		let educationOfferId = '';
		let turnId = '';

		for (var q = 0; q < props.educationOfferType.length; ++q) {
			var itemQ = props.educationOfferType[q];

			if (itemQ.name === formik.values.educativeOfferType) {
				educationOfferId = itemQ._id;
				q = props.educationOfferType.length;
			}
		}

		for (var w = 0; w < props.turn.length; ++w) {
			var itemW = props.turn[w];

			if (itemW.name === formik.values.turn) {
				turnId = itemW._id;
				w = props.turn.length;
			}
		}

		const body = {
			name: formik.values.name,
			educationOfferId: educationOfferId,
			educationOfferType: formik.values.educativeOfferType,
			turnId: turnId,
			turn: formik.values.turn,
			sepCode: formik.values.sepCode,
			studentAmount: formik.values.studentAmount,
			teacherAmount: formik.values.teacherAmount,
			classroomAmount: formik.values.classroomAmount,
			cooperativeAmount: formik.values.cooperativeAmount,
			numberOfExams: formik.values.numberOfExams,
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}educationoffer`,
			data: body,
			headers: getHeaderSimpleToken(),
		})
			.then((response) => {
				if (response.message === 'Ok') {
					setLoading(false);
					formik.resetForm();
					formik.setValues({});
					toggleRightCanvas();

					localStorage.setItem('EDUCATIVE_OFFER_ID', response.content._id);
					localStorage.setItem('EDUCATIVE_OFFER_NAME', response.content.name);
					localStorage.setItem('EDUCATIVE_OFFER_TYPE', response.content.educationOfferType);
					localStorage.setItem('USER_TENANT', response.content.tenant);

					props.changeLayout(layoutTypes.VERTICAL);
					navigate('/dashboard');
				} else {
					if (response.message === 'Información duplicada') {
						setLoading(false);
						toast.warning('Información duplicada', toastStyle);
					} else {
						toast.error('Error al crear', toastStyle);
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Error de conexión', toastStyle);
			});

		setLoading(false);
	};

	const onUpdate = async () => {
		setLoading(true);
		const body = {
			//schoolId: schoolSelected._id,
			//educationOffer: educationOfferList,
		};

		axios({
			method: 'PUT',
			url: `${process.env.REACT_APP_API_URL}educationoffer/${props.editItem._id}`,
			data: body,
			headers: getHeaderSimpleToken(),
		})
			.then((response) => {
				if (response.message === 'Ok') {
					setLoading(false);
					formik.resetForm();
					formik.setValues({});
					toggleRightCanvas();
				} else {
					if (response.message === 'Información duplicada') {
						setLoading(false);
						toast.warning('Información duplicada', toastStyle);
					} else {
						toast.error('Error al crear', toastStyle);
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Error de conexión', toastStyle);
			});

		setLoading(false);
	};

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				props.onlyClose();
			}}
			id='off-canvas-education-offer'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					props.onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{props.tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Form
								className='form-horizontal'
								onSubmit={(e) => {
									e.preventDefault();
									formik.handleSubmit();
									return false;
								}}>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div className='row'>
											<div className='col-12 mt-0'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name &&
														formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-12 mt-0'>
												<label
													htmlFor='sepCode'
													className='col-form-label text-muted'>
													Clave SEP
												</label>
												<Input
													className='form-control'
													type='text'
													id='sepCode'
													name='sepCode'
													value={formik.values.sepCode}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.sepCode &&
														formik.errors.sepCode
															? true
															: false
													}
												/>
												{formik.touched.sepCode && formik.errors.sepCode ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.sepCode}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-6'>
												<label className='col-form-label text-muted'>
													Oferta educativa
												</label>
												<Input
													name='educativeOfferType'
													type='select'
													value={formik.values.educativeOfferType}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.educativeOfferType &&
														formik.errors.educativeOfferType
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{props.educationOfferType.map((i) => (
														<option key={i._id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.educativeOfferType &&
												formik.errors.educativeOfferType ? (
													<FormFeedback type='invalid'>
														<div>
															{formik.errors.educativeOfferType}
														</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-6 mt-0'>
												<label className='col-form-label text-muted'>
													Turno (s)
												</label>
												<Input
													name='turn'
													type='select'
													value={formik.values.turn}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.turn && formik.errors.turn
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{props.turn.map((i) => (
														<option key={i._id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.turn && formik.errors.turn ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.turn}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-3 mt-0'>
												<label
													htmlFor='studentAmount'
													className='col-form-label text-muted'>
													No. Alumnos
												</label>
												<Input
													className='form-control'
													type='number'
													id='studentAmount'
													name='studentAmount'
													value={formik.values.studentAmount}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.studentAmount &&
														formik.errors.studentAmount
															? true
															: false
													}
												/>
												{formik.touched.studentAmount &&
												formik.errors.studentAmount ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.studentAmount}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='teacherAmount'
													className='col-form-label text-muted'>
													No. Docentes
												</label>
												<Input
													className='form-control'
													type='number'
													id='teacherAmount'
													name='teacherAmount'
													value={formik.values.teacherAmount}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.teacherAmount &&
														formik.errors.teacherAmount
															? true
															: false
													}
												/>
												{formik.touched.teacherAmount &&
												formik.errors.teacherAmount ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.teacherAmount}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='classroomAmount'
													className='col-form-label text-muted'>
													No. Aulas
												</label>
												<Input
													className='form-control'
													type='number'
													id='classroomAmount'
													name='classroomAmount'
													value={formik.values.classroomAmount}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.classroomAmount &&
														formik.errors.classroomAmount
															? true
															: false
													}
												/>
												{formik.touched.classroomAmount &&
												formik.errors.classroomAmount ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.classroomAmount}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='cooperativeAmount'
													className='col-form-label text-muted'>
													No. Cooperativas
												</label>
												<Input
													className='form-control'
													type='number'
													id='cooperativeAmount'
													name='cooperativeAmount'
													value={formik.values.cooperativeAmount}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.cooperativeAmount &&
														formik.errors.cooperativeAmount
															? true
															: false
													}
												/>
												{formik.touched.cooperativeAmount &&
												formik.errors.cooperativeAmount ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.cooperativeAmount}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='numberOfExams'
													className='col-form-label text-muted'>
													No. Parciales
												</label>
												<Input
													className='form-control'
													type='number'
													id='numberOfExams'
													name='numberOfExams'
													value={formik.values.numberOfExams}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.numberOfExams &&
														formik.errors.numberOfExams
															? true
															: false
													}
												/>
												{formik.touched.numberOfExams &&
												formik.errors.numberOfExams ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.numberOfExams}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
								</Card>

								<div
									className='d-flex justify-content-center'
									style={{ marginBottom: '30px' }}>
									{loading ? (
										<div id='div-style-7'>
											<Spinner id='spinner-style-1' size='lg' />
										</div>
									) : (
										<button
											type='submit'
											className='btn btn-success waves-effect waves-light w-50'
											id='create-btn'>
											<i className='fas fa-save me-1' />
											{props.tittle === 'Editar registro'
												? 'Actualizar'
												: 'Guardar'}
										</button>
									)}
								</div>
							</Form>
						</div>
					</div>
				</Container>

				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewEducativeOfferAdmin.propTypes = {
	changeLayout: PropTypes.func,
};

const mapStateToProps = (state) => {
	return { ...state.Layout };
};

export default connect(mapStateToProps, {
	changeLayout,
})(AddNewEducativeOfferAdmin);
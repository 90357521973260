import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Row, Col, Alert, Form, Input, FormFeedback, Spinner } from 'reactstrap';

//redux
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import withRouter from '../../../components/Common/withRouter';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { getHeaderSimple } from '../../Utility/utils';

const LoginForm = (props) => {
	document.title = 'Kuali | Login';

	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Por favor, ingresa tu email'),
			password: Yup.string().required('Por favor, ingresa tu contraseña'),
		}),
		onSubmit: async (values) => {
			setLoading(true);
			const body = {
				email: validation.values.email,
				password: validation.values.password,
			};

			const response = await fetch(`${process.env.REACT_APP_API_URL}auth/singin`, {
				method: 'POST',
				headers: getHeaderSimple(),
				body: JSON.stringify(body),
			});
			const json = await response.json();

			if (json.message === 'Ok') {
				validation.resetForm();
				setLoading(false);

				localStorage.setItem('USER_TOKEN', json.content.token);
				localStorage.setItem('USER_ID', json.content.userId);
				localStorage.setItem('USER_FULL_NAME', json.content.userFullName);
				localStorage.setItem('USER_IMAGE', json.content.userImage);
				localStorage.setItem('USER_INSTITUTE_TENANT', json.content.instituteTenant);

				values.email = 'admin@Themesdesign.com';
				values.password = '123456';
				// dispatch(loginUser(values, props.router.navigate));

				localStorage.setItem(
					'authUser',
					JSON.stringify({
						uid: 1,
						username: 'admin',
						role: 'admin',
						password: '123456',
						email: 'admin@Themesdesign.com',
					}),
				);
				// props.router.navigate('/dashboard');
				props.router.navigate('/dashboardAdmin');
			} else {
				setLoading(false);
			}
		},
	});

	const { error } = useSelector((state) => ({
		error: state.login.error,
	}));

	return (
		<div>
			<p id='p-style-3'>¡Hola de nuevo!</p>
			<p id='p-style-4'>Ingresa tus datos para continuar</p>
			<Form
				className='form-horizontal'
				onSubmit={(e) => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
				}}>
				{error ? (
					<Alert color='danger'>
						<div>{error}</div>
					</Alert>
				) : null}
				<Row>
					<Col md={12}>
						<div className='mb-4' id='div-style-6'>
							<text id='text-style-1'>Usuario</text>
							<Input
								name='email'
								className='form-control'
								placeholder='¿Cuál es tu usuario?'
								type='email'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.email || ''}
								invalid={
									validation.touched.email && validation.errors.email
										? true
										: false
								}
								id='input-style-1'
							/>
							{validation.touched.email && validation.errors.email ? (
								<FormFeedback type='invalid'>
									<div>{validation.errors.email}</div>
								</FormFeedback>
							) : null}
						</div>
						<div className='mb-1 d-flex' id='div-style-6'>
							<div className='col-11'>
								<text id='text-style-1'>Contraseña</text>
								<Input
									name='password'
									value={validation.values.password || ''}
									type={showPassword ? 'text' : 'password'}
									placeholder='Escribe tu contraseña'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={
										validation.touched.password && validation.errors.password
											? true
											: false
									}
									id='input-style-1'
								/>
								{validation.touched.password && validation.errors.password ? (
									<FormFeedback type='invalid'>
										<div> {validation.errors.password} </div>
									</FormFeedback>
								) : null}
							</div>

							<div
								className='col-1'
								id='div-style-7'
								onClick={() => {
									setShowPassword(!showPassword);
								}}>
								<i
									className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
									id='icon-style-1'
								/>
							</div>
						</div>

						<Col className='col-12'>
							<div className='text-md-end'>
								<Link to='/auth-recoverpw' id='p-style-5'>
									Olvidé mi contraseña
								</Link>
							</div>
						</Col>
						<div className='d-grid' id='div-style-2'>
							{loading ? (
								<div id='div-style-7'>
									<Spinner id='spinner-style-1' size='lg' />
								</div>
							) : (
								<button
									className='btn waves-effect waves-light'
									id='button-style-1'
									type='submit'>
									Iniciar Sesión
								</button>
							)}
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default withRouter(LoginForm);

LoginForm.propTypes = {
	history: PropTypes.object,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	CardImg,
	Input,
	Form,
	FormFeedback,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import avatarDefault from '../../assets/images/users/AdminAvatar.png';
import { getHeaderMultipart } from '../Utility/utils';
import moment from 'moment';
import { DegreeData } from '../../CommonData/Data/dashboard'

const AddNewTeacher = ({ openCanvas, tittle, editItem, refreshList, onlyClose, genderData }) => {
	const [isRight, setIsRight] = useState(false);
	const [avatarImage, setAvatarImage] = useState('');
	const avatarImageUpload = () => {
		// eslint-disable-next-line no-unused-expressions
		document.getElementById('file').files[0];
		setAvatarImage(URL.createObjectURL(document.getElementById('file').files[0]));
	};
	const [loading, setLoading] = useState(false);

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const formik = useFormik({
		initialValues: {
			name: '',
			lastname: '',
			phone: '',
			email: '',
			gender: '',
			birthdate: '',
			position: '',
			degree: '',
			startdate: '',
			typejob: '',
			shift: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Por favor, ingrese el nombre'),
			lastname: Yup.string().required('Por favor, ingrese su apellido'),
			phone: Yup.string()
				.matches(phoneRegExp, 'No es un número de teléfono válido')
				.required('Por favor ingrese un número de teléfono válido'),
			email: Yup.string()
				.required('Se requiere ingresar un correo válido')
				.email('El formato de correo no es correcto'),
			gender: Yup.string().required('Por favor, ingrese el género'),
			birthdate: Yup.string().required('Por favor, ingrese la fecha de nacimiento'),
			position: Yup.string().required('Por favor, ingrese el cargo'),
			degree: Yup.string().required('Por favor, ingrese el grado académico'),
			startdate: Yup.string().required('Por favor, ingrese el día de inicio de labores'),
			typejob: Yup.string().required('Por favor, ingrese el tipo de plaza'),
			shift: Yup.string().required('Por favor, ingrese el turno'),
		}),
		onSubmit: () => {
			setLoading(true);

			if (tittle === 'Editar registro') {
				const formData = new FormData();
				formData.append('name', formik.values.name);
				formData.append('lastName', formik.values.lastname);
				formData.append('phone', formik.values.phone);
				formData.append('email', formik.values.email);
				formData.append('gender', formik.values.gender);
				formData.append('birthdate', formik.values.birthdate);
				formData.append('typeJob', formik.values.typejob);
				formData.append('turn', formik.values.shift);
				formData.append('position', formik.values.position);
				formData.append('degree', formik.values.degree);
				formData.append('startDate', formik.values.startdate);

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}teacher/${editItem._id}`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								phone: formik.values.phone,
								email: formik.values.email,
								gender: formik.values.gender,
								birthdate: formik.values.birthdate,
								typeJob: formik.values.typejob,
								turn: formik.values.shift,
								position: formik.values.position,
								degree: formik.values.degree,
								startDate: formik.values.startdate,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			} else {
				const formData = new FormData();
				formData.append('name', formik.values.name);
				formData.append('lastName', formik.values.lastname);
				formData.append('phone', formik.values.phone);
				formData.append('email', formik.values.email);
				formData.append('gender', formik.values.gender);
				formData.append('birthdate', formik.values.birthdate);
				formData.append('typeJob', formik.values.typejob);
				formData.append('turn', formik.values.shift);
				formData.append('position', formik.values.position);
				formData.append('degree', formik.values.degree);
				formData.append('startDate', formik.values.startdate);

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}teacher`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								_id: response.content._id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								phone: formik.values.phone,
								email: formik.values.email,
								gender: formik.values.gender,
								birthdate: formik.values.birthdate,
								typeJob: formik.values.typejob,
								turn: formik.values.shift,
								position: formik.values.position,
								degree: formik.values.degree,
								startDate: formik.values.startdate,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		setAvatarImage(avatarDefault);

		if (editItem !== null) {
			formik.setValues({
				id: editItem._id,
				name: editItem.name,
				lastname: editItem.lastName,
				phone: editItem.phone,
				email: editItem.email,
				gender: editItem.gender,
				birthdate: moment(editItem.birthDate).format(moment.HTML5_FMT.DATE),
				position: editItem.position,
				degree: editItem.degree,
				startdate: moment(editItem.startDate).format(moment.HTML5_FMT.DATE),
				typejob: editItem.typeJob,
				shift: editItem.turn,
			});
		}
	}, [openCanvas]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-teacher'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<CardBody>
									<div className='row d-flex justify-content-between'>
										<div className='col-2'>
											<CardImg
												id='img-bgc-g'
												src={avatarImage}
												alt='Kuali'
												className='rounded avatar-lg'
											/>
										</div>
										<div className='col-9'>
											<div className='customfile'>
												<Input
													type='file'
													name='file'
													className='inputfile'
													id='file'
													accept='image/jpg, image/jpeg, image/png'
													lang='es'
													onChange={(e) => {
														// eslint-disable-next-line no-unused-expressions
														formik.setFieldValue(
															'avatar',
															e.currentTarget.files[0],
														);
														avatarImageUpload();
													}}
												/>
											</div>
											<div className='mt-2'>
												<span>
													Colocar la imagen ayuda a aumentar la seguridad
													en la institución.
												</span>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<Card id='card-into-off-canvas'>
								<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
									<CardBody>
										<div className='row'>
											<div>
												<h4>Datos personales</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='lastname'
													className='col-form-label text-muted'>
													Apellidos
												</label>

												<Input
													className='form-control'
													type='text'
													id='lastname'
													name='lastname'
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.lastname &&
														formik.errors.lastname
															? true
															: false
													}
												/>
												{formik.touched.lastname &&
												formik.errors.lastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.lastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>

												<Input
													className='form-control'
													type='tel'
													id='phone'
													name='phone'
													value={formik.values.phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.phone && formik.errors.phone
															? true
															: false
													}
												/>
												{formik.touched.phone && formik.errors.phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='email'
													className='col-form-label text-muted'>
													Correo
												</label>

												<Input
													className='form-control'
													type='text'
													id='email'
													name='email'
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.email && formik.errors.email
															? true
															: false
													}
												/>
												{formik.touched.email && formik.errors.email ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.email}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													className='col-form-label text-muted'>
													Sexo
												</label>
												<Input
													type='select'
													name='gender'
													value={formik.values.gender}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.gender &&
														formik.errors.gender
															? true
															: false
													}
												>
													<option value=''>Selecciona</option>
													{genderData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.gender && formik.errors.gender ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.gender}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='age'
													className='col-form-label text-muted'>
													Fecha de nacimiento
												</label>
												<Input
													className='form-control'
													type='date'
													id='example-date-input'
													name='birthdate'
													value={formik.values.birthdate}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.birthdate &&
														formik.errors.birthdate
															? true
															: false
													}
												/>
												{formik.touched.birthdate &&
												formik.errors.birthdate ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.birthdate}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='mt-3'>
												<h4>Datos laborales</h4>
											</div> 
											<div className='col-4 mt-0'>
												<label
													htmlFor='typejob'
													className='col-form-label text-muted'>
													Tipo de plaza
												</label>
												<Input
													className='form-control'
													type='text'
													id='typejob'
													name='typejob'
													value={formik.values.typejob}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.typejob &&
														formik.errors.typejob
															? true
															: false
													}
												/>
												{formik.touched.typejob && formik.errors.typejob ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.typejob}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='shift'
													className='col-form-label text-muted'>
													Turno
												</label>
												<Input
													className='form-control'
													type='text'
													id='shift'
													name='shift'
													value={formik.values.shift}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.shift && formik.errors.shift
															? true
															: false
													}
												/>
												{formik.touched.shift && formik.errors.shift ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.shift}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='position'
													className='col-form-label text-muted'>
													Cargo
												</label>
												<Input
													className='form-control'
													type='text'
													id='position'
													name='position'
													value={formik.values.position}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.position &&
														formik.errors.position
															? true
															: false
													}
												/>
												{formik.touched.position &&
												formik.errors.position ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.position}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													className='col-form-label text-muted'>
													Grado académico
												</label>
												<Input
													name='degree'
													type='select'
													value={formik.values.degree}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.degree &&
														formik.errors.degree
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{DegreeData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.degree && formik.errors.degree ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.degree}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='startdate'
													className='col-form-label text-muted'>
													Fecha de inicio
												</label>
												<Input
													className='form-control'
													type='date'
													id='startdate'
													name='startdate'
													value={formik.values.startdate}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.startdate &&
														formik.errors.startdate
															? true
															: false
													}
												/>
												{formik.touched.startdate &&
												formik.errors.startdate ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.startdate}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
							</Card>
						</div>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewTeacher.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewTeacher.defaultProps = {
	openCanvas: false,
};

export default AddNewTeacher;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { priceFormat } from '../../helpers/helpers';
import {
	Container,
	Card,
	CardBody,
	Form,
	CardImg,
	Input,
	Col,
	Row,
	Badge,
	Button,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import avatar4 from '../../assets/images/users/uknownuser.jpg';
import { getHeaderSimpleToken } from '../Utility/utils';
import SearchProducts from '../Components/SearchProducts';
import AddNewSale from '../CanvasPages/AddNewSale';

const PosPage = () => {
	const [productResult, setProductResult] = useState([]);
	const [productList, setProductList] = useState([]);
	const [studentResult, setStudentResult] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState({});
	const [updateFlag, setUpdateFlag] = useState(0);
	const [actualStudentAllergies, setActualStudentAllergies] = useState([]);
	const [actualStudentLockedProducts, setActualStudentLockedProducts] = useState([]);
	const [saleTotal, setSaleTotal] = useState(0);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [activeOrder, setActiveOrder] = useState([]);
	const [isOrderProcessing, setIsOrderProcessing] = useState(false);
	const formatter = new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
		minimumFraction: 2,
	});

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	let selectdItem = [];

	const getStudentLockedProducts = (id) => {
		try {
			fetch(`${process.env.REACT_APP_API_URL}lockedProducts/${id}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					const lockedList = [];
					const lockedObjects = json[0]?.lockedProducts;

					//Recorrer items del objeto
					for (const key in lockedObjects) {
						if (lockedObjects.hasOwnProperty(key)) {
							const item = lockedObjects[key];
							lockedList.push(item);
						}
					}

					setActualStudentLockedProducts(lockedList);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const getStudentAlergies = (id) => {
		try {
			fetch(`${process.env.REACT_APP_API_URL}student/health/${id}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					setActualStudentAllergies(json.data[0]?.allergies);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const getActiveOrders = () => {
		try {
			fetch(`${process.env.REACT_APP_API_URL}activeOrders/${selectedStudent._id}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					extractOrderItems(json);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const extractOrderItems = (data) => {
		// Verificar que 'data' tiene al menos un elemento y que el primer elemento tiene 'orderList'
		if (data.length > 0 && data[0].hasOwnProperty('orderList')) {
			const orderList = data[0].orderList;
			const items = [];

			// Iterar sobre las claves de 'orderList'
			for (const key in orderList) {
				if (orderList.hasOwnProperty(key)) {
					const item = orderList[key];
					console.log('item', item);
					item.isOrder = true; // Marcar el item como parte de una orden activa
					items.push(item); // Agregar el item a la lista
				}
			}
			setActiveOrder(items);
		} else {
			return []; // Devolver un arreglo vacío si no se encuentra 'orderList'
		}
	};

	const processActiveOrder = () => {
		setProductList(activeOrder);
		setIsOrderProcessing(true);
	};

	const onSelectedProduct = (value) => {
		//Comprobar si el id del producto existe en la lista de productos bloqueados
		const isBlocked = actualStudentLockedProducts.some((item) => item.productId === value._id);

		if (isBlocked) {
			toast.warning('Producto bloqueado para el usuario actual', toastStyle);
			return;
		}
		selectdItem = productList;
		selectdItem.push({
			_id: value._id,
			id: selectdItem.length + 1,
			code: value.code,
			item: value.name,
			quantity: 1,
			cost: value.price,
			status: value.status,
			isOrder: false,
		});
		setProductList(selectdItem);
		setUpdateFlag(updateFlag + 1);
		updateSaleTotal();
	};

	const onSelectedStudent = (value) => {
		resetData();
		setSelectedStudent(value);
		getStudentAlergies(value._id);
		getStudentLockedProducts(value._id);
	};

	const updateSaleTotal = () => {
		setSaleTotal(productList.reduce((acc, item) => acc + item.quantity * item.cost, 0));
	};

	const updateProductQuantity = (id, quantity) => {
		const newProductList = productList.map((item) => {
			if (item.id === id && (quantity > 0 || item.quantity > 1)) {
				quantity > 0 ? item.quantity++ : item.quantity--;
			}
			return item;
		});

		console.log('npl', newProductList);
		setProductList(newProductList);
	};

	const deleteProduct = (id) => {
		console.log('id', id);
		const newProductList = productList.filter((item) => item.id !== id);
		setProductList(newProductList);
	};

	const resetData = () => {
		setProductList([]);
		setSaleTotal(0);
		setActiveOrder([]);
		setIsOrderProcessing(false);
	};

	const delayedHandleChange = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}products/pos/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setProductResult(json.data);
					} else {
						setProductResult([]);
					}
				});
		}
	}, 700);

	const handleStudentSearch = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}student/search/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setStudentResult(json.data);
					} else {
						setStudentResult([]);
					}
				});
		}
	}, 700);

	useEffect(() => {
		if (selectedStudent._id === undefined) return;
		getActiveOrders();
	}, [selectedStudent]);

	useEffect(() => {
		updateSaleTotal();
	}, [productList]);

	return (
		<React.Fragment>
			<div className='page-content p-10' id='div-page-content'>
				<Container fluid>
					<div id='div-container'>
						<Form className='form-horizontal d-flex'>
							<Col lg={9} xl={9} xxl={9} className={'mx-2'}>
								<Row>
									<div className='d-flex mb-2'>
										<SearchProducts
											delayedHandleChange={delayedHandleChange}
											list={productResult}
											columns={'12'}
											onSelect={onSelectedProduct}
											placeholder={'Buscar producto...'}
										/>
									</div>
									<div className='col-12'>
										{/* ==========CARD DE TABLA DE PRODUCTOS==========*/}
										<Card id='card-principal-pos'>
											<CardBody>
												<div>
													<h4>Productos</h4>
												</div>
												<div
													className='table-responsive table-card mb-1'
													style={{ overflowY: 'auto', height: '530px' }}>
													<table
														className='table align-middle table-nowrap'
														id='customerTable'>
														<thead className='table-light'>
															<tr>
																<th
																	className='sort'
																	data-sort='customer_name'>
																	No.
																</th>
																<th
																	className='sort'
																	data-sort='phone'>
																	Producto
																</th>
																<th
																	className='sort'
																	data-sort='email'>
																	Código
																</th>
																<th
																	className='sort text-center'
																	data-sort='date'>
																	Cantidad
																</th>
																<th
																	className='sort text-center'
																	data-sort='status'>
																	Precio
																</th>
																<th
																	className='sort text-center'
																	data-sort='action'>
																	Estatus
																</th>
																<th
																	className='sort text-center'
																	data-sort='status'>
																	Total
																</th>
																<th
																	className='sort text-center'
																	data-sort='action'>
																	Acciones
																</th>
															</tr>
														</thead>
														<tbody className='list form-check-all'>
															{productList.map((item) => (
																<tr key={item.id}>
																	{console.log(item)}
																	<td>
																		<div>{item.id}</div>
																	</td>
																	<td>
																		<div>{item.item}</div>
																	</td>
																	<td>
																		<div>{item.code}</div>
																	</td>
																	<td className='text-center'>
																		<div className='d-flex justify-content-center'>
																			<div id='div-button-cell-r'>
																				<button
																					type='button'
																					id='button-cell-rounded'
																					className=' waves-effect waves-light'
																					disabled={
																						item.isOrder
																					}
																					onClick={() => {
																						updateProductQuantity(
																							item.id,
																							-1,
																						);
																					}}>
																					<i className='fas fa-minus' />
																				</button>
																			</div>
																			<div
																				style={{
																					width: '50px',
																				}}>
																				<Input
																					name='quantity'
																					className='form-control text-center'
																					type='number'
																					disabled={
																						item.isOrder
																					}
																					value={
																						item.quantity
																					}
																					id='input-payment-style'
																				/>
																			</div>
																			<div id='div-button-cell-l'>
																				<button
																					type='button'
																					id='button-cell-rounded'
																					className='waves-effect waves-light'
																					disabled={
																						item.isOrder
																					}
																					onClick={() => {
																						updateProductQuantity(
																							item.id,
																							1,
																						);
																					}}>
																					<i className=' fas fa-plus' />
																				</button>
																			</div>
																		</div>
																	</td>
																	<td className='text-center'>
																		<div>
																			{priceFormat(item.cost)}
																		</div>
																	</td>
																	<td className='text-center'>
																		<div>
																			{item.status ===
																			'active' ? (
																				<Badge color='success'>
																					Permitido
																				</Badge>
																			) : (
																				<Badge color='danger'>
																					Prohibido
																				</Badge>
																			)}
																		</div>
																	</td>
																	<td className='text-center'>
																		<div>
																			{priceFormat(
																				item.quantity *
																					item.cost,
																			)}
																		</div>
																	</td>
																	<td className='text-center'>
																		{!item.isOrder ? (
																			<button
																				type='button'
																				className='btn btn-outline-danger waves-effect waves-light'
																				id='create-btn'
																				onClick={() => {
																					deleteProduct(
																						item.id,
																					);
																				}}>
																				<i className='fas fa-trash-alt' />
																			</button>
																		) : (
																			''
																		)}
																	</td>
																</tr>
															))}
														</tbody>
													</table>
													<div className='noresult d-none'>
														<div className='text-center'>
															<h5 className='mt-2'>
																No se encontraron resultados
															</h5>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</Row>
								{/* ==========CARDS DE ALERGIAS Y BLOQUEOS==========*/}
								<Row className={'mt-3'}>
									<div
										className='col-12 d-flex'
										id='div-secondary-card-container'>
										<div id='div-secundary-info'>
											<Card id='card-secundary-info'>
												<CardBody>
													<div className='mt-0 px-2'>
														<div className='d-flex justify-content-start align-items-baseline'>
															<i
																className='fas fa-exclamation-triangle me-1'
																style={{
																	fontSize: '24px',
																	color: '#F7AE09',
																}}
															/>
															<h3>Alergias</h3>
														</div>
														<div className='row justify-content-start d-flex mt-3'>
															{/*Recorrer array*/}
															{actualStudentAllergies ? (
																actualStudentAllergies?.map(
																	(item, index) => (
																		<h6>
																			<span>{item}</span>
																		</h6>
																	),
																)
															) : (
																<h6> </h6>
															)}
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										<div id='div-secundary-total'>
											<Card id='card-secundary-total'>
												<CardBody>
													<div>
														<div className='mt-0'>
															<div className='d-flex justify-content-start align-items-baseline'>
																<i
																	className='fas fa-ban me-1'
																	style={{
																		fontSize: '24px',
																		color: '#A70909',
																	}}
																/>
																<h3>Bloqueos</h3>
															</div>
															<div className='row justify-content-start d-flex mt-2'>
																{/*Recorrer array*/}

																{actualStudentLockedProducts ? (
																	actualStudentLockedProducts?.map(
																		(item, index) => (
																			<h6>
																				<span>
																					{
																						item.productName
																					}
																				</span>
																			</h6>
																		),
																	)
																) : (
																	<h6>{''}</h6>
																)}
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
										{/* ==========BOTONES DE ACCION GRISES==========*/}
										<div className='d-flex' id='div-grey-buttons-container'>
											<div id='div-button-grey'>
												<button
													className='btn waves-effect waves-light p-2 pt-0'
													id='button-grey-style-pos'
													type='button'
													disabled={
														isOrderProcessing ||
														activeOrder.length === 0
													}
													onClick={() => {
														processActiveOrder();
													}}>
													<div
														id='div-icon-gray-button'
														style={{
															height: '100px',
															alignContent: 'center',
														}}>
														<i
															className='mdi mdi-food'
															style={{ fontSize: '65px' }}
															id='icon-gray-button'
														/>
													</div>
													<div
														style={{
															justifyContent: 'center',
															fontSize: '16px',
														}}>
														Procesar Orden
													</div>
												</button>
											</div>

											<div id='div-button-grey'>
												<button
													className='btn waves-effect waves-light p-2 pt-0'
													id='button-grey-style-pos'
													onClick={() => {
														
													}}>
													<div
														id='div-icon-gray-button'
														style={{
															height: '100px',
															alignContent: 'center',
														}}>
														<i
															className='fas fa-credit-card'
															id='icon-gray-button'
															style={{ fontSize: '50px' }}
														/>
													</div>
													<div
														style={{
															justifyContent: 'center',
															fontSize: '16px',
														}}>
														Recargar tarjeta
													</div>
												</button>
											</div>
											<div id='div-button-grey'>
												<button
													className='btn waves-effect waves-light p-2 pt-0'
													id='button-grey-style-pos'
													onClick={() => {
														
													}}>
													<div
														id='div-icon-gray-button'
														style={{
															height: '100px',
															alignContent: 'center',
														}}>
														<i
															className='mdi mdi-book-multiple'
															style={{ fontSize: '50px' }}
															id='icon-gray-button'
														/>
													</div>
													<div
														style={{
															justifyContent: 'center',
															fontSize: '16px',
														}}>
														Agregar producto
													</div>
												</button>
											</div>
										</div>
									</div>
								</Row>
							</Col>

							{/* ==========CARD DE DATOS PERSONALES==========*/}
							<div
								className='d-flex row justify-content-center align-content-between'
								id='div-personal-data'
								style={{
									height: 'auto',
								}}>
								<div
									className='d-flex row justify-content-center'
									id='div-card-personal'>
									<div className='col-12'>
										<SearchProducts
											delayedHandleChange={handleStudentSearch}
											list={studentResult}
											columns={'12'}
											onSelect={onSelectedStudent}
											placeholder={'Buscar alumno...'}
										/>
									</div>
									<div className='row mt-2' id='div-card-personal'>
										{' '}
										{/* Agrega margen top */}
										<Card id='card-personal-data'>
											<CardBody className='d-grid'>
												<h4 className='d-flex justify-content-start'>
													Alumno
												</h4>
												<div className='row my-2'>
													<div className='col-12 d-flex justify-content-center'>
														<CardImg
															src={
																selectedStudent.image?.url
																	? selectedStudent.image.url
																	: avatar4
															}
															alt=''
															className='rounded-10 avatar-xl'
														/>
													</div>
												</div>
												<div>
													<div className='d-flex justify-content-center'>
														{' '}
														{/* Agrega margen bottom */}
														<h4>
															<strong>
																{selectedStudent.name}{' '}
																{selectedStudent.lastName}
															</strong>
														</h4>
													</div>
													<div className='d-flex justify-content-center mb-2'>
														{' '}
														{/* Agrega margen bottom */}
														<h5>
															{selectedStudent.enrolmentCode !=
															null ? (
																<span>
																	Matrícula:{' '}
																	{selectedStudent.enrolmentCode}
																</span>
															) : (
																<span></span>
															)}
														</h5>
													</div>
													<div className='d-flex justify-content-center mb-2'>
														{' '}
														{/* Agrega margen bottom */}
														<span
															id='badge-personal'
															className='badge'
															style={{
																backgroundColor: '#40C074',
																borderColor: '#40C074',
															}}>
															Activo
														</span>
													</div>
													<div className='d-flex justify-content-center mb-2'>
														{' '}
														{/* Agrega margen bottom */}
														<span
															id='badge-personal'
															className='badge'
															style={{
																backgroundColor:
																	activeOrder.length > 0
																		? '#F7AE06'
																		: '#EFEFEF',
																borderColor:
																	activeOrder.length > 0
																		? '#F7AE06'
																		: '#EFEFEF',
																color:
																	activeOrder.length > 0
																		? 'white'
																		: 'gray',
															}}>
															Orden activa
														</span>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
									<div
										className='row mt-5'
										id='div-card-method'
										style={{ height: '50px' }}>
										<div
											className='col-12'
											id='div-card-method'
											style={{ height: '50px' }}>
											<Card>
												<CardBody>
													<div className='row d-flex'></div>
												</CardBody>
											</Card>
										</div>
									</div>
								</div>
								<div
									className='d-flex row align-content-center'
									id='div-card-total'>
									<div
										className='col-12'
										id='div-card-total'
										style={{ height: '85px', marginBottom: '0px' }}>
										<Card style={{ height: '85px' }}>
											<CardBody>
												<div className='row d-flex'>
													<div className='col-6 d-flex'>
														<span
															id='span-total-card'
															style={{ fontSize: '30px ' }}>
															Total
														</span>
													</div>
													<div
														className='col-6'
														style={{ textAlign: 'end' }}>
														<span
															id='span-total-card'
															style={{ fontSize: '30px' }}>
															{formatter.format(saleTotal)}
														</span>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
									<div className='col-12'>
										<div
											className='col-12'
											style={{ height: '65px', marginBottom: '20px' }}>
											<Button
												className='btn waves-effect waves-light'
												id='button-green-style-pos'
												style={{
													backgroundColor: '#40C074',
													borderColor: '#40C074',
												}}
												disabled={
													productList.length === 0 ||
													selectedStudent._id === undefined
												}
												onClick={() => {
													if (selectedStudent._id === undefined) {
														toast.warning(
															'Por favor selecciona un alumno',
															toastStyle,
														);
													} else {
														setShowPaymentModal(true);
													}
												}}>
												<span>Pagar</span>
											</Button>
										</div>
									</div>
								</div>
							</div>
						</Form>
					</div>
				</Container>
			</div>
			<AddNewSale
				show={showPaymentModal}
				handleClose={() => setShowPaymentModal(false)}
				studentId={selectedStudent._id}
				saleTotalAmount={saleTotal}
				orderList={productList}
			/>
			<ToastContainer />
		</React.Fragment>
	);
};

export default PosPage;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Modal, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import AddNewSchool from '../CanvasPages/AddNewSchool';
import { getHeaderSimpleToken } from '../Utility/utils';
import { debounce } from 'lodash';
import { generateSchoolPdf, generateSchoolXls } from '../../Utils/ExportFiles/School';
const CommonSchoolsEvents = () => {
	const [dataList, setDataList] = useState([]);
	const [dataFilterList, setDataFilterList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const [openCanvas, setOpenCanvas] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [formTitle, setFormTitle] = useState('Agregar registro');
	const [openExportOptions, setOpenExportOptions] = useState(false);

	const onlyClose = async () => {
		setOpenCanvas(false);
		setEditItem(null);
	};

	async function getSchoolData() {
		fetch(`${process.env.REACT_APP_API_URL}school`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setDataList(rawData.data);
					setDataFilterList(rawData.data);
				} else {
					setDataList([]);
					setDataFilterList([]);
				}
			});
	}

	useEffect(() => {
		getSchoolData();
	}, []);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = Object.keys(dataList)
				.filter((key) =>
					dataList[key].name
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search),
				)
				.map((i) => dataList[i]);
			setDataFilterList(filterResult);
		} else {
			setDataFilterList(dataList);
		}
	}, 700);

	const refreshList = (newItem) => {
		var isEdit = false;
		var list = [];

		for (var i = 0; i < dataList.length; i++) {
			var item = dataList[i];
			if (item._id !== newItem._id) {
				list.push(item);
			} else {
				newItem.id = item.id;
				list.push(newItem);
				isEdit = true;
			}
		}

		if (!isEdit) {
			newItem.id = dataList.length + 1;
			list.push(newItem);
		}

		setDataList(list);
		setDataFilterList(list);
	};

	const deleteData = async (id) => {
		setDeleteModal(false);

		const response = await fetch(`${process.env.REACT_APP_API_URL}school/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const status = await response.json();

		if (status.message === 'Ok') {
			var list = [];
			for (var i = 0; i < dataList.length; i++) {
				var item = dataList[i];
				if (item._id !== id) {
					list.push(item);
				}
			}

			setDataList(list);
			setDataFilterList(list);
			setEditItem(null);
		} else {
			setEditItem(null);
		}
	};

	const exportTableToExcel = () => {
		generateSchoolXls(dataFilterList);
	};

	const exportTableToPdf = () => {
		generateSchoolPdf(dataFilterList);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Escuelas</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											delayedHandleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex align-items-center'>
										<div className='col-6  h4 text-primary'>
											<i className='fas fa-school' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
										<div className='col-6 container d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												id='button-style-3'
												onClick={() => {
													setFormTitle('Agregar registro');
													setOpenCanvas(!openCanvas);
												}}>
												<i className='fas fa-plus me-1'></i>
												Agregar registro
											</button>
											<Dropdown
												isOpen={openExportOptions}
												toggle={() =>
													setOpenExportOptions(!openExportOptions)
												}>
												<DropdownToggle caret color='info' outline>
													<i className='fas fa-cloud-download-alt me-1' />
													Exportar
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														onClick={() => exportTableToExcel()}>
														Excel
													</DropdownItem>
													<DropdownItem
														onClick={() => exportTableToPdf()}>
														PDF
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th className='sort text-center' data-sort='customer_name'>
														No.
													</th>
													<th className='sort' data-sort='email'>
														Nombre
													</th>
													<th className='sort' data-sort='phone'>
														Clave SEP
													</th>
													<th className='sort' data-sort='date'>
														Estado
													</th>
													<th className='sort' data-sort='status'>
														Ciudad
													</th>
													<th className='sort text-center' data-sort='action'>
														Docentes
													</th>
													<th className='sort text-center' data-sort='action'>
														Alumnos
													</th>
													<th className='sort text-center' data-sort='action'>
														Turnos
													</th>
													<th className='sort text-center' data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(
													dataFilterList,
													currentPage,
													perPage,
												).map((item) => (
													<tr key={item.id}>
														<td className='text-center'>
															<div>{item.id}</div>
														</td>
														<td>
															<div>{item.name}</div>
														</td>
														<td>
															<div>{item.sepCode}</div>
														</td>
														<td>
															<div>{item.state}</div>
														</td>
														<td>
															<div>{item.city}</div>
														</td>
														<td className='text-center'>
															<div>{item.teacherAmount}</div>
														</td>
														<td className='text-center'>
															<div>{item.studentAmount}</div>
														</td>
														<td className='text-center'>
															<div>{item.shifts}</div>
														</td>
														<td className='text-center'>
															<button
																type='button'
																className='btn btn-success waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setEditItem(item);
																	setFormTitle('Editar registro');
																	setOpenCanvas(!openCanvas);
																}}>
																<i className=' fas fa-pencil-alt me-1' />
																Editar
															</button>

															<button
																type='button'
																className='btn btn-outline-danger waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setEditItem(item);
																	setDeleteModal(true);
																}}>
																<i className='fas fa-trash-alt me-1' />
																Borrar
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={dataFilterList}
									label='registros'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<AddNewSchool
				onlyClose={onlyClose}
				openCanvas={openCanvas}
				cleanForm={true}
				editItem={editItem}
				refreshList={refreshList}
				tittle={formTitle}
			/>

			<Modal
				isOpen={deleteModal}
				toggle={() => {
					setDeleteModal(false);
					setEditItem(null);
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>Eliminar registro</h5>
					<button
						type='button'
						className='btn-close'
						onClick={() => {
							setDeleteModal(false);
							setEditItem(null);
						}}
						aria-label='Close'></button>
				</div>
				<div className='modal-body'>
					<div className='mb-3'>
						<p>¿Estás seguro de querer eliminar el registro?</p>
					</div>
				</div>
				<div className='modal-footer'>
					<button
						type='button'
						className='btn btn-secondary'
						data-bs-dismiss='modal'
						onClick={() => {
							setDeleteModal(false);
							setEditItem(null);
						}}>
						No
					</button>
					<button
						type='button'
						className='btn btn-primary'
						onClick={() => {
							deleteData(editItem._id);
						}}>
						Si
					</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default CommonSchoolsEvents;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Form,
	Input,
	FormFeedback,
	Label,
	CardImg,
	Spinner,
} from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import SearchComponent from '../Components/search';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// Imgs
import avatarDefault from '../../assets/images/users/StudentAvatar.png';
import { getHeaderSimpleToken } from '../Utility/utils';

const AddNewHeealth = ({ openCanvas, tittle, editItem, refreshList, onlyClose }) => {
	const [isRight, setIsRight] = useState(false);
	const [allergiesSelect, setAllergiesSelect] = useState([]);
	const [diseasesSelect, setDiseasesSelect] = useState([]);
	const [studentDataList, setStudentDataList] = useState([]);
	const [updateKey, setUpdateKey] = useState(0);
	const [studentSelected, setStudentSelected] = useState('');
	const [loading, setLoading] = useState(false);

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	const allergiesOptions = [
		{ label: 'Polen', value: 'Polen' },
		{ label: 'Cosméticos', value: 'Cosméticos' },
		{ label: 'Ácaros', value: 'Ácaros' },
		{ label: 'Moho', value: 'Moho' },
		{ label: 'Alimentos', value: 'Alimentos' },
	];

	const diseasesOptions = [
		{ label: 'Asma', value: 'Asma' },
		{ label: 'Diabetes', value: 'Diabetes' },
		{ label: 'Hipertención', value: 'Hipertención' },
		{ label: 'Mal de puerco', value: 'Mal de puerco' },
	];

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			level: '',
			group: '',
			turn: '',
			height: '',
			weight: '',
			imc: '',
			status: '',
			waist: '',
			chest: '',
			hip: '',
			arms: '',
		},
		validationSchema: Yup.object().shape({
			height: Yup.string().required('Campo obligatorio'),
			weight: Yup.string().required('Campo obligatorio'),
			waist: Yup.string().required('Campo obligatorio'),
			chest: Yup.string().required('Campo obligatorio'),
			hip: Yup.string().required('Campo obligatorio'),
			arms: Yup.string().required('Campo obligatorio'),
		}),
		onSubmit: () => {
			setLoading(true);

			if (tittle === 'Editar registro') {
				const body = {
					studentId: studentSelected._id,
					height: formik.values.height,
					weight: formik.values.weight,
					imc: formik.values.imc,
					status: formik.values.status,
					waist: formik.values.waist,
					chest: formik.values.chest,
					hip: formik.values.hip,
					arms: formik.values.arms,
					allergies: allergiesSelect,
					diseases: diseasesSelect,
				};

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}health/${editItem._id}`,
					data: body,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const studentData = [];
							studentData.push({
								_id: studentSelected._id,
								name: studentSelected.name,
								lastName: studentSelected.lastName,
								level: formik.values.level,
								group: formik.values.group,
								turn: formik.values.turn,
							});

							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								height: formik.values.height,
								weight: formik.values.weight,
								imc: formik.values.imc,
								status: formik.values.status,
								waist: formik.values.waist,
								chest: formik.values.chest,
								hip: formik.values.hip,
								arms: formik.values.arms,
								allergies: allergiesSelect,
								diseases: diseasesSelect,
								studentData: studentData,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			} else {
				const body = {
					studentId: studentSelected._id,
					height: formik.values.height,
					weight: formik.values.weight,
					imc: formik.values.imc,
					status: formik.values.status,
					waist: formik.values.waist,
					chest: formik.values.chest,
					hip: formik.values.hip,
					arms: formik.values.arms,
					allergies: allergiesSelect,
					diseases: diseasesSelect,
				};

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}health`,
					data: body,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const studentData = [];
							studentData.push({
								_id: studentSelected._id,
								name: studentSelected.name,
								lastName: studentSelected.lastName,
								level: formik.values.level,
								group: formik.values.group,
								turn: formik.values.turn,
							});

							const newItem = {
								_id: response.content.id,
								height: formik.values.height,
								weight: formik.values.weight,
								imc: formik.values.imc,
								status: formik.values.status,
								waist: formik.values.waist,
								chest: formik.values.chest,
								hip: formik.values.hip,
								arms: formik.values.arms,
								emotion: null,
								allergies: allergiesSelect,
								diseases: diseasesSelect,
								studentData: studentData,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			}
		},
	});

	useEffect(() => {
		setIsRight(openCanvas);
		setStudentSelected('');

		formik.setValues({});
		formik.resetForm();

		if (editItem !== null) {
			formik.setValues({
				level: editItem.academySheetData.level,
				group: editItem.academySheetData.group,
				turn: editItem.academySheetData.turn,
				height: editItem.height,
				weight: editItem.weight,
				imc: editItem.imc,
				status: editItem.status,
				waist: editItem.waist,
				chest: editItem.chest,
				hip: editItem.hip,
				arms: editItem.arms,
			});

			setStudentSelected(editItem.studentData);

			if (editItem.allergies !== '' && editItem.allergies !== null) {
				setAllergiesSelect(editItem.allergies);
			} else {
				setAllergiesSelect([]);
			}

			if (editItem.diseases !== '' && editItem.diseases !== null) {
				setDiseasesSelect(editItem.diseases);
			} else {
				setDiseasesSelect([]);
			}
		} else {
			setAllergiesSelect([]);
			setDiseasesSelect([]);
		}

		setUpdateKey(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas, editItem]);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}student/search/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setStudentDataList(json.data);
					} else {
						setStudentDataList([]);
					}
				});
		}
	}, 700);

	const handleAllergiesSelect = (e) => {
		const value = e.value;
		const listA = allergiesSelect;

		if (!listA.includes(value)) {
			listA.push(value);
		}

		setAllergiesSelect(listA);
		setUpdateKey(updateKey + 1);
	};

	const handleDiseasesSelect = (e) => {
		const value = e.value;
		const listD = diseasesSelect;

		if (!listD.includes(value)) {
			listD.push(value);
		}

		setDiseasesSelect(listD);
		setUpdateKey(updateKey + 1);
	};

	const onDeleteAllergy = (value) => {
		var list = [];
		for (var i = 0; i < allergiesSelect.length; i++) {
			var item = allergiesSelect[i];
			if (item !== value) {
				list.push(item);
			}
		}

		setAllergiesSelect(list);
		setUpdateKey(updateKey + 1);
	};

	const onDeleteDiseases = (value) => {
		var list = [];
		for (var i = 0; i < diseasesSelect.length; i++) {
			var item = diseasesSelect[i];
			if (item !== value) {
				list.push(item);
			}
		}

		setDiseasesSelect(list);
		setUpdateKey(updateKey + 1);
	};

	function calculateImc() {
		if (formik.values.height !== '' && formik.values.weight !== '') {
			let heightForm = 0;
			var weightForm = Number(formik.values.weight);

			if (formik.values.height.includes('.')) {
				heightForm = Number(formik.values.height) * Number(formik.values.height);
			} else {
				const heightFormDecimal = Number(formik.values.height) / 100;
				heightForm = Number(heightFormDecimal) * Number(heightFormDecimal);
			}

			var imcValue = (weightForm / heightForm).toFixed(2);
			let statusByImc = 'Normal';

			if (imcValue < 16) {
				statusByImc = 'Delgadez severa';
			} else {
				if (imcValue >= 16 && imcValue <= 16.99) {
					statusByImc = 'Delgadez moderada';
				} else {
					if (imcValue >= 17 && imcValue <= 18.49) {
						statusByImc = 'Delgadez leve';
					} else {
						if (imcValue >= 18.5 && imcValue <= 24.99) {
							statusByImc = 'Normal';
						} else {
							if (imcValue >= 25 && imcValue <= 29.99) {
								statusByImc = 'Pre-obeso';
							} else {
								if (imcValue >= 30 && imcValue <= 24.99) {
									statusByImc = 'Obesidad tipo I';
								} else {
									if (imcValue >= 35 && imcValue <= 39.99) {
										statusByImc = 'Obesidad tipo II';
									} else {
										statusByImc = 'Obesidad tipo III';
									}
								}
							}
						}
					}
				}
			}

			formik.setFieldValue('imc', imcValue);
			formik.setFieldValue('status', statusByImc);
			setUpdateKey(updateKey + 1);
		}
	}

	const onSelectedStudent = (value) => {
		setStudentSelected(value);
		formik.setValues({
			...formik.values,
			level: value.academySheetData.level,
			group: value.academySheetData.group,
			turn: value.academySheetData.turn,
		});
	};

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-health'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader id='off-canvas-header' toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
							<div>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div className='row'>
											<div className='col-6'>
												<h4>Datos del alumno</h4>
											</div>
											{tittle === 'Agregar registro' ? (
												<div className='col-6'>
													<SearchComponent
														delayedHandleChange={delayedHandleSearch}
														list={studentDataList}
														onSelect={onSelectedStudent}
														placeholder={'Buscar alumno...'}
														columns={'12'}
													/>
												</div>
											) : null}
										</div>

										<div className='d-flex'>
											<div id='div-style-11'>
												<CardImg
													id='img-bgc-g'
													src={avatarDefault}
													alt='Kuali'
													className='rounded avatar-lg'
												/>
											</div>

											<div>
												<div className='row'>
													<div>
														<Label className='col-form-label text-muted'>
															Nombre
														</Label>
														<Input
															name='name'
															className='form-control'
															type='text'
															value={
																studentSelected.name
																	? studentSelected.name +
																	  ' ' +
																	  studentSelected.lastName
																	: ''
															}
															disabled
														/>
													</div>
													<div className='col-4'>
														<Label className='col-form-label text-muted'>
															Grado
														</Label>
														<Input
															name='level'
															className='form-control'
															type='text'
															value={formik.values.level}
															disabled
														/>
													</div>
													<div className='col-4'>
														<Label className='col-form-label text-muted'>
															Grupo
														</Label>
														<Input
															name='group'
															className='form-control'
															type='text'
															value={formik.values.group}
															disabled
														/>
													</div>
													<div className='col-4'>
														<Label className='col-form-label text-muted'>
															Turno
														</Label>
														<Input
															name='turn'
															className='form-control'
															type='text'
															value={formik.values.turn}
															disabled
														/>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div className='row'>
											<div>
												<h4>Datos de salud</h4>
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Altura
												</Label>
												<Input
													name='height'
													className='form-control'
													type='number'
													value={formik.values.height}
													onChange={(e) => {
														formik.setFieldValue(
															'height',
															e.target.value,
														);
														calculateImc();
													}}
													onKeyUp={(e) => {
														formik.setFieldValue(
															'height',
															e.target.value,
														);
														calculateImc();
													}}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.height &&
														formik.errors.height
															? true
															: false
													}
												/>
												{formik.touched.height && formik.errors.height ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.height}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Peso
												</Label>
												<Input
													name='weight'
													className='form-control'
													type='number'
													value={formik.values.weight}
													onChange={(e) => {
														formik.setFieldValue(
															'weight',
															e.target.value,
														);
														calculateImc();
													}}
													onKeyUp={(e) => {
														formik.setFieldValue(
															'weight',
															e.target.value,
														);
														calculateImc();
													}}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.weight &&
														formik.errors.weight
															? true
															: false
													}
												/>
												{formik.touched.weight && formik.errors.weight ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.weight}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													IMC
												</Label>
												<Input
													name='imc'
													className='form-control'
													type='text'
													value={formik.values.imc}
													disabled
												/>
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Estatus
												</Label>
												<Input
													name='status'
													className='form-control'
													type='text'
													value={formik.values.status}
													disabled
												/>
											</div>

											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Cintura
												</Label>
												<Input
													name='waist'
													className='form-control'
													type='number'
													value={formik.values.waist}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.waist && formik.errors.waist
															? true
															: false
													}
												/>
												{formik.touched.waist && formik.errors.waist ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.waist}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Pecho
												</Label>
												<Input
													name='chest'
													className='form-control'
													type='number'
													value={formik.values.chest}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.chest && formik.errors.chest
															? true
															: false
													}
												/>
												{formik.touched.chest && formik.errors.chest ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.chest}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Cadera
												</Label>
												<Input
													name='hip'
													className='form-control'
													type='number'
													value={formik.values.hip}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.hip && formik.errors.hip
															? true
															: false
													}
												/>
												{formik.touched.hip && formik.errors.hip ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.hip}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3'>
												<Label className='col-form-label text-muted'>
													Brazos
												</Label>
												<Input
													name='arms'
													className='form-control'
													type='number'
													value={formik.values.arms}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.arms && formik.errors.arms
															? true
															: false
													}
												/>
												{formik.touched.arms && formik.errors.arms ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.arms}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-6'>
												<Label className='col-form-label text-muted'>
													Alergias
												</Label>
												<Select
													name='allergies'
													value={null}
													onChange={handleAllergiesSelect}
													options={allergiesOptions}
													classNamePrefix='select2-selection'
													placeholder='Selecciona'
													noOptionsMessage={() => 'Sin resultados'}
												/>
												<div id='div-style-8'>Alergias</div>
												{allergiesSelect.map((item) => (
													<div key={item} id='div-style-9'>
														<div className='col-10'>
															<text>{item}</text>
														</div>
														<div className='d-flex'>
															<div id='div-style-10' />
															<i
																className='fas fa-trash-alt'
																id='icon-style-2'
																onClick={() => {
																	onDeleteAllergy(item);
																}}
															/>
														</div>
													</div>
												))}
											</div>
											<div className='col-6'>
												<Label className='col-form-label text-muted'>
													Enfermedades
												</Label>
												<Select
													name='diseases'
													value={null}
													onChange={handleDiseasesSelect}
													options={diseasesOptions}
													classNamePrefix='select2-selection'
													placeholder='Selecciona'
													noOptionsMessage={() => 'Sin resultados'}
												/>
												<div id='div-style-8'>Enfermedades</div>
												{diseasesSelect.map((item) => (
													<div key={item} id='div-style-9'>
														<div className='col-10'>
															<text>{item}</text>
														</div>
														<div className='d-flex'>
															<div id='div-style-10' />
															<i
																className='fas fa-trash-alt'
																id='icon-style-2'
																onClick={() => {
																	onDeleteDiseases(item);
																}}
															/>
														</div>
													</div>
												))}
											</div>
										</div>
									</CardBody>
								</Card>

								<CardFooter id='card-footer-off-canvas'>
									<div className='d-flex justify-content-center'>
										{loading ? (
											<div id='div-style-7'>
												<Spinner id='spinner-style-1' size='lg' />
											</div>
										) : (
											<button
												type='submit'
												className='btn btn-success waves-effect waves-light w-50'
												id='create-btn'>
												<i className='fas fa-save me-1' />
												{tittle === 'Editar registro'
													? 'Actualizar'
													: 'Guardar'}
											</button>
										)}
									</div>
								</CardFooter>
							</div>
						</Form>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewHeealth.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewHeealth.defaultProps = {
	openCanvas: false,
};

export default AddNewHeealth;

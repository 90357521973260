import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
} from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import 'react-toastify/dist/ReactToastify.css';
import { getHeaderSimpleToken } from '../Utility/utils';
import Kardex from '../CanvasPages/Kardex';

const CommonStudentGrades = () => {
    const [dataList, setDataList] = useState([]);
    const [dataFilterList, setDataFilterList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['5']);
    const [openCanvas, setOpenCanvas] = useState(false);
    const [studentSelected, setStudentSelected] = useState(null);


    const onlyClose = async () => {
        setOpenCanvas(false);
        setStudentSelected(null);
    };

    function getStudentsControlNumberNameAndLastname() {
        fetch(`${process.env.REACT_APP_API_URL}student/getStudentsControlNumberNameAndLastname`, {
            headers: getHeaderSimpleToken(),
        })
            .then((response) => response.json())
            .then((rawData) => {
                if (rawData.data.length !== 0) {
                    const listFromBack = rawData.data;
                    const newListFromBack = [];
                    for (let i = 0; i < listFromBack.length; i += 1) {
                        const item = listFromBack[i];
                        newListFromBack.push({
                            id: i + 1,
                            _id: item._id,
                            name: item.name,
                            lastname: item.lastName,
                            controlNumber: item.controlNumber,
                        });
                    }

                    setDataList(newListFromBack);
                    setDataFilterList(newListFromBack);
                } else {
                    setDataList([]);
                    setDataFilterList([]);
                }
            });
    }



    useEffect(() => {
        getStudentsControlNumberNameAndLastname();
    }, []);

    const delayedHandleSearch = debounce((search) => {
        if (search === '') {
            setDataFilterList(dataList);
            return
        }

        search = search
            .replace('á', 'a')
            .replace('í', 'i')
            .replace('ú', 'u')
            .replace('é', 'e')
            .replace('ó', 'o');

        const filterResult = Object.keys(dataList)
            .filter(
                (key) =>
                    dataList[key].name
                        .toString()
                        .toLowerCase()
                        .replace('á', 'a')
                        .replace('í', 'i')
                        .replace('ú', 'u')
                        .replace('é', 'e')
                        .replace('ó', 'o')
                        .includes(search) ||
                    dataList[key].lastname
                        .toString()
                        .toLowerCase()
                        .replace('á', 'a')
                        .replace('í', 'i')
                        .replace('ú', 'u')
                        .replace('é', 'e')
                        .replace('ó', 'o')
                        .includes(search),
            )
            .map((i) => dataList[i]);
        setDataFilterList(filterResult);

    }, 700);

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
                            <span className='span-tittle-text'>Calificaciones</span>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
                            <form className='app-search d-none d-lg-block'>
                                <div className='col-12 position-relative'>
                                    <input
                                        type='text'
                                        className='form-control bg-white'
                                        placeholder='Buscar...'
                                        onChange={(event) => delayedHandleSearch(event.target.value.toLowerCase())}

                                    />
                                    <span className='ri-search-line'></span>
                                </div>
                            </form>
                        </div>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='bg-white'>
                                    <div className='d-flex'>
                                        <div className='col-6 h4 text-primary'>
                                            <i className='fas fa-user-graduate' />
                                            <span className='span-tittle-text-table'>
                                                Registros
                                            </span>
                                        </div>

                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className='table-responsive table-card mt-3 mb-1'>
                                        <table
                                            className='table align-middle table-nowrap'
                                            id='customerTable'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th className='sort' data-sort='customer_name'>
                                                        No.
                                                    </th>
                                                    <th className='sort' data-sort='email'>
                                                        Matrícula
                                                    </th>
                                                    <th className='sort' data-sort='phone'>
                                                        Nombre
                                                    </th>
                                                    <th
                                                        className='sort text-center'
                                                        data-sort='action'>
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='list form-check-all'>
                                                {dataPagination(
                                                    dataFilterList,
                                                    currentPage,
                                                    perPage,
                                                ).map((item) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <div>{item.id}</div>
                                                        </td>
                                                        <td>
                                                            <div>{item.controlNumber}</div>

                                                        </td>
                                                        <td>
                                                            <div>{`${item.name} ${item.lastname}`}</div>
                                                        </td>
                                                        <td className='text-center'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success waves-effect waves-light me-2'
                                                                id='create-btn'
                                                                onClick={() => {

                                                                    setOpenCanvas(!openCanvas);
                                                                    setStudentSelected(item)
                                                                }}>
                                                                <i className=' fas fa-pencil-alt me-1' />
                                                                Ver calificaciones
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className='noresult d-none'>
                                            <div className='text-center'>
                                                <h5 className='mt-2'>
                                                    No se encontraron resultados
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <PaginationButtons
                                    data={dataFilterList}
                                    label='registros'
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {studentSelected && (
                <Kardex
                    onlyClose={onlyClose}
                    openCanvas={openCanvas}
                    studentId={studentSelected._id}
                    studentName={studentSelected.name + " " + studentSelected.lastname}
                />

            )}


        </React.Fragment>
    );
};

export default CommonStudentGrades;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Input,
	FormFeedback,
	Form,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderSimpleToken } from '../Utility/utils';

const AddNewSchool = ({ openCanvas, tittle, editItem, refreshList, onlyClose }) => {
	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);
	const countryData = ['México', 'Estados Unidos'];

	const formik = useFormik({
		initialValues: {
			id: '',
			schoolname: '',
			registration: '',
			students: '',
			teachers: '',
			classrooms: '',
			schoolstore: '',
			state: '',
			municipality: '',
			city: '',
			colony: '',
			mail: '',
			website: '',
			street: '',
			outdoornumber: '',
			interiornumber: '',
			zipcode: '',
			phone: '',
			country: '',
		},
		validationSchema: Yup.object({
			schoolname: Yup.string().required('Por favor, ingrese el nombre de la escuela'),
			registration: Yup.string().required('Por favor, ingrese la clave SEP'),
			students: Yup.string().required('Por favor, ingrese el número de estudiantes'),
			teachers: Yup.string().required('Por favor, ingrese número de docentes'),
			classrooms: Yup.string().required('Por favor, ingrese el número de aulas'),
			schoolstore: Yup.string().required('Por favor, ingrese el número de cooperativas'),
			state: Yup.string().required('Por favor, ingrese el estado'),
			municipality: Yup.string().required('Por favor, ingrese el municipio'),
			city: Yup.string().required('Por favor, ingrese la ciudad'),
			colony: Yup.string().required('Por favor, ingrese la colonia'),
			street: Yup.string().required('Por favor, ingrese la calle'),
			outdoornumber: Yup.string().required('Por favor, ingrese el número exterior'),
			zipcode: Yup.string()
				.required('Por favor, ingrese el código postal')
				.min(5, '5 caracteres')
				.max(5, '5 caracteres'),
			phone: Yup.string()
				.required('Por favor, ingrese el número de teléfono')
				.min(10, '10 caracteres')
				.max(10, '10 caracteres'),
			country: Yup.string().required('Por favor, ingrese el país de la escuela'),
		}),
		onSubmit: () => {
			setLoading(true);

			if (tittle === 'Editar registro') {
				const formData = new FormData();
				formData.append('name', formik.values.schoolname);
				formData.append('sepCode', formik.values.registration);
				formData.append('studentAmount', formik.values.students);
				formData.append('teacherAmount', formik.values.teachers);
				formData.append('classroomAmount', formik.values.classrooms);
				formData.append('cooperativeAmount', formik.values.schoolstore);
				formData.append('country', formik.values.country);
				formData.append('state', formik.values.state);
				formData.append('municipality', formik.values.municipality);
				formData.append('city', formik.values.city);
				formData.append('colony', formik.values.colony);
				formData.append('street', formik.values.street);
				formData.append('email', formik.values.mail);
				formData.append('website', formik.values.website);
				formData.append('outdoorNumber', formik.values.outdoornumber);
				formData.append('interiorNumber', formik.values.interiornumber);
				formData.append('zipCode', formik.values.zipcode);
				formData.append('phone', formik.values.phone);

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}school/${editItem._id}`,
					data: formData,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								name: formik.values.schoolname,
								sepCode: formik.values.registration,
								studentAmount: formik.values.students,
								teacherAmount: formik.values.teachers,
								classroomAmount: formik.values.classrooms,
								cooperativeAmount: formik.values.schoolstore,
								country: formik.values.country,
								state: formik.values.state,
								municipality: formik.values.municipality,
								city: formik.values.city,
								colony: formik.values.colony,
								street: formik.values.street,
								email: formik.values.mail,
								website: formik.values.website,
								outdoorNumber: formik.values.outdoornumber,
								interiorNumber: formik.values.interiornumber,
								zipCode: formik.values.zipcode,
								phone: formik.values.phone,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			} else {
				const formData = new FormData();
				formData.append('name', formik.values.schoolname);
				formData.append('sepCode', formik.values.registration);
				formData.append('studentAmount', formik.values.students);
				formData.append('teacherAmount', formik.values.teachers);
				formData.append('classroomAmount', formik.values.classrooms);
				formData.append('cooperativeAmount', formik.values.schoolstore);
				formData.append('country', formik.values.country);
				formData.append('state', formik.values.state);
				formData.append('municipality', formik.values.municipality);
				formData.append('city', formik.values.city);
				formData.append('colony', formik.values.colony);
				formData.append('street', formik.values.street);
				formData.append('email', formik.values.mail);
				formData.append('website', formik.values.website);
				formData.append('outdoorNumber', formik.values.outdoornumber);
				formData.append('interiorNumber', formik.values.interiornumber);
				formData.append('zipCode', formik.values.zipcode);
				formData.append('phone', formik.values.phone);

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}school`,
					data: formData,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								_id: response.content.id,
								name: formik.values.schoolname,
								sepCode: formik.values.registration,
								studentAmount: formik.values.students,
								teacherAmount: formik.values.teachers,
								classroomAmount: formik.values.classrooms,
								cooperativeAmount: formik.values.schoolstore,
								country: formik.values.country,
								state: formik.values.state,
								municipality: formik.values.municipality,
								city: formik.values.city,
								colony: formik.values.colony,
								street: formik.values.street,
								email: formik.values.mail,
								website: formik.values.website,
								outdoorNumber: formik.values.outdoornumber,
								interiorNumber: formik.values.interiornumber,
								zipCode: formik.values.zipcode,
								phone: formik.values.phone,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);

		if (editItem !== null) {
			formik.setValues({
				id: editItem._id,
				schoolname: editItem.name,
				registration: editItem.sepCode,
				students: editItem.studentAmount,
				teachers: editItem.teacherAmount,
				classrooms: editItem.classroomAmount,
				schoolstore: editItem.cooperativeAmount,
				state: editItem.state,
				municipality: editItem.municipality,
				city: editItem.city,
				colony: editItem.colony,
				mail: editItem.email,
				website: editItem.website,
				street: editItem.street,
				outdoornumber: editItem.outdoorNumber,
				interiornumber: editItem.interiorNumber,
				zipcode: editItem.zipCode,
				phone: editItem.phone,
				country: editItem.country,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas, editItem]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-school'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<Form
									className='form-horizontal'
									onSubmit={(e) => {
										e.preventDefault();
										formik.handleSubmit();
										return false;
									}}>
									<CardBody>
										<div className='row'>
											<div>
												<h4>Generales</h4>
											</div>
											<div className='col-8 mt-0'>
												<label
													htmlFor='schoolname'
													className='col-form-label text-muted'>
													Nombre de escuela
												</label>
												<Input
													className='form-control'
													type='text'
													id='schoolname'
													name='schoolname'
													value={formik.values.schoolname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.schoolname &&
														formik.errors.schoolname
															? true
															: false
													}
												/>
												{formik.touched.schoolname &&
												formik.errors.schoolname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.schoolname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='registration'
													className='col-form-label text-muted'>
													Clave SEP
												</label>

												<Input
													className='form-control'
													type='text'
													id='registration'
													name='registration'
													value={formik.values.registration}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.registration &&
														formik.errors.registration
															? true
															: false
													}
												/>
												{formik.touched.registration &&
												formik.errors.registration ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.registration}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='students'
													className='col-form-label text-muted'>
													No. Alumnos
												</label>
												<Input
													className='form-control'
													type='number'
													id='students'
													name='students'
													value={formik.values.students}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.students &&
														formik.errors.students
															? true
															: false
													}
												/>
												{formik.touched.students &&
												formik.errors.students ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.students}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='teachers'
													className='col-form-label text-muted'>
													No. Docentes
												</label>
												<Input
													className='form-control'
													type='number'
													id='teachers'
													name='teachers'
													value={formik.values.teachers}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.teachers &&
														formik.errors.teachers
															? true
															: false
													}
												/>
												{formik.touched.teachers &&
												formik.errors.teachers ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.teachers}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='classrooms'
													className='col-form-label text-muted'>
													No. Aulas
												</label>
												<Input
													className='form-control'
													type='number'
													id='classrooms'
													name='classrooms'
													value={formik.values.classrooms}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.classrooms &&
														formik.errors.classrooms
															? true
															: false
													}
												/>
												{formik.touched.classrooms &&
												formik.errors.classrooms ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.classrooms}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='schoolstore'
													className='col-form-label text-muted'>
													No. Cooperativas
												</label>
												<Input
													className='form-control'
													type='number'
													id='schoolstore'
													name='schoolstore'
													value={formik.values.schoolstore}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.schoolstore &&
														formik.errors.schoolstore
															? true
															: false
													}
												/>
												{formik.touched.schoolstore &&
												formik.errors.schoolstore ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.schoolstore}</div>
													</FormFeedback>
												) : null}
											</div>
											<div>
												<h4 className='mt-3'>Dirección</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='country'
													className='col-form-label text-muted'>
													País
												</label>
												<Input
													id='country'
													name='country'
													type='select'
													value={formik.values.country}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.country &&
														formik.errors.country
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{countryData.map((i) => (
														<option key={i} value={i}>
															{i}
														</option>
													))}
												</Input>
												{formik.touched.country && formik.errors.country ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.country}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='state'
													className='col-form-label text-muted'>
													Estado
												</label>
												<Input
													className='form-control'
													type='text'
													id='state'
													name='state'
													value={formik.values.state}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.state && formik.errors.state
															? true
															: false
													}
												/>
												{formik.touched.state && formik.errors.state ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.state}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='municipality'
													className='col-form-label text-muted'>
													Municipio
												</label>
												<Input
													className='form-control'
													type='text'
													id='municipality'
													name='municipality'
													value={formik.values.municipality}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.municipality &&
														formik.errors.municipality
															? true
															: false
													}
												/>
												{formik.touched.municipality &&
												formik.errors.municipality ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.municipality}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='city'
													className='col-form-label text-muted'>
													Ciudad/Localidad
												</label>
												<Input
													className='form-control'
													type='text'
													id='city'
													name='city'
													value={formik.values.city}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.city && formik.errors.city
															? true
															: false
													}
												/>
												{formik.touched.city && formik.errors.city ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.city}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='colony'
													className='col-form-label text-muted'>
													Colonia
												</label>
												<Input
													className='form-control'
													type='text'
													id='colony'
													name='colony'
													value={formik.values.colony}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.colony &&
														formik.errors.colony
															? true
															: false
													}
												/>
												{formik.touched.colony && formik.errors.colony ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.colony}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='street'
													className='col-form-label text-muted'>
													Calle
												</label>
												<Input
													className='form-control'
													type='text'
													id='street'
													name='street'
													value={formik.values.street}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.street &&
														formik.errors.street
															? true
															: false
													}
												/>
												{formik.touched.street && formik.errors.street ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.street}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='mail'
													className='col-form-label text-muted'>
													Correo
												</label>
												<Input
													className='form-control'
													type='text'
													id='mail'
													name='mail'
													value={formik.values.mail}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.mail && formik.errors.mail
															? true
															: false
													}
												/>
												{formik.touched.mail && formik.errors.mail ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.mail}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='website'
													className='col-form-label text-muted'>
													Página web
												</label>
												<Input
													className='form-control'
													type='text'
													id='website'
													name='website'
													value={formik.values.website}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.website &&
														formik.errors.website
															? true
															: false
													}
												/>
												{formik.touched.website && formik.errors.website ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.website}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='outdoornumber'
													className='col-form-label text-muted'>
													No. Exterior
												</label>
												<Input
													className='form-control'
													type='number'
													id='outdoornumber'
													name='outdoornumber'
													value={formik.values.outdoornumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.outdoornumber &&
														formik.errors.outdoornumber
															? true
															: false
													}
												/>
												{formik.touched.outdoornumber &&
												formik.errors.outdoornumber ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.outdoornumber}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='interiornumber'
													className='col-form-label text-muted'>
													No. Interior
												</label>
												<Input
													className='form-control'
													type='number'
													id='interiornumber'
													name='interiornumber'
													value={formik.values.interiornumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.interiornumber &&
														formik.errors.interiornumber
															? true
															: false
													}
												/>
												{formik.touched.interiornumber &&
												formik.errors.interiornumber ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.interiornumber}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='zipcode'
													className='col-form-label text-muted'>
													C.P.
												</label>
												<Input
													className='form-control'
													type='number'
													id='zipcode'
													name='zipcode'
													value={formik.values.zipcode}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.zipcode &&
														formik.errors.zipcode
															? true
															: false
													}
												/>
												{formik.touched.zipcode && formik.errors.zipcode ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.zipcode}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-3 mt-0'>
												<label
													htmlFor='phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>
												<Input
													className='form-control'
													type='number'
													id='phone'
													name='phone'
													value={formik.values.phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.phone && formik.errors.phone
															? true
															: false
													}
												/>
												{formik.touched.phone && formik.errors.phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.phone}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
							</Card>
						</div>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewSchool.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewSchool.defaultProps = {
	openCanvas: false,
};

export default AddNewSchool;

import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DonutSaleDos from './DonutSaleDos';
import { SaleDonutData } from '../../CommonData/Data/dashboard';

const CategorySales = () => {
	return (
		<React.Fragment>
			<Col lg={4} style={{ marginBottom: '24px' }}>
				<Card style={{ height: '100%' }}>
					<CardBody>
						<div style={{ fontWeight: 'bold', fontSize: '24px', color: '#000' }}>
							Ventas por categorías
						</div>
						<DonutSaleDos />
						<Row>
							{SaleDonutData.map((item, key) => (
								<div key={key} className='col-6'>
									<div
										style={{
											display: 'flex',
											marginLeft: '25px',
											marginBottom: '8px',
										}}>
										<div
											className={'avatar-title'}
											style={{
												backgroundColor: item.bgcolor,
												height: '25px',
												width: '25px',
												marginTop: '2px',
											}}
										/>
										<div style={{ marginLeft: '10px' }}>
											<div
												style={{
													color: '#2C2C2C',
													fontSize: '20px',
													fontWeight: '500',
												}}>
												{item.title}
											</div>
										</div>
									</div>
								</div>
							))}
						</Row>
						<div style={{ textAlign: 'center', marginTop: "55px"}}>
							<button
								type='button'
								className='btn'
								style={{
									backgroundColor: '#00A88D',
									color: '#fff',
									fontSize: '22px',
									fontWeight: 'bold',
									paddingLeft: '48px',
									paddingTop: '2px',
									paddingRight: '48px',
									paddingBottom: '2px',
								}}>
								Ver detalle
							</button>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};
export default CategorySales;

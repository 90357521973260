import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import RadialChart1 from './userpanelChart1';
import RadialChart2 from './userpanelChart2';

// imgs
import studentDefault from '../../assets/images/student-default.png';
import personnelDefault from '../../assets/images/personnel-default.png';

const UserPanel = () => {
	return (
		<React.Fragment>
			<Row>
				<Col xl={3} sm={6}>
					<Card>
						<CardBody>
							<div className='d-flex text-muted'>
								<div className='flex-shrink-0 me-3 align-self-center'>
									<div id='radialchart-1' className='apex-charts' dir='ltr'>
										<img src={studentDefault} alt='' height={'87px'} />
									</div>
								</div>

								<div className='flex-grow-1 overflow-hidden'>
									<div style={{ color: '#696D81', fontSize: '18px' }}>
										Total de alumnos
									</div>
									<div
										style={{
											color: '#2C2C2C',
											fontSize: '35px',
											fontWeight: 'bold',
										}}>
										250
									</div>
									<div
										className='text-truncate mb-0'
										style={{ color: '#696D81', fontSize: '12px' }}>
										<span className='text-success me-2'>
											5%
											<i className='ri-arrow-right-up-line align-bottom ms-1'></i>
										</span>
										Del año anterior
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>

				<Col xl={3} sm={6}>
					<Card>
						<CardBody>
							<div className='d-flex'>
								<div className='flex-shrink-0 me-3 align-self-center'>
									<img src={personnelDefault} alt='' height={'87px'} />
								</div>

								<div className='flex-grow-1 overflow-hidden'>
									<div style={{ color: '#696D81', fontSize: '18px' }}>
										Total de personal
									</div>
									<div
										style={{
											color: '#2C2C2C',
											fontSize: '35px',
											fontWeight: 'bold',
										}}>
										20
									</div>
									<div
										className='text-truncate mb-0'
										style={{ color: '#696D81', fontSize: '12px' }}>
										<span className='text-danger me-2'>
											1.7%
											<i className='ri-arrow-right-down-line align-bottom ms-1'></i>
										</span>
										Del año anterior
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>

				<Col xl={3} sm={6}>
					<Card>
						<CardBody>
							<div className='d-flex text-muted'>
								<div className='flex-shrink-0 me-3 align-self-center'>
									<RadialChart1
										id='radialchart-3'
										className='apex-charts'
										dir='ltr'
									/>
								</div>

								<div className='flex-grow-1 overflow-hidden'>
									<div style={{ color: '#696D81', fontSize: '18px' }}>
										Promedios deficientes
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div
											style={{
												color: '#2C2C2C',
												fontSize: '35px',
												fontWeight: 'bold',
											}}>
											117
										</div>
										<div
											style={{
												color: '#2C2C2C',
												fontSize: '20px',
												fontWeight: 'bold',
												marginLeft: '10px',
											}}>
											Alumnos
										</div>
									</div>
									<div
										className='text-truncate mb-0'
										style={{ color: '#696D81', fontSize: '12px' }}>
										<span className='text-success me-2'>
											8%
											<i className='ri-arrow-right-up-line align-bottom ms-1'></i>
										</span>
										Del año anterior
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>

				<Col xl={3} sm={6}>
					<Card>
						<CardBody>
							<div className='d-flex text-muted'>
								<div className='flex-shrink-0 me-3 align-self-center'>
									<RadialChart2
										id='radialchart-3'
										className='apex-charts'
										dir='ltr'
									/>
								</div>

								<div className='flex-grow-1 overflow-hidden'>
									<div style={{ color: '#696D81', fontSize: '18px' }}>
										Problemas de alimentación
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div
											style={{
												color: '#2C2C2C',
												fontSize: '35px',
												fontWeight: 'bold',
											}}>
											143
										</div>
										<div
											style={{
												color: '#2C2C2C',
												fontSize: '20px',
												fontWeight: 'bold',
												marginLeft: '10px',
											}}>
											Alumnos
										</div>
									</div>
									<div
										className='text-truncate mb-0'
										style={{ color: '#696D81', fontSize: '12px' }}>
										<span className='text-success me-2'>
											8%
											<i className='ri-arrow-right-up-line align-bottom ms-1'></i>
										</span>
										Del año anterior
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default UserPanel;

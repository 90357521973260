import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

const SearchSchoolComponent = forwardRef(
	({ delayedHandleChange, list, onSelect, columns, placeholder }) => {
		const [searchModalStatus, setSearchModalStatus] = useState(false);

		return (
			<>
				<div className={`col-${columns}`}>
					<div
						className='d-flex justify-content-center align-items-center'
						id='div-style-15'>
						<span className='ri-search-line'></span>
						<input
							className='form-control'
							placeholder={placeholder}
							onSelect={() => {
								setSearchModalStatus(true);
							}}
							onChange={(event) => {
								delayedHandleChange(event.target.value.toLowerCase());
							}}
							id='input-style-3'
						/>
					</div>
				</div>
				{searchModalStatus ? (
					<div className='col-12' id='div-style-14'>
						<SimpleBar className='mx-n3'>
							<table className='table table-hover table-modern caption-top mb-0'>
								<tbody>
									{list.length ? (
										list.map((item) => (
											<tr
												className='table-active'
												onClick={() => {
													onSelect(item);
													setSearchModalStatus(false);
												}}
												id='tr-style-1'>
												<td>{item.name}</td>
											</tr>
										))
									) : (
										<tr className='table-active' id='tr-style-2'>
											<td>Sin resultados</td>
											<i
												className='fas fa-times'
												id='icon-style-3'
												onClick={() => {
													setSearchModalStatus(false);
												}}
											/>
										</tr>
									)}
								</tbody>
							</table>
						</SimpleBar>
					</div>
				) : null}
			</>
		);
	},
);

SearchSchoolComponent.propTypes = {
	columns: PropTypes.string,
	placeholder: PropTypes.string,
};

SearchSchoolComponent.defaultProps = {
	columns: '6',
	placeholder: 'Buscar...',
};

export default SearchSchoolComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	Input,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderSimpleToken } from '../Utility/utils';
import { debounce } from 'lodash';
import SearchSchoolComponent from '../Components/searchShool';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNewEducativeOffer = ({
	openCanvas,
	tittle,
	editItem,
	refreshList,
	onlyClose,
	educationOfferType,
	turn,
	schoolData,
}) => {
	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);
	const [schoolDataList, setSchoolDataList] = useState([]);
	const [schoolFilterList, setSchoolFilterList] = useState([]);
	const [schoolSelected, setSchoolSelected] = useState(null);
	const [educationOfferList, setEducationOfferList] = useState([]);

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const formik = useFormik({
		initialValues: {
			educationOfferType: '',
			turn: '',
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		setSchoolSelected(null);
		setEducationOfferList([]);

		if (editItem !== null) {
			setSchoolSelected(editItem.schoolData);
			setEducationOfferList(editItem.educationOffer);
		}

		setSchoolDataList(schoolData);
		setSchoolFilterList(schoolData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas, schoolData]);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = Object.keys(schoolDataList)
				.filter((key) =>
					schoolDataList[key].studentName
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search),
				)
				.map((i) => schoolDataList[i]);
			setSchoolFilterList(filterResult);
		} else {
			setSchoolFilterList(schoolDataList);
		}
	}, 700);

	const onSelectSchool = (value) => {
		setSchoolSelected(value);
	};

	const onHandlerEducationOfferTypeOrTurn = (educationOfferType, turn) => {
		if (educationOfferType && turn) {
			const newEducationOffer = {
				educationOfferType: educationOfferType,
				turn: turn,
			};
			let exist = false;

			for (var i = 0; i < educationOfferList.length; i++) {
				var item = educationOfferList[i];

				if (item.educationOfferType === educationOfferType && item.turn === turn) {
					exist = true;
					i = educationOfferList.length;
				}
			}

			if (!exist) {
				educationOfferList.push(newEducationOffer);
				setEducationOfferList(educationOfferList);

				formik.setValues({});
				formik.resetForm();
			} else {
				toast.warning('Ya se agregó una oferta educativa igual', toastStyle);
			}
		}
	};

	const onCreate = async () => {
		setLoading(true);

		if (schoolSelected !== null && educationOfferList.length > 0) {
			const body = {
				schoolId: schoolSelected._id,
				educationOffer: educationOfferList,
			};

			axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_URL}educationoffer`,
				data: body,
				headers: getHeaderSimpleToken(),
			})
				.then((response) => {
					if (response.message === 'Ok') {
						response.content.schoolData.name = schoolSelected.name;

						refreshList(response.content);
						setLoading(false);
						formik.resetForm();
						formik.setValues({});
						toggleRightCanvas();
					} else {
						if (response.message === 'Información duplicada') {
							setLoading(false);
							toast.warning('Información duplicada', toastStyle);
						} else {
							toast.error('Error al crear', toastStyle);
						}
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error('Error de conexión', toastStyle);
				});
		} else {
			if (schoolSelected === null && educationOfferList.length === 0) {
				toast.warning('No hay oferta educativa por agregar', toastStyle);
			} else {
				if (schoolSelected === null) {
					toast.warning('No se ha seleccionado escuela', toastStyle);
				} else {
					toast.warning('No se han agregado ofertas educativas', toastStyle);
				}
			}
		}

		setLoading(false);
	};

	const onUpdate = async () => {
		setLoading(true);

		if (schoolSelected !== null && educationOfferList.length > 0) {
			const body = {
				schoolId: schoolSelected._id,
				educationOffer: educationOfferList,
			};

			axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_URL}educationoffer/${editItem._id}`,
				data: body,
				headers: getHeaderSimpleToken(),
			})
				.then((response) => {
					if (response.message === 'Ok') {
						response.content.schoolData.name = schoolSelected.name;

						refreshList(response.content);
						setLoading(false);
						formik.resetForm();
						formik.setValues({});
						toggleRightCanvas();
					} else {
						if (response.message === 'Información duplicada') {
							setLoading(false);
							toast.warning('Información duplicada', toastStyle);
						} else {
							toast.error('Error al crear', toastStyle);
						}
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error('Error de conexión', toastStyle);
				});
		} else {
			if (schoolSelected === null && educationOfferList.length === 0) {
				toast.warning('No hay oferta educativa por agregar', toastStyle);
			} else {
				if (schoolSelected === null) {
					toast.warning('No se ha seleccionado escuela', toastStyle);
				} else {
					toast.warning('No se han agregado ofertas educativas', toastStyle);
				}
			}
		}

		setLoading(false);
	};

	const onDeleteEducativeOffer = (value) => {
		var list = [];
		for (var i = 0; i < educationOfferList.length; i++) {
			var item = educationOfferList[i];
			if (item !== value) {
				list.push(item);
			}
		}

		setEducationOfferList(list);
	};

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-education-offer'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<CardBody>
									<div>
										<h4>Datos de la escuela</h4>
									</div>
									<label className='col-form-label text-muted'>
										Nombre de la escuela
									</label>
									<div className='row'>
										<div className='d-flex'>
											<SearchSchoolComponent
												delayedHandleChange={delayedHandleSearch}
												list={schoolFilterList}
												onSelect={onSelectSchool}
												columns={'12'}
												placeholder={'Buscar escuela...'}
											/>
										</div>
									</div>

									{schoolSelected && (
										<div className='col-12' style={{ marginTop: '14px' }}>
											<label className='col-form-label text-muted'>
												Escuela
											</label>
											<div
												id='div-style-9'
												style={{ justifyContent: 'space-between' }}>
												<div
													className='col-10 d-flex'
													style={{
														justifyContent: 'space-between',
														paddingRight: '30px',
													}}>
													<div>{schoolSelected.name}</div>
												</div>
												<div className='d-flex col-2'>
													<div
														id='div-style-10'
														style={{
															marginRight: '16px',
														}}
													/>
													<i
														className='fas fa-trash-alt'
														id='icon-style-2'
														onClick={() => {
															onSelectSchool(null);
														}}
													/>
												</div>
											</div>
										</div>
									)}
								</CardBody>
							</Card>

							<Card id='card-into-off-canvas'>
								<CardBody>
									<div className='row'>
										<div>
											<h4>Oferta educativa</h4>
										</div>
										<div className='col-12' style={{ marginBottom: '10px' }}>
											<label className='col-form-label text-muted'>
												Oferta
											</label>
											<Input
												name='educationOfferType'
												type='select'
												value={formik.values.educationOfferType}
												onChange={(e) => {
													formik.setFieldValue(
														'educationOfferType',
														e.target.value,
													);
													onHandlerEducationOfferTypeOrTurn(
														e.target.value,
														formik.values.turn,
													);
												}}
												onBlur={formik.handleBlur}
												invalid={
													formik.touched.schoolId &&
													formik.errors.schoolId
														? true
														: false
												}>
												<option value=''>Selecciona</option>
												{educationOfferType.map((i) => (
													<option key={i._id} value={i.name}>
														{i.name}
													</option>
												))}
											</Input>
										</div>

										<div className='col-12 mt-0'>
											<label className='col-form-label text-muted'>
												Turno (s)
											</label>
											<Input
												name='turn'
												type='select'
												value={formik.values.turn}
												onChange={(e) => {
													formik.setFieldValue('turn', e.target.value);
													onHandlerEducationOfferTypeOrTurn(
														formik.values.educationOfferType,
														e.target.value,
													);
												}}
												onBlur={formik.handleBlur}
												invalid={
													formik.touched.schoolId &&
													formik.errors.schoolId
														? true
														: false
												}>
												<option value=''>Selecciona</option>
												{turn.map((i) => (
													<option key={i._id} value={i.name}>
														{i.name}
													</option>
												))}
											</Input>
										</div>

										<div className='col-12' style={{ marginTop: '28px' }}>
											<div id='div-style-8'>Registros</div>
											{educationOfferList.map((item) => (
												<div
													key={item}
													id='div-style-9'
													style={{ justifyContent: 'space-between' }}>
													<div
														className='col-10 d-flex'
														style={{
															paddingRight: '30px',
														}}>
														<div className='col-6' >{item.educationOfferType}</div>
														<div className='col-6' style={{ textAlign: "right"}}>{item.turn}</div>
													</div>
													<div className='d-flex col-2'>
														<div
															id='div-style-10'
															style={{
																marginRight: '16px',
															}}
														/>
														<i
															className='fas fa-trash-alt'
															id='icon-style-2'
															onClick={() => {
																onDeleteEducativeOffer(item);
															}}
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								</CardBody>
							</Card>

							<div
								className='d-flex justify-content-center'
								style={{ marginBottom: '30px' }}>
								{loading ? (
									<div id='div-style-7'>
										<Spinner id='spinner-style-1' size='lg' />
									</div>
								) : (
									<button
										type='submit'
										className='btn btn-success waves-effect waves-light w-50'
										id='create-btn'
										onClick={() => {
											if (tittle === 'Editar registro') {
												onUpdate();
											} else {
												onCreate();
											}
										}}>
										<i className='fas fa-save me-1' />
										{tittle === 'Editar registro' ? 'Actualizar' : 'Guardar'}
									</button>
								)}
							</div>
						</div>
					</div>
				</Container>

				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewEducativeOffer.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewEducativeOffer.defaultProps = {
	openCanvas: false,
};

export default AddNewEducativeOffer;

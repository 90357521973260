import React, { useEffect, useState } from 'react';
import EducativeOfferList from '../DashboardSuper/EducativeOfferList';
import { Container } from 'reactstrap';
import AddNewEducativeOfferAdmin from '../CanvasPages/AddNewEducativeOfferAdmin';
import { getHeaderSimpleToken } from '../Utility/utils';

const CommonEducativaOfferSpace = () => {
	const [openCanvas, setOpenCanvas] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [educationOfferTypeList, setEducationOfferTypeList] = useState([]);
	const [turnList, setTurnList] = useState([]);

	async function getEducationOfferTypeData() {
		fetch(`${process.env.REACT_APP_API_URL}educationoffer/educationOfferType`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setEducationOfferTypeList(rawData.data);
				} else {
					setEducationOfferTypeList([]);
				}
			});
	}

	async function getTurnsData() {
		fetch(`${process.env.REACT_APP_API_URL}educationoffer/turn`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setTurnList(rawData.data);
				} else {
					setTurnList([]);
				}
			});
	}

	useEffect(() => {
		getEducationOfferTypeData();
		getTurnsData();
	}, []);

	const openForm = async () => {
		setOpenCanvas(true);
		setEditItem(null);
	};

	const onlyClose = async () => {
		setOpenCanvas(false);
		setEditItem(null);
	};

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid={true}>
					<div
						className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'
						style={{ marginBottom: '12px', display: 'flex', alignItems: 'baseline' }}>
						<div
							className='span-tittle-text'
							style={{ color: 'black', fontSize: '28px' }}>
							{localStorage.getItem('SCHOOL_NAME')}
						</div>
						<div className='span-tittle-text'>| Oferta educativa</div>

						{/*<Breadcrumbs title="Constitución mexicana" breadcrumbItem="Oferta educativa" />*/}
					</div>
					<EducativeOfferList addNewEducativeOffer={openForm} />
				</Container>
			</div>

			<AddNewEducativeOfferAdmin
				onlyClose={onlyClose}
				openCanvas={openCanvas}
				editItem={editItem}
				tittle={'Nueva oferta educativa'}
				educationOfferType={educationOfferTypeList}
				turn={turnList}
			/>
		</React.Fragment>
	);
};

export default CommonEducativaOfferSpace;

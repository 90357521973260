import React from 'react';
import { Routes, Route } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

//constants
import { layoutTypes } from '../constants/layout';

// layouts
import NonAuthLayout from '../Layout/NonAuthLayout';
import VerticalLayout from '../Layout/VerticalLayout/index';
import VerticalLayoutSuper from '../Layout/VerticalLayoutSuper/index';
import HorizontalLayout from '../Layout/HorizontalLayout/index';
import { AuthProtected } from './AuthProtected';

import { superAdminRoutes, authProtectedRoutes, publicRoutes } from './routes';

const getLayout = (layoutType) => {
  /*console.log("----layoutType")
  console.log(layoutType)
  console.log("layoutType----")*/
	let Layout = VerticalLayout;
	switch (layoutType) {
		case layoutTypes.VERTICAL:
			Layout = VerticalLayout;
			break;
		case layoutTypes.HORIZONTAL:
			Layout = HorizontalLayout;
			break;
		case layoutTypes.VERTICALSUPER:
			Layout = VerticalLayoutSuper;
			break;
		default:
			break;
	}
	return Layout;
};

const Index = () => {
	const { layoutType } = useSelector((state) => ({
		layoutType: state.Layout.layoutType,
	}));

	const Layout = getLayout(layoutType);

	return (
		<Routes>
			<Route>
				{publicRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={<NonAuthLayout>{route.component}</NonAuthLayout>}
						key={idx}
						exact={true}
					/>
				))}
			</Route>

			<Route>
				{superAdminRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={<Layout>{route.component}</Layout>}
						key={idx}
						exact={true}
					/>
				))}
			</Route>

			<Route>
				{authProtectedRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={
							<AuthProtected>
								<Layout>{route.component}</Layout>
							</AuthProtected>
						}
						key={idx}
						exact={true}
					/>
				))}
			</Route>
		</Routes>
	);
};

export default Index;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Input,
	Form,
	FormFeedback,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderSimpleToken } from '../Utility/utils';

const AddNewMarket = ({ openCanvas, tittle, editItem, refreshList, onlyClose }) => {
	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const formik = useFormik({
		initialValues: {
			name: '',
			lastname: '',
			phone: '',
			email: '',
			morningshift: false,
			afternoonshift: false,
			fulltime: false,
			starttime: '',
			endtime: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Por favor, ingrese el nombre'),
			lastname: Yup.string().required('Por favor, ingrese su apellido'),
			phone: Yup.string()
				.matches(phoneRegExp, 'No es un numero de teléfono valido')
				.required('Por favor ingrese un número de teléfono valido'),
			email: Yup.string()
				.required('Se requiere ingresar un correo válido')
				.email('El formato de correo no es correcto'),
			morningshift: Yup.boolean(),
			afternoonshift: Yup.boolean(),
			fulltime: Yup.boolean(),
			starttime: Yup.string().required('Por favor, ingrese la hora de apertura'),
			endtime: Yup.string().required('Por favor, ingrese la hora de cierre'),
		}).test('checkTest', null, (obj) => {
			if (obj.morningshift || obj.afternoonshift || obj.fulltime) {
				return true;
			}
			return new Yup.ValidationError(
				'Por favor selecciona almenos un turno',
				null,
				'fulltime',
			);
		}),
		onSubmit: () => {
			setLoading(true);

			if (tittle === 'Editar registro') {
				const body = {
					name: formik.values.name,
					lastName: formik.values.lastname,
					phone: formik.values.phone,
					email: formik.values.email,
					morningShift: formik.values.morningshift,
					afternoonShift: formik.values.afternoonshift,
					fullTime: formik.values.fulltime,
					openingTime: formik.values.starttime,
					closingTime: formik.values.endtime,
				};

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}market/${editItem._id}`,
					data: body,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							let turnText = '';

							if (
								formik.values.morningshift &&
								!formik.values.afternoonshift &&
								!formik.values.fulltime
							) {
								turnText = 'Matutino';
							} else {
								if (
									!formik.values.morningshift &&
									formik.values.afternoonshift &&
									!formik.values.fulltime
								) {
									turnText = 'Vespertino';
								} else {
									if (
										!formik.values.morningshift &&
										!formik.values.afternoonshift &&
										formik.values.fulltime
									) {
										turnText = 'Tiempo completo';
									} else {
										if (
											formik.values.morningshift &&
											formik.values.afternoonshift &&
											!formik.values.fulltime
										) {
											turnText = 'Matutino y vespertino';
										} else {
											if (
												formik.values.morningshift &&
												!formik.values.afternoonshift &&
												formik.values.fulltime
											) {
												turnText = 'Matutino y tiempo completo';
											} else {
												turnText = 'Vespertino y tiempo completo';
											}
										}
									}
								}
							}

							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								phone: formik.values.phone,
								email: formik.values.email,
								morningShift: formik.values.morningshift,
								afternoonShift: formik.values.afternoonshift,
								fullTime: formik.values.fulltime,
								openingTime: formik.values.starttime,
								closingTime: formik.values.endtime,
								turn: turnText,
								status: editItem.status,
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			} else {
				const body = {
					name: formik.values.name,
					lastName: formik.values.lastname,
					phone: formik.values.phone,
					email: formik.values.email,
					morningShift: formik.values.morningshift,
					afternoonShift: formik.values.afternoonshift,
					fullTime: formik.values.fulltime,
					openingTime: formik.values.starttime,
					closingTime: formik.values.endtime,
				};

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}market`,
					data: body,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							let turnText = '';

							if (
								formik.values.morningshift &&
								!formik.values.afternoonshift &&
								!formik.values.fulltime
							) {
								turnText = 'Matutino';
							} else {
								if (
									!formik.values.morningshift &&
									formik.values.afternoonshift &&
									!formik.values.fulltime
								) {
									turnText = 'Vespertino';
								} else {
									if (
										!formik.values.morningshift &&
										!formik.values.afternoonshift &&
										formik.values.fulltime
									) {
										turnText = 'Tiempo completo';
									} else {
										if (
											formik.values.morningshift &&
											formik.values.afternoonshift &&
											!formik.values.fulltime
										) {
											turnText = 'Matutino y vespertino';
										} else {
											if (
												formik.values.morningshift &&
												!formik.values.afternoonshift &&
												formik.values.fulltime
											) {
												turnText = 'Matutino y tiempo completo';
											} else {
												turnText = 'Vespertino y tiempo completo';
											}
										}
									}
								}
							}

							const newItem = {
								_id: response.content._id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								phone: formik.values.phone,
								email: formik.values.email,
								morningShift: formik.values.morningshift,
								afternoonShift: formik.values.afternoonshift,
								fullTime: formik.values.fulltime,
								openingTime: formik.values.starttime,
								closingTime: formik.values.endtime,
								turn: turnText,
								status: 'Activo',
							};

							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);

		if (editItem !== null) {
			formik.setValues({
				id: editItem._id,
				name: editItem.name,
				lastname: editItem.lastName,
				phone: editItem.phone,
				email: editItem.email,
				starttime: editItem.openingTime,
				endtime: editItem.closingTime,
				morningshift: editItem.morningShift,
				afternoonshift: editItem.afternoonShift,
				fulltime: editItem.fullTime,
			});
		}
	}, [openCanvas]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-market'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
							<div className='col'>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<Form className='row g-4'>
											<div>
												<h4>Datos del administrador</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='lastname'
													className='col-form-label text-muted'>
													Apellidos
												</label>

												<Input
													className='form-control'
													type='text'
													id='lastname'
													name='lastname'
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.lastname &&
														formik.errors.lastname
															? true
															: false
													}
												/>
												{formik.touched.lastname &&
												formik.errors.lastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.lastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>

												<Input
													className='form-control'
													type='tel'
													id='phone'
													name='phone'
													value={formik.values.phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.phone && formik.errors.phone
															? true
															: false
													}
												/>
												{formik.touched.phone && formik.errors.phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='email'
													className='col-form-label text-muted'>
													Correo
												</label>

												<Input
													className='form-control'
													type='text'
													id='email'
													name='email'
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.email && formik.errors.email
															? true
															: false
													}
												/>
												{formik.touched.email && formik.errors.email ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.email}</div>
													</FormFeedback>
												) : null}
											</div>
											<div>
												<h4>Datos de la cooperativa</h4>
											</div>
											<div className='row d-flex'>
												<div className='col-4'>
													<label
														htmlFor='shift'
														className='col-form-label text-muted'>
														Turno
													</label>
													<div className='form-check form-check-end'>
														<Input
															className='form-check-input'
															type='checkbox'
															name='morningshift'
															checked={formik.values.morningshift}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															invalid={
																formik.touched.morningshift &&
																formik.errors.morningshift
																	? true
																	: false
															}
														/>
														<label
															className='form-check-label'
															htmlFor='morningshift'>
															Matutino
														</label>
													</div>
													<div className='form-check form-check-end'>
														<Input
															className='form-check-input'
															type='checkbox'
															name='afternoonshift'
															checked={formik.values.afternoonshift}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															invalid={
																formik.touched.afternoonshift &&
																formik.errors.afternoonshift
																	? true
																	: false
															}
														/>
														<label
															className='form-check-label'
															htmlFor='afternoonshift'>
															Vespertino
														</label>
													</div>
													<div className='form-check form-check-end'>
														<Input
															className='form-check-input'
															type='checkbox'
															name='fulltime'
															checked={formik.values.fulltime}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															invalid={
																formik.touched.fulltime &&
																formik.errors.fulltime
																	? true
																	: false
															}
														/>
														<label
															className='form-check-label'
															htmlFor='fulltime'>
															Tiempo completo
														</label>
														{formik.touched.fulltime &&
														formik.errors.fulltime ? (
															<FormFeedback type='invalid'>
																<div>{formik.errors.fulltime}</div>
															</FormFeedback>
														) : null}
													</div>
												</div>
												<div className='col-4'>
													<label
														htmlFor='fromtime'
														className='col-md-10 col-form-label'>
														Abiertdo de:
													</label>
													<div className='col-md-10'>
														<Input
															className='form-control'
															type='time'
															defaultValue='13:45:00'
															id='starttime'
															name='starttime'
															value={formik.values.starttime}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															invalid={
																formik.touched.starttime &&
																formik.errors.starttime
																	? true
																	: false
															}
														/>
														{formik.touched.starttime &&
														formik.errors.starttime ? (
															<FormFeedback type='invalid'>
																<div>{formik.errors.starttime}</div>
															</FormFeedback>
														) : null}
													</div>
												</div>
												<div className='col-4'>
													<label
														htmlFor='totime'
														className='col-md-10 col-form-label'>
														Hasta:
													</label>
													<div className='col-md-10'>
														<Input
															className='form-control'
															type='time'
															defaultValue='13:45:00'
															id='endtime'
															name='endtime'
															value={formik.values.endtime}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															invalid={
																formik.touched.endtime &&
																formik.errors.endtime
																	? true
																	: false
															}
														/>
														{formik.touched.endtime &&
														formik.errors.endtime ? (
															<FormFeedback type='invalid'>
																<div>{formik.errors.endtime}</div>
															</FormFeedback>
														) : null}
													</div>
												</div>
											</div>
										</Form>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Card>
							</div>
						</Form>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewMarket.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewMarket.defaultProps = {
	openCanvas: false,
};

export default AddNewMarket;

// Import Images
import img1 from '../../assets/images/users/avatar-1.jpg';
import img2 from '../../assets/images/users/avatar-2.jpg';
import img3 from '../../assets/images/users/avatar-3.jpg';
import img4 from '../../assets/images/users/avatar-4.jpg';
import img6 from '../../assets/images/users/avatar-6.jpg';

// Latest Transation

const LatestTransationData = [
	{
		id: 'customCheck1',
		clientId: '#AP1234',
		clientName: 'David Wiley',
		src: img2,
		date: '02 Nov, 2019',
		price: '1234',
		quantity: '1',
		color: 'success',
		status: 'Confirm',
	},
	{
		id: 'customCheck2',
		clientId: '#AP1235',
		clientName: 'Walter Jones',
		date: '04 Nov, 2019',
		price: '822',
		quantity: '2',
		color: 'success',
		status: 'Confirm',
	},
	{
		id: 'customCheck3',
		clientId: '#AP1236',
		clientName: 'Eric Ryder',
		src: img3,
		date: '	05 Nov, 2019',
		price: '1153',
		quantity: '1',
		color: 'danger',
		status: 'Cancel',
	},
	{
		id: 'customCheck4',
		clientId: '#AP1237',
		clientName: 'Kenneth Jackson',
		date: '06 Nov, 2019',
		price: '1365',
		quantity: '1',
		color: 'success',
		status: 'Confirm',
	},
	{
		id: 'customCheck5',
		clientId: '#AP1238',
		clientName: 'Ronnie Spiller',
		src: img6,
		date: '08 Nov, 2019',
		price: '740',
		quantity: '2',
		color: 'warning',
		status: 'Pending',
	},
];

// Order Status
const OrderStatusData = [
	{
		id: 1,
		title: '1A',
		color: '#F12D53',
		value: '6.67',
	},
	{
		id: 2,
		title: '1B',
		color: '#EBB241',
		value: '7.78',
	},
	{
		id: 3,
		title: '2A',
		color: '#EBB241',
		value: '8.07',
	},
	{
		id: 4,
		title: '2B',
		color: '##40C074',
		value: '8.57',
	},
	{
		id: 5,
		title: '3A',
		color: '##40C074',
		value: '8.98',
	},
];

// Overview

const OverViewData = [
	{
		id: 1,
		title: 'Año actual',
		count: '8.87',
		color: '#41B6A0',
	},
	{
		id: 2,
		title: 'Año anterior',
		count: '8.89',
		color: '#FF3256',
	},
	{
		id: 3,
		title: 'Promedio insuficiente',
		count: '47%',
		color: '#359AFA',
	},
];

// SocialSource

const SocialSourceData = [
	{
		id: 1,
		title: 'Obesidad',
		count: '110',
		bgcolor: '#F53D61',
	},
	{
		id: 2,
		title: 'Sobrepeso',
		count: '100',
		bgcolor: '#63AFFD',
	},
	{
		id: 3,
		title: 'Normal',
		count: '107',
		bgcolor: '#2CA491',
	},
	{
		id: 4,
		title: 'Desnutrición',
		count: '24',
		bgcolor: '#6E79D7',
	},
];

// Notifications

const NotificationsData = [
	{
		id: 1,
		name: 'Rodolfo Castro',
		desc: 'Matematicas, español, ciencias',
		amount: '30',
		src: img2,
	},
	{
		id: 2,
		name: 'María Luisa Guevara',
		desc: 'Matematicas',
		amount: '17',
		src: img1,
	},
	{
		id: 3,
		name: 'Edgar Perez',
		desc: 'Educación física',
		amount: '21',
		src: img4,
	},
	{
		id: 4,
		name: 'Rosalva Flores',
		desc: 'Ética, artes',
		amount: '26',
		src: img3,
	},
	{
		id: 5,
		name: 'Pedro Bay',
		desc: 'Matematicas, español, ciencias',
		amount: '19',
		src: img6,
	},
];

// SocialSource

const SaleDonutData = [
	{
		id: 1,
		title: 'Bebidas',
		count: '22',
		bgcolor: '#01A685',
	},
	{
		id: 2,
		title: 'Comida',
		count: '41',
		bgcolor: '#9599B3',
	},
	{
		id: 3,
		title: 'Botanas',
		count: '18',
		bgcolor: '#192737',
	},
	{
		id: 4,
		title: 'Dulces',
		count: '18',
		bgcolor: '#4F8DCB',
	},
];

// DegreeData

const DegreeData = [
	{
		id: 1,
		name: 'Primaria',
	},
	{
		id: 2,
		name: 'Secundaria',
	},
	{
		id: 3,
		name: 'Preparatoria',
	},
	{
		id: 4,
		name: 'Licenciatura',
	},
	{
		id: 5,
		name: 'Maestría',
	},
	{
		id: 6,
		name: 'Doctorado',
	},
];

export { LatestTransationData, OrderStatusData, OverViewData, SocialSourceData, NotificationsData, SaleDonutData, DegreeData };

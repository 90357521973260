import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { toInteger, toString } from 'lodash';
import 'moment/locale/es';

export function generateKardexPdf(list, studentName, schoolYear, level, group, schoolName, finalAverageCourse) {
    const pdf = new JsPDF();
    const totalPagesExp = '{total_pages_count_string}';
    const width = pdf.internal.pageSize.getWidth();
    pdf.setFontSize(14);
    pdf.setFont(undefined, 'bold');
    pdf.text('BOLETA DE CALIFICACIONES', width / 2, 20, { align: 'center' });
    pdf.text(`Ciclo escolar: ${schoolYear} `, width / 2, 30, { align: 'center' });

    // Datos del alumno
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'bold');
    pdf.text('Datos del alumno(a)', 14, 50);
    pdf.setFont(undefined, 'normal');

    pdf.text(studentName, 14, 60);

    pdf.setFont(undefined, 'bold');
    pdf.text('Nombre de la escuela', 110, 50);
    pdf.setFont(undefined, 'normal');
    pdf.text(schoolName, 110, 60);

    // Grado, Grupo y Turno
    pdf.setFont(undefined, 'bold');

    pdf.text(`Grado: `, 14, 80);
    pdf.setFont(undefined, 'normal');
    pdf.text(level, 29, 80);

    pdf.setFont(undefined, 'bold');
    pdf.text(`Grupo: `, 60, 80);
    pdf.setFont(undefined, 'normal');
    pdf.text(group, 75, 80);

    pdf.setFont(undefined, 'bold');
    pdf.text('Turno:', 110, 80);
    pdf.setFont(undefined, 'normal');
    pdf.text('Matutino', 126, 80);

    // Tabla de calificaciones
    let partials = [];
    list[0].grades.forEach((item, idx) => {
        partials.push("Parcial " + (idx + 1));
    });
    let columnsPartials = partials.map((item, idx) => {
        return { dataKey: "p" + (idx + 1), header: item };
    });

    pdf.autoTable({
        startY: 95,
        head: [['Materia', ...partials, 'Promedio final']],
        headStyles: { fillColor: [11, 177, 151], textColor: [255, 255, 255] },
        columns: [
            { dataKey: 'subject', header: 'Materia' },
            ...columnsPartials,
            { dataKey: 'finalAverageSubject', header: 'Promedio final' },
        ],
        body: bodyRows(list),
    });

    // Promedio final escolar
    pdf.text(`Promedio final escolar`, 14, pdf.autoTable.previous.finalY + 10);
    pdf.text(toString(finalAverageCourse.toFixed(2)), width - 30, pdf.autoTable.previous.finalY + 10);

    pdf.save('boleta_calificaciones.pdf');
}

function bodyRows(list) {
    const body = [];

    list.map(({ _id, grades, subject }, ind) =>
        body.push({
            subject: subject,
            ...grades.reduce((acc, grade, index) => {
                acc['p' + (index + 1)] = grade; // index + 1 para ajustar los números parciales (p1, p2, p3, ...)
                return acc;
            }, {}),
            finalAverageSubject: (grades.reduce((acc, grade) => {
                if (grade === "") return parseFloat(acc);
                return parseFloat(acc) + parseFloat(grade)
            }, 0) / grades.filter(grade => grade !== "").length).toFixed(2),
        }),
    );
    return body;
}
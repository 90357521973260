const SidebarData = [
	{
		label: 'Menu',
		isMainMenu: true,
	},
	{
		label: 'Dashboard',
		icon: 'mdi mdi-home-variant-outline',
		url: '/dashboard',
		issubMenubadge: true,
	},
	{
		label: 'Personal',
		icon: 'fab fa-black-tie',
		url: '/personnel',
		issubMenubadge: true,
	},
	{
		label: 'Alumnos',
		icon: 'fas fa-user-graduate',
		subItem: [
			{ sublabel: 'Registros', link: '/students' },
			{ sublabel: 'Salud', link: '/health' },
			{ sublabel: 'Calificaciones', link: '/grades' },
		],
	},
	{
		label: 'Padres',
		icon: 'fas fa-portrait',
		url: '/parents',
		issubMenubadge: true,
	},
	{
		label: 'Docentes',
		icon: 'fas fa-chalkboard-teacher',
		subItem: [
			{ sublabel: 'Registros', link: '/teachers' },
			{ sublabel: 'Horarios', link: '/teacherSchedules' },
			{ sublabel: 'Calificaciones', link: '/schoolgrades' },
		],
	},
	{
		label: 'Cooperativas',
		icon: 'fas fa-building',
		subItem: [
			{ sublabel: 'Registros', link: '/markets' },
			{ sublabel: 'POS', link: '/pos' },
		],
	},
	{
		label: 'Usuarios',
		icon: 'fas fa-user',
		url: '/users',
		issubMenubadge: true,
	},
	{
		label: 'Configuración',
		icon: 'fas fa-cogs',
		url: '/settings',
		issubMenubadge: true,
	},
];

export default SidebarData;

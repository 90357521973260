/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	CardImg,
	FormFeedback,
	Form,
	Input,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderMultipart, getHeaderSimpleToken } from '../Utility/utils';
import { ToastContainer, toast } from 'react-toastify';

// img
import avatarDefault from '../../assets/images/users/StudentAvatar.png';

const AddNewStudent = ({ openCanvas, tittle, editItem, refreshList, onlyClose, levelData, grouplevelData, turnData, genderData }) => {
	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const [isRight, setIsRight] = useState(false);
	const [avatarImage, setAvatarImage] = useState('');
	const avatarImageUpload = () => {
		// eslint-disable-next-line no-unused-expressions
		document.getElementById('avatar').files[0];
		setAvatarImage(URL.createObjectURL(document.getElementById('avatar').files[0]));
	};
	const [loading, setLoading] = useState(false);
	const [numberOfExam, setNumberOfExam] = useState(0);

	const formik = useFormik({
		initialValues: {
			avatar: '',
			enrollment: '',
			grade: '',
			group: '',
			turn: '',
			name: '',
			lastname: '',
			birthdate: '',
			age: '',
			gender: '',
		},
		validationSchema: Yup.object({
			enrollment: Yup.string().required('Por favor, ingrese la matrícula'),
			grade: Yup.string().required('Por favor, ingrese el grado'),
			group: Yup.string().required('Por favor, ingrese el grupo'),
			turn: Yup.string().required('Por favor, ingrese el turno'),
			name: Yup.string().required('Por favor, ingrese el nombre'),
			lastname: Yup.string().required('Por favor, ingrese su apellido'),
			birthdate: Yup.string().required('Por favor, ingrese la fehca de nacimiento'),
			gender: Yup.string().required('Por favor, ingrese el género'),
		}),
		onSubmit: () => {
			setLoading(true);

			if (tittle === 'Editar registro') {
				const formData = new FormData();
				/* if (
					formik.values.avatar !== null &&
					formik.values.avatar !== '' &&
					!formik.values.avatar.toString().includes('https://res.cloudinary.com')
				)
					formData.append('image', formik.values.avatar); */
				formData.append('controlNumber', formik.values.enrollment);
				formData.append('level', formik.values.grade);
				formData.append('group', formik.values.group);
				formData.append('turn', formik.values.turn);
				formData.append('name', formik.values.name);
				formData.append('lastName', formik.values.lastname);
				formData.append('birthdate', formik.values.birthdate);
				formData.append('age', formik.values.age);
				formData.append('gender', formik.values.gender);

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}student/${editItem._id}`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								birthdate: formik.values.birthdate,
								age: formik.values.age,
								gender: formik.values.gender,
								academySheetData: [{
									controlNumber: formik.values.enrollment,
									level: formik.values.grade,
									group: formik.values.group,
									turn: formik.values.turn,
								}]
							};

							newItem.parentData = editItem.parentData;
							newItem.additionalParent = editItem.additionalParent;

							refreshList(newItem);
							setLoading(false);
							toast.success('La operación se realizó con éxito', toastStyle);
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
								toast.warning(response.content, toastStyle);
							} else {
								setLoading(false);
								toast.error('Error al crear', toastStyle);
							}
						}
					})
					.catch((error) => { });
			} else {
				const formData = new FormData();
				formData.append('image', formik.values.avatar);
				formData.append('controlNumber', formik.values.enrollment);
				formData.append('level', formik.values.grade);
				formData.append('group', formik.values.group);
				formData.append('turn', formik.values.turn);
				formData.append('name', formik.values.name);
				formData.append('lastName', formik.values.lastname);
				formData.append('birthdate', formik.values.birthdate);
				formData.append('age', formik.values.age);
				formData.append('gender', formik.values.gender);
				formData.append('educativeOfferName', localStorage.getItem('EDUCATIVE_OFFER_NAME'));
				formData.append('schoolName', localStorage.getItem('SCHOOL_NAME'));

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}student`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const additionalParent = {
								name: '',
								lastName: '',
								phone: '',
								email: '',
							};

							const newItem = {
								_id: response.content.id,
								name: formik.values.name,
								lastName: formik.values.lastname,
								birthdate: formik.values.birthdate,
								age: formik.values.age,
								gender: formik.values.gender,
								academySheetData: [{
									controlNumber: formik.values.enrollment,
									level: formik.values.grade,
									group: formik.values.group,
									turn: formik.values.turn,
								}]
							};

							newItem.parentData = [additionalParent];
							newItem.additionalParent = additionalParent;

							refreshList(newItem);
							setLoading(false);
							setAvatarImage(avatarDefault);
							formik.setValues({});
							formik.resetForm();
							toast.success('La operación se realizó con éxito', toastStyle);
							addStudentGrade(response.content.id);
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
								toast.warning(response.content, toastStyle);
							} else {
								setLoading(false);
								toast.error('Error al crear', toastStyle);
							}
						}
					})
					.catch((error) => { });
			}
		},
	});

	const addStudentGrade = async (idStudent) => {
		const subjectData = await getMateriaData();
		let commas = ""
		for (let index = 0; index < numberOfExam - 1; index++) {
			commas += ","
		};
		subjectData.forEach(element => {
			axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_URL}grades`,
				data: JSON.stringify({
					student: idStudent,
					subject: element._id,
					grade: [commas],
					schoolYear: "2023-2024",
				}),
				headers: getHeaderSimpleToken(),
			})
		});
	};

	async function getMateriaData() {
		const response = await fetch(`${process.env.REACT_APP_API_URL}subject`, {
			headers: getHeaderSimpleToken(),
		})

		const rawData = await response.json();
		let subjectData = [];
		if (rawData.data.length !== 0) {
			subjectData = rawData.data;
		}
		return subjectData;
	};

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	function educationoffers() {
		fetch(`${process.env.REACT_APP_API_URL}educationoffer/school`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setNumberOfExam(rawData.data[0].numberOfExams);
				}
			});
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		setAvatarImage(avatarDefault);
		educationoffers();

		if (editItem !== null) {
			formik.setValues({
				id: editItem._id,
				enrollment: editItem.academySheetData[0].controlNumber,
				grade: editItem.academySheetData[0].level,
				group: editItem.academySheetData[0].group,
				turn: editItem.academySheetData[0].turn,
				name: editItem.name,
				lastname: editItem.lastName,
				birthdate: editItem.birthdate,
				age: editItem.age,
				gender: editItem.gender,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas]);

	function handleBirdthdate(value) {
		const dateSelected = value.target.value;
		var today = new Date();
		var birthDate = new Date(dateSelected);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}

		formik.setFieldValue('birthdate', dateSelected);
		formik.setFieldValue('age', age);
	}

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-students'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<CardBody>
									<div className='row d-flex justify-content-between'>
										<div className='col-2'>
											<CardImg
												id='img-bgc-g'
												src={avatarImage}
												alt='Kuali'
												className='rounded avatar-lg'
											/>
										</div>
										<div className='col-9'>
											<div className='customfile'>
												<Input
													type='file'
													name='avatar'
													className='inputfile'
													id='avatar'
													accept='image/jpg, image/jpeg, image/png'
													lang='es'
													onChange={(e) => {
														// eslint-disable-next-line no-unused-expressions
														formik.setFieldValue(
															'avatar',
															e.currentTarget.files[0],
														);
														avatarImageUpload();
													}}
												/>
											</div>
											<div className='mt-2'>
												<span>
													Colocar la imagen ayuda a aumentar la seguridad
													en la institución.
												</span>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<Card id='card-into-off-canvas'>
								<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
									<CardBody>
										<div className='row'>
											<div>
												<h4>Datos personales</h4>
											</div>
											<div className='col-6'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombres
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label
													htmlFor='lastname'
													className='col-form-label text-muted'>
													Apellidos
												</label>
												<Input
													className='form-control'
													type='text'
													id='lastname'
													name='lastname'
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.lastname &&
															formik.errors.lastname
															? true
															: false
													}
												/>
												{formik.touched.lastname &&
													formik.errors.lastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.lastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label
													htmlFor='age'
													className='col-form-label text-muted'>
													Fecha de nacimiento
												</label>
												<Input
													className='form-control'
													type='date'
													id='example-date-input'
													name='birthdate'
													value={formik.values.birthdate}
													onChange={handleBirdthdate}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.birthdate &&
															formik.errors.birthdate
															? true
															: false
													}
												/>
												{formik.touched.birthdate &&
													formik.errors.birthdate ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.birthdate}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label
													htmlFor='age'
													className='col-form-label text-muted'>
													Edad
												</label>
												<Input
													className='form-control'
													type='text'
													id='age'
													name='age'
													value={formik.values.age}
													disabled={true}
													invalid={
														formik.touched.age && formik.errors.age
															? true
															: false
													}
												/>
											</div>
											<div className='col-6'>
												<label className='col-form-label text-muted'>
													Sexo
												</label>
												<Input
													name='gender'
													type='select'
													value={formik.values.gender}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.gender &&
															formik.errors.gender
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{genderData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.gender &&
													formik.errors.gender ? (
													<FormFeedback type='invalid'>
														<div>
															{formik.errors.gender}
														</div>
													</FormFeedback>
												) : null}
											</div>

											<div>
												<h4 className='mt-3'>Datos escolares</h4>
											</div>
											<div className='col-6'>
												<label
													htmlFor='enrollment'
													className='col-form-label text-muted'>
													Matrícula
												</label>
												<Input
													className='form-control'
													type='text'
													id='enrollment'
													name='enrollment'
													value={formik.values.enrollment}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.enrollment &&
															formik.errors.enrollment
															? true
															: false
													}
												/>
												{formik.touched.enrollment &&
													formik.errors.enrollment ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.enrollment}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label className='col-form-label text-muted'>
													Grado
												</label>
												<Input
													name='grade'
													type='select'
													value={formik.values.grade}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.grade &&
															formik.errors.grade
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{levelData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.grade &&
													formik.errors.grade ? (
													<FormFeedback type='invalid'>
														<div>
															{formik.errors.grade}
														</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label className='col-form-label text-muted'>
													Grupo
												</label>
												<Input
													name='group'
													type='select'
													value={formik.values.group}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.group &&
															formik.errors.group
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{grouplevelData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.group &&
													formik.errors.group ? (
													<FormFeedback type='invalid'>
														<div>
															{formik.errors.group}
														</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label className='col-form-label text-muted'>
													Turno
												</label>
												<Input
													name='turn'
													type='select'
													value={formik.values.turn}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.turn &&
															formik.errors.turn
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{turnData.map((i) => (
														<option key={i.id} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.turn &&
													formik.errors.turn ? (
													<FormFeedback type='invalid'>
														<div>
															{formik.errors.turn}
														</div>
													</FormFeedback>
												) : null}
											</div>
										</div>

										{tittle === 'Editar registro' ? (
											<div className='row'>
												<div>
													<h4 className='mt-3'>Tutores</h4>
												</div>
												<div className='col-6 mt-0'>
													<label
														htmlFor='tutor'
														className='col-form-label text-muted'>
														Tutor principal
													</label>
													<Input
														className='form-control'
														type='text'
														value={
															editItem
																? editItem.parentData[0].name +
																' ' +
																editItem.parentData[0].lastName
																: ''
														}
														disabled
													/>
												</div>
												<div className='col-6 mt-0'>
													<label
														htmlFor='contact'
														className='col-form-label text-muted'>
														Contacto
													</label>
													<Input
														className='form-control'
														type='text'
														value={
															editItem
																? editItem.parentData[0].phone ??
																editItem.parentData[0].email ??
																''
																: ''
														}
														disabled
													/>
												</div>
												<div className='col-6 mt-0'>
													<label
														htmlFor='tutor'
														className='col-form-label text-muted'>
														Tutor adicional
													</label>
													<Input
														className='form-control'
														type='text'
														value={
															editItem
																? editItem.additionalParent.name +
																' ' +
																editItem.additionalParent.lastName
																: ''
														}
														disabled
													/>
												</div>
												<div className='col-6 mt-0'>
													<label
														htmlFor='contact'
														className='col-form-label text-muted'>
														Contacto
													</label>
													<Input
														className='form-control'
														type='text'
														value={
															editItem
																? editItem.additionalParent.phone ??
																editItem.additionalParent.email ??
																''
																: ''
														}
														disabled
													/>
												</div>
											</div>
										) : null}
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
							</Card>
						</div>
					</div>
				</Container>

				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewStudent.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewStudent.defaultProps = {
	openCanvas: false,
};

export default AddNewStudent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Input,
	Form,
	FormFeedback,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { getHeaderSimpleToken } from '../Utility/utils';
import SearchComponent from '../Components/search';

const AddNewTutor = ({ openCanvas, tittle, editItem, refreshList, onlyClose }) => {
	const [isRight, setIsRight] = useState(false);
	const [studentDataList, setStudentDataList] = useState([]);
	const [studentSelected, setStudentSelected] = useState('');
	const [studentListSelected, setStudentListSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [updateKey, setUpdateKey] = useState(false);
	const [searchPrincipal, setSearchPrincipal] = useState([]);
	const [searchAdditional, setSearchAdditional] = useState([]);

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const formik = useFormik({
		initialValues: {
			p_tutorname: '',
			p_tutorlastname: '',
			p_phone: '',
			p_email: '',
			p_relationship: '',
			a_tutorname: '',
			a_tutorlastname: '',
			a_phone: '',
			a_email: '',
			a_relationship: '',
		},
		validationSchema: Yup.object({
			p_tutorname: Yup.string().required('Por favor, el nombre del padre o tutor principal'),
			p_tutorlastname: Yup.string().required(
				'Por favor, ingrese el apellido del padre o tutor principal',
			),
			p_phone: Yup.string()
				.matches(phoneRegExp, 'No es un número de teléfono válido')
				.required('Por favor, ingrese el número de teléfono del padre o tutor principal'),
			p_email: Yup.string().email('El formato de correo no es correcto'),
			p_relationship: Yup.string().required('Por favor, ingrese el parentesco con el alumno'),
			a_tutorname: Yup.string().required(
				'Por favor, ingrese el número de teléfono del padre o tutor adicional',
			),
			a_tutorlastname: Yup.string().required(
				'Por favor, ingrese el apellido del padre o tutor principal',
			),
			a_phone: Yup.string()
				.matches(phoneRegExp, 'No es un número de teléfono válido')
				.required('Por favor, ingrese un número de teléfono del padre o tutor adicional'),
			a_email: Yup.string().email('El formato de correo no es correcto'),
			a_relationship: Yup.string().required('Por favor, ingrese el parentesco con el alumno'),
		}),
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setStudentDataList([]);
		setStudentSelected('');
		setStudentListSelected([]);
		setLoading(false);
		setSearchPrincipal([]);
		setSearchAdditional([]);

		if (editItem !== null) {
			formik.setValues({
				p_tutorname: editItem.name,
				p_tutorlastname: editItem.lastName,
				p_phone: editItem.phone,
				p_email: editItem.email,
				p_relationship: editItem.relationship,
				a_tutorname: editItem.parentData[0].name,
				a_tutorlastname: editItem.parentData[0].lastName,
				a_phone: editItem.parentData[0].phone,
				a_email: editItem.parentData[0].email,
				a_relationship: editItem.parentData[0].relationship,
			});

			setStudentListSelected(editItem.studentData);
		}

		setUpdateKey(0);
	}, [openCanvas]);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}student/search/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setStudentDataList(json.data);
					} else {
						setStudentDataList([]);
					}
				});
		}
	}, 700);

	function addToStudentList() {
		var exist = false;

		for (var i = 0; i < studentListSelected.length; i++) {
			var item = studentListSelected[i];
			if (item._id === studentSelected._id) {
				exist = true;
				i = studentListSelected.length;
			}
		}

		if (!exist) {
			var studentList = studentListSelected;
			studentList.push(studentSelected);
			setStudentListSelected(studentList);
			setUpdateKey(updateKey + 1);
		}
	}

	const onDeleteDiseases = (value) => {
		var list = [];
		for (var i = 0; i < studentListSelected.length; i++) {
			var item = studentListSelected[i];
			if (item !== value) {
				list.push(item);
			}
		}

		setStudentListSelected(list);
	};

	const onClickStudent = (value) => {
		for (var i = 0; i < studentListSelected.length; i++) {
			var item = studentListSelected[i];
			if (item === value) {
				setStudentSelected(item);
				i = studentListSelected.length;
			}
		}
	};

	const onSave = async () => {
		setLoading(true);

		if (tittle === 'Editar registro') {
			var parentList = [];

			parentList.push({
				_id: editItem._id,
				name: formik.values.p_tutorname,
				lastName: formik.values.p_tutorlastname,
				phone: formik.values.p_phone,
				email: formik.values.p_email,
				relationship: formik.values.p_relationship,
				isPrincipal: true,
			});

			parentList.push({
				_id: editItem.parentData[0]._id,
				name: formik.values.a_tutorname,
				lastName: formik.values.a_tutorlastname,
				phone: formik.values.a_phone,
				email: formik.values.a_email,
				relationship: formik.values.a_relationship,
				isPrincipal: false,
			});

			const body = {
				parents: parentList,
				students: studentListSelected,
			};

			axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_URL}parent`,
				data: body,
				headers: getHeaderSimpleToken(),
			})
				.then((response) => {
					if (response.message === 'Ok') {
						refreshList(response.content);
						setLoading(false);
						formik.resetForm();
						formik.setValues({});
						toggleRightCanvas();
					} else {
						if (response.message === 'Información duplicada') {
							setLoading(false);
						} else {
							setLoading(false);
						}
					}
				})
				.catch((error) => {});
		} else {
			var parentListCreate = [];

			parentListCreate.push({
				name: formik.values.p_tutorname,
				lastName: formik.values.p_tutorlastname,
				phone: formik.values.p_phone,
				email: formik.values.p_email,
				relationship: formik.values.p_relationship,
				isPrincipal: true,
			});

			parentListCreate.push({
				name: formik.values.a_tutorname,
				lastName: formik.values.a_tutorlastname,
				phone: formik.values.a_phone,
				email: formik.values.a_email,
				relationship: formik.values.a_relationship,
				isPrincipal: false,
			});

			const body = {
				parents: parentListCreate,
				students: studentListSelected,
			};

			axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_URL}parent`,
				data: body,
				headers: getHeaderSimpleToken(),
			})
				.then((response) => {
					if (response.message === 'Ok') {
						refreshList(response.content);
						setLoading(false);
						formik.resetForm();
						formik.setValues({});
						toggleRightCanvas();
					} else {
						if (response.message === 'Información duplicada') {
							setLoading(false);
						} else {
							setLoading(false);
						}
					}
				})
				.catch((error) => {});
		}
	};

	const delayedHandleChange = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}parent/search/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setSearchPrincipal(json.data);
					} else {
						setSearchPrincipal([]);
					}
				});
		}
	}, 700);

	const delayedHandleChangeAdditional = debounce((search) => {
		if (search !== '') {
			fetch(`${process.env.REACT_APP_API_URL}parent/search/${search}`, {
				headers: getHeaderSimpleToken(),
			})
				.then((res) => res.json())
				.then((json) => {
					if (json.message === 'Ok') {
						setSearchAdditional(json.data);
					} else {
						setSearchAdditional([]);
					}
				});
		}
	}, 700);

	const onSelectedTutor = (value) => {
		formik.setValues({
			...formik.values,
			p_tutorname: value.name,
			p_tutorlastname: value.lastName,
			p_phone: value.phone,
			p_email: value.email,
		});
	};

	const onSelectedAdditionalTutor = (value) => {
		formik.setValues({
			...formik.values,
			a_tutorname: value.name,
			a_tutorlastname: value.lastName,
			a_phone: value.phone,
			a_email: value.email,
		});
	};

	const onSelectStudent = (value) => {
		setStudentSelected(value);
	};

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-tutor'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Form className='row-horizontal'>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div className='row'>
											<div className='d-flex'>
												<div className='col-6'>
													<h4>Tutor principal</h4>
												</div>
												<SearchComponent
													delayedHandleChange={delayedHandleChange}
													list={searchPrincipal}
													onSelect={onSelectedTutor}
													placeholder={'Buscar tutor...'}
												/>
											</div>

											<div className='col-6'>
												<label
													htmlFor='p_tutorname'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='p_tutorname'
													name='p_tutorname'
													value={formik.values.p_tutorname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.p_tutorname &&
														formik.errors.p_tutorname
															? true
															: false
													}
												/>
												{formik.touched.p_tutorname &&
												formik.errors.p_tutorname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.p_tutorname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label
													htmlFor='p_tutorlastname'
													className='col-form-label text-muted'>
													Apellido
												</label>

												<Input
													className='form-control'
													type='text'
													id='p_tutorlastname'
													name='p_tutorlastname'
													value={formik.values.p_tutorlastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.p_tutorlastname &&
														formik.errors.p_tutorlastname
															? true
															: false
													}
												/>
												{formik.touched.p_tutorlastname &&
												formik.errors.p_tutorlastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.p_tutorlastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='p_phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>

												<Input
													className='form-control'
													type='number'
													id='p_phone'
													name='p_phone'
													value={formik.values.p_phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.p_phone &&
														formik.errors.p_phone
															? true
															: false
													}
												/>
												{formik.touched.p_phone && formik.errors.p_phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.p_phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='example-text-input'
													className='col-form-label text-muted'>
													Correo
												</label>
												<Input
													className='form-control'
													type='text'
													id='p_email'
													name='p_email'
													value={formik.values.p_email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.p_email &&
														formik.errors.p_email
															? true
															: false
													}
												/>
												{formik.touched.p_email && formik.errors.p_email ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.p_email}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='p_relationship'
													className='col-form-label text-muted'>
													Parentesco
												</label>
												<Input
													className='form-control'
													type='text'
													id='p_relationship'
													name='p_relationship'
													value={formik.values.p_relationship}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.p_relationship &&
														formik.errors.p_relationship
															? true
															: false
													}
												/>
												{formik.touched.p_relationship &&
												formik.errors.p_relationship ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.p_relationship}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='d-flex mt-4'>
												<div className='col-6'>
													<h4 className='m-0'>Tutor adicional</h4>
												</div>
												<SearchComponent
													delayedHandleChange={
														delayedHandleChangeAdditional
													}
													list={searchAdditional}
													onSelect={onSelectedAdditionalTutor}
													placeholder={'Buscar tutor...'}
												/>
											</div>

											<div className='col-6'>
												<label
													htmlFor='a_tutorname'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='a_tutorname'
													name='a_tutorname'
													value={formik.values.a_tutorname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.a_tutorname &&
														formik.errors.a_tutorname
															? true
															: false
													}
												/>
												{formik.touched.a_tutorname &&
												formik.errors.a_tutorname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.a_tutorname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6'>
												<label
													htmlFor='a_tutorlastname'
													className='col-form-label text-muted'>
													Apellido
												</label>

												<Input
													className='form-control'
													type='text'
													id='a_tutorlastname'
													name='a_tutorlastname'
													value={formik.values.a_tutorlastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.a_tutorlastname &&
														formik.errors.a_tutorlastname
															? true
															: false
													}
												/>
												{formik.touched.a_tutorlastname &&
												formik.errors.a_tutorlastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.a_tutorlastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='a_phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>

												<Input
													className='form-control'
													type='number'
													id='a_phone'
													name='a_phone'
													value={formik.values.a_phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.a_phone &&
														formik.errors.a_phone
															? true
															: false
													}
												/>
												{formik.touched.a_phone && formik.errors.a_phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.a_phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='a_email'
													className='col-form-label text-muted'>
													Correo
												</label>
												<Input
													className='form-control'
													type='text'
													id='a_email'
													name='a_email'
													value={formik.values.a_email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.a_email &&
														formik.errors.a_email
															? true
															: false
													}
												/>
												{formik.touched.a_email && formik.errors.a_email ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.a_email}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4'>
												<label
													htmlFor='a_relationship'
													className='col-form-label text-muted'>
													Parentesco
												</label>
												<Input
													className='form-control'
													type='text'
													id='a_relationship'
													name='a_relationship'
													value={formik.values.a_relationship}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.a_relationship &&
														formik.errors.a_relationship
															? true
															: false
													}
												/>
												{formik.touched.a_relationship &&
												formik.errors.a_relationship ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.a_relationship}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
								</Card>
							</Form>

							<Card id='card-into-off-canvas'>
								<CardBody>
									<div className='row'>
										<div>
											<h4>Datos del alumno</h4>
										</div>
										<div className='col-8'>
											<SearchComponent
												delayedHandleChange={delayedHandleSearch}
												list={studentDataList}
												onSelect={onSelectStudent}
												columns={'12'}
												placeholder={'Buscar alumno...'}
											/>
										</div>
										<div className='col-4'>
											<div className='d-flex justify-content-star'>
												<button
													className='btn btn-info waves-effect waves-light'
													onClick={addToStudentList}>
													Agregar
												</button>
											</div>
										</div>

										<div className='col-4'>
											<label
												htmlFor='level'
												className='col-form-label text-muted'>
												Grado
											</label>
											<Input
												className='form-control'
												type='text'
												value={studentSelected.academySheetData ? studentSelected.academySheetData.level : ''}
												disabled={true}
											/>
										</div>
										<div className='col-4'>
											<label
												htmlFor='group'
												className='col-form-label text-muted'>
												Grupo
											</label>
											<Input
												className='form-control'
												type='text'
												value={studentSelected.academySheetData ? studentSelected.academySheetData.group : ''}
												disabled={true}
											/>
										</div>
										<div className='col-4'>
											<label
												htmlFor='turn'
												className='col-form-label text-muted'>
												Turno
											</label>
											<Input
												className='form-control'
												type='text'
												value={studentSelected.academySheetData ? studentSelected.academySheetData.turn : ''}
												disabled={true}
											/>
										</div>

										<div className='col-12 mt-2'>
											<div id='div-style-8'>Alumnos</div>
											{studentListSelected.map((item) => (
												<div key={item} id='div-style-9'>
													<div
														className='col-10'
														onClick={() => {
															onClickStudent(item);
														}}>
														<text>
															{item.name + ' ' + item.lastName}
														</text>
													</div>
													<div className='d-flex'>
														<div id='div-style-10' />
														<i
															className='fas fa-trash-alt'
															id='icon-style-2'
															onClick={() => {
																onDeleteDiseases(item);
															}}
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								</CardBody>
							</Card>

							<CardFooter id='card-footer-off-canvas'>
								<div className='d-flex justify-content-center'>
									{loading ? (
										<div id='div-style-7'>
											<Spinner id='spinner-style-1' size='lg' />
										</div>
									) : (
										<button
											type='submit'
											className='btn btn-success waves-effect waves-light w-50'
											id='create-btn'
											onClick={onSave}>
											<i className='fas fa-save me-1' />
											{tittle === 'Editar registro'
												? 'Actualizar'
												: 'Guardar'}
										</button>
									)}
								</div>
							</CardFooter>
						</div>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewTutor.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewTutor.defaultProps = {
	openCanvas: false,
};

export default AddNewTutor;

import React from 'react';
import LineColumnArea from './LineColumnArea';

import { Card, CardBody, Col, Row } from 'reactstrap';

import { OverViewData } from '../../CommonData/Data/index';

const OverView = () => {
	return (
		<React.Fragment>
			<Col xl={8} style={{ marginBottom: '24px' }}>
				<Card style={{ height: '100%' }}>
					<CardBody>
						<div className='d-flex align-items-center'>
							<div className='flex-grow-1'>
								<div
									style={{ fontSize: '24px', fontWeight: 'bold', color: '#000' }}>
									Comparativa de promedio general
								</div>
							</div>
							<div className='flex-shrink-0'>
								<div>
									<button
										type='button'
										className='btn btn-soft-secondary btn-sm me-1'>
										Mes
									</button>
									<button
										type='button'
										className='btn btn-soft-primary btn-sm me-1'>
										Semestre
									</button>
									<button
										type='button'
										className='btn btn-soft-secondary btn-sm me-1'>
										Año
									</button>
								</div>
							</div>
						</div>
						<div>
							<LineColumnArea />
						</div>
					</CardBody>
					<CardBody className='border-top'>
						<div className='text-muted text-center'>
							<Row>
								{OverViewData.map((item, key) => (
									<Col md={4} key={key} className='border-end'>
										<div>
											<div>
												<i
													className={'mdi mdi-circle font-size-12 me-1'}
													style={{ color: item.color }}
												/>
												{item.title}
											</div>
											<div
												style={{
													fontSize: '35px',
													color: '#2C2C2C',
													fontWeight: 'bold',
												}}>
												{item.count}
											</div>
										</div>
									</Col>
								))}
							</Row>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default OverView;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	Input,
	FormFeedback,
	Form,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderSimpleToken } from '../Utility/utils';
import { useNavigate } from 'react-router-dom';

const AddNewSchoolAdmin = ({ openCanvas, tittle, editItem, onlyClose, stateList }) => {
	const navigate = useNavigate();
	const [isRight, setIsRight] = useState(false);
	const [loading, setLoading] = useState(false);
	const countryData = ['México', 'Estados Unidos'];

	const formik = useFormik({
		initialValues: {
			id: '',
			schoolname: '',
			country: 'México',
			state: '',
			municipality: '',
			city: '',
			colony: '',
			street: '',
			outdoornumber: '',
			interiornumber: '',
			zipcode: '',
			phone: '',
			mail: '',
			website: '',
			status: 'Activo',
		},
		validationSchema: Yup.object({
			schoolname: Yup.string().required('Por favor, ingrese el nombre de la escuela'),
			country: Yup.string().required('Por favor, seleccione el país'),
			state: Yup.string().required('Por favor, seleccione el estado'),
			outdoornumber: Yup.string().required('Por favor, ingrese el número exterior'),
			zipcode: Yup.string()
				.required('Por favor, ingrese el código postal')
				.min(5, '5 caracteres')
				.max(5, '5 caracteres'),
			phone: Yup.string()
				.required('Por favor, ingrese el número de teléfono')
				.min(10, '10 caracteres')
				.max(10, '10 caracteres'),
		}),
		onSubmit: () => {
			setLoading(true);

				const formData = new FormData();
				formData.append('name', formik.values.schoolname);
				formData.append('country', formik.values.country);
				formData.append('state', formik.values.state);
				formData.append('municipality', formik.values.municipality);
				formData.append('city', formik.values.city);
				formData.append('colony', formik.values.colony);
				formData.append('street', formik.values.street);
				formData.append('outdoorNumber', formik.values.outdoornumber);
				formData.append('interiorNumber', formik.values.interiornumber);
				formData.append('zipCode', formik.values.zipcode);
				formData.append('phone', formik.values.phone);
				formData.append('email', formik.values.mail);
				formData.append('website', formik.values.website);

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}school`,
					data: formData,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
							localStorage.setItem('SCHOOL_ID', response.content._id);
							localStorage.setItem('SCHOOL_NAME', response.content.name);
							localStorage.setItem('USER_SCHOOL_TENANT', response.content.tenant);
							navigate('/adminEducativeOffer');
						} else {
							if (response.message === 'Información duplicada') {
								setLoading(false);
							} else {
								setLoading(false);
							}
						}
					})
					.catch((error) => {});
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas, editItem]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-school'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<Form
									className='form-horizontal'
									onSubmit={(e) => {
										e.preventDefault();
										formik.handleSubmit();
										return false;
									}}>
									<CardBody>
										<div className='row'>
											<div className='col-12 mt-0'>
												<label
													htmlFor='schoolname'
													className='col-form-label text-muted'>
													Nombre de escuela
												</label>
												<Input
													className='form-control'
													type='text'
													id='schoolname'
													name='schoolname'
													value={formik.values.schoolname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.schoolname &&
														formik.errors.schoolname
															? true
															: false
													}
												/>
												{formik.touched.schoolname &&
												formik.errors.schoolname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.schoolname}</div>
													</FormFeedback>
												) : null}
											</div>

											<div>
												<h4 className='mt-3'>Dirección</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='country'
													className='col-form-label text-muted'>
													País
												</label>
												<Input
													id='country'
													name='country'
													type='select'
													value={formik.values.country}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.country &&
														formik.errors.country
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{countryData.map((i) => (
														<option key={i} value={i}>
															{i}
														</option>
													))}
												</Input>
												{formik.touched.country && formik.errors.country ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.country}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='state'
													className='col-form-label text-muted'>
													Estado
												</label>
												<Input
													name='state'
													type='select'
													value={formik.values.state}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.state &&
														formik.errors.state
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{stateList.map((i) => (
														<option key={i.code} value={i.name}>
															{i.name}
														</option>
													))}
												</Input>
												{formik.touched.state && formik.errors.state ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.state}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='municipality'
													className='col-form-label text-muted'>
													Municipio/Delegación
												</label>
												<Input
													className='form-control'
													type='text'
													id='municipality'
													name='municipality'
													value={formik.values.municipality}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.municipality &&
														formik.errors.municipality
															? true
															: false
													}
												/>
												{formik.touched.municipality &&
												formik.errors.municipality ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.municipality}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='city'
													className='col-form-label text-muted'>
													Ciudad/Localidad
												</label>
												<Input
													className='form-control'
													type='text'
													id='city'
													name='city'
													value={formik.values.city}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.city && formik.errors.city
															? true
															: false
													}
												/>
												{formik.touched.city && formik.errors.city ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.city}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='colony'
													className='col-form-label text-muted'>
													Colonia
												</label>
												<Input
													className='form-control'
													type='text'
													id='colony'
													name='colony'
													value={formik.values.colony}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.colony &&
														formik.errors.colony
															? true
															: false
													}
												/>
												{formik.touched.colony && formik.errors.colony ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.colony}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='street'
													className='col-form-label text-muted'>
													Calle
												</label>
												<Input
													className='form-control'
													type='text'
													id='street'
													name='street'
													value={formik.values.street}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.street &&
														formik.errors.street
															? true
															: false
													}
												/>
												{formik.touched.street && formik.errors.street ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.street}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='zipcode'
													className='col-form-label text-muted'>
													C.P.
												</label>
												<Input
													className='form-control'
													type='number'
													id='zipcode'
													name='zipcode'
													value={formik.values.zipcode}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.zipcode &&
														formik.errors.zipcode
															? true
															: false
													}
												/>
												{formik.touched.zipcode && formik.errors.zipcode ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.zipcode}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='outdoornumber'
													className='col-form-label text-muted'>
													No. Exterior
												</label>
												<Input
													className='form-control'
													type='number'
													id='outdoornumber'
													name='outdoornumber'
													value={formik.values.outdoornumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.outdoornumber &&
														formik.errors.outdoornumber
															? true
															: false
													}
												/>
												{formik.touched.outdoornumber &&
												formik.errors.outdoornumber ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.outdoornumber}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='interiornumber'
													className='col-form-label text-muted'>
													No. Interior
												</label>
												<Input
													className='form-control'
													type='number'
													id='interiornumber'
													name='interiornumber'
													value={formik.values.interiornumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.interiornumber &&
														formik.errors.interiornumber
															? true
															: false
													}
												/>
												{formik.touched.interiornumber &&
												formik.errors.interiornumber ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.interiornumber}</div>
													</FormFeedback>
												) : null}
											</div>

											<div>
												<h4 className='mt-3'>Contacto</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>
												<Input
													className='form-control'
													type='number'
													id='phone'
													name='phone'
													value={formik.values.phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.phone && formik.errors.phone
															? true
															: false
													}
												/>
												{formik.touched.phone && formik.errors.phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='mail'
													className='col-form-label text-muted'>
													Correo
												</label>
												<Input
													className='form-control'
													type='text'
													id='mail'
													name='mail'
													value={formik.values.mail}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.mail && formik.errors.mail
															? true
															: false
													}
												/>
												{formik.touched.mail && formik.errors.mail ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.mail}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='website'
													className='col-form-label text-muted'>
													Página web
												</label>
												<Input
													className='form-control'
													type='text'
													id='website'
													name='website'
													value={formik.values.website}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.website &&
														formik.errors.website
															? true
															: false
													}
												/>
												{formik.touched.website && formik.errors.website ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.website}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													Guardar
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
							</Card>
						</div>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewSchoolAdmin.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewSchoolAdmin.defaultProps = {
	openCanvas: false,
};

export default AddNewSchoolAdmin;

import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Modal,
} from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import AddNewSubject from '../CanvasPages/AddNewSubject';
import AddNewGroupLevel from '../CanvasPages/AddNewGroupLevel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getHeaderSimpleToken } from '../Utility/utils';

const CommonSettingsEvents = () => {
	const [materiaDataList, setMateriaDataList] = useState([]);
	const [materiaDataFilterList, setmateriaDataFilterList] = useState([]);
	const [materiaCurrentPage, setMateriaCurrentPage] = useState(1);
	const [materiaPerPage, setMateriaPerPage] = useState(PER_COUNT['5']);
	const [materiaOpenCanvas, setMateriaOpenCanvas] = useState(false);
	const [materiaDeleteModal, setMateriaDeleteModal] = useState(false);
	const [materiaItemToEdit, setMateriaItemToEdit] = useState(null);
	const [materiaFormTitle, setMateriaFormTitle] = useState('');

	const [groupDataList, setGroupDataList] = useState([]);
	const [groupDataFilterList, setGroupDataFilterList] = useState([]);
	const [groupCurrentPage, setGroupCurrentPage] = useState(1);
	const [groupPerPage, setGroupPerPage] = useState(PER_COUNT['5']);
	const [groupOpenCanvas, setGroupOpenCanvas] = useState(false);
	const [groupDeleteModal, setGroupDeleteModal] = useState(false);
	const [groupItemToEdit, setGroupItemToEdit] = useState(null);
	const [groupFormTitle, setGroupFormTitle] = useState('');

	const refreshSubjectList = (newItem) => {
		var isEdit = false;
		var list = [];

		for (var i = 0; i < materiaDataList.length; i++) {
			var item = materiaDataList[i];
			
			if (item._id !== newItem._id) {
				list.push(item);
			} else {
				newItem.id = item.id;
				list.push(newItem);
				isEdit = true;
			}
		}

		if (!isEdit) {
			newItem.id = materiaDataList.length + 1;
			list.push(newItem);
		}

		toast.success('La operación se realizó con éxito', toastStyle);
		setMateriaDataList(list);
		setmateriaDataFilterList(list);
		// setMateriaOpenCanvas(false);
	};

	const refreshGroupList = (newItem) => {
		var isEdit = false;
		var list = [];

		for (var i = 0; i < groupDataList.length; i++) {
			var item = groupDataList[i];
			
			if (item._id !== newItem._id) {
				list.push(item);
			} else {
				newItem.id = item.id;
				list.push(newItem);
				isEdit = true;
			}
		}

		if (!isEdit) {
			newItem.id = groupDataList.length + 1;
			list.push(newItem);
		}

		toast.success('La operación se realizó con éxito', toastStyle);
		setGroupDataList(list);
		setGroupDataFilterList(list);
		// setGroupOpenCanvas(false);
	};

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const materiaOnlyClose = async () => {
		setMateriaOpenCanvas(false);
	};

	const groupOnlyClose = async () => {
		setGroupOpenCanvas(false);
	};

	async function getMateriaData() {
		fetch(`${process.env.REACT_APP_API_URL}subject`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.data.length !== 0) {
					setMateriaDataList(rawData.data);
					setmateriaDataFilterList(rawData.data);
				} else {
					setMateriaDataList([]);
					setmateriaDataFilterList([]);
				}
			});
	}

	async function getGroupsData() {
		fetch(`${process.env.REACT_APP_API_URL}grouplevel`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.data.length !== 0) {
					setGroupDataList(rawData.data);
					setGroupDataFilterList(rawData.data);
				} else {
					setGroupDataList([]);
					setGroupDataFilterList([]);
				}
			});
	}

	const deleteMateria = async (id) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}subject/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const status = await response.json();
		if (status.message === 'Ok') {
			var list = [];
			for (var i = 0; i < materiaDataList.length; i++) {
				var item = materiaDataList[i];
				if (item._id !== id) {
					list.push(item);
				}
			}

			setMateriaDataList(list);
			setmateriaDataFilterList(list);
			setMateriaItemToEdit(null);
			toast.success('La operación se realizó con éxito', toastStyle);
		} else {
			toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
		}
	};

	const deleteGroup = async (id) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}grouplevel/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const status = await response.json();
		if (status.message === 'Ok') {
			var list = [];
			for (var i = 0; i < groupDataList.length; i++) {
				var item = groupDataList[i];
				if (item._id !== id) {
					list.push(item);
				}
			}

			setGroupDataList(list);
			setGroupDataFilterList(list);
			setGroupItemToEdit(null);
			toast.success('La operación se realizó con éxito', toastStyle);
		} else {
			toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
		}
	};

	useEffect(() => {
		getMateriaData();
		getGroupsData();
	}, []);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = Object.keys(materiaDataList)
				.filter(
					(key) =>
					materiaDataList[key].name
							.toString()
							.toLowerCase()
							.replace('á', 'a')
							.replace('í', 'i')
							.replace('ú', 'u')
							.replace('é', 'e')
							.replace('ó', 'o')
							.includes(search) ||
							materiaDataList[key].lastname
							.toString()
							.toLowerCase()
							.replace('á', 'a')
							.replace('í', 'i')
							.replace('ú', 'u')
							.replace('é', 'e')
							.replace('ó', 'o')
							.includes(search),
				)
				.map((i) => materiaDataList[i]);
				setmateriaDataFilterList(filterResult);
		} else {
			setmateriaDataFilterList(materiaDataList);
		}
	}, 700);

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Configuración</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											delayedHandleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>

					<Row>
						<Col lg={6}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6 h4 text-primary'>
											<i className='fas fa-book' />
											<span className='span-tittle-text-table'>
												Materias
											</span>
										</div>
										<div className='col-6 container d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												onClick={() => {
													setMateriaItemToEdit(null);
													setMateriaFormTitle('Agregar materia');
													setMateriaOpenCanvas(!materiaOpenCanvas);
												}}>
												<i className='fas fa-plus me-1'></i>
												Agregar registro
											</button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th className='sort' data-sort='customer_name'>
														No.
													</th>
													<th className='sort' data-sort='email'>
														Nombre
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(
													materiaDataFilterList,
													materiaCurrentPage,
													materiaPerPage,
												).map((item) => (
													<tr key={item.id}>
														<td>
															<div>{item.id}</div>
														</td>
														<td>
															<div>{item.name}</div>
														</td>
														<td className='text-center'>
															<button
																type='button'
																className='btn btn-success waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setMateriaFormTitle('Editar materia');
																	setMateriaItemToEdit(item);
																	setMateriaOpenCanvas(!materiaOpenCanvas);
																}}>
																<i className=' fas fa-pencil-alt me-1' />
																Editar
															</button>

															<button
																type='button'
																className='btn btn-outline-danger waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setMateriaItemToEdit(item);
																	setMateriaDeleteModal(true);
																}}>
																<i className='fas fa-trash-alt me-1' />
																Borrar
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>

										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={materiaDataFilterList}
									label='registros'
									setCurrentPage={setMateriaCurrentPage}
									currentPage={materiaCurrentPage}
									perPage={materiaPerPage}
									setPerPage={setMateriaPerPage}
								/>
								<ToastContainer />
							</Card>
						</Col>

						<Col lg={6}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6 h4 text-primary'>
											<i className='fas fa-kaaba' />
											<span className='span-tittle-text-table'>
												Grupos
											</span>
										</div>
										<div className='col-6 container d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												onClick={() => {
													setGroupItemToEdit(null);
													setGroupFormTitle('Agregar grupo');
													setGroupOpenCanvas(!materiaOpenCanvas);
												}}>
												<i className='fas fa-plus me-1'></i>
												Agregar registro
											</button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th className='sort' data-sort='customer_name'>
														No.
													</th>
													<th className='sort' data-sort='email'>
														Nombre
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(
													groupDataFilterList,
													groupCurrentPage,
													groupPerPage,
												).map((item) => (
													<tr key={item.id}>
														<td>
															<div>{item.id}</div>
														</td>
														<td>
															<div>{item.name}</div>
														</td>
														<td className='text-center'>
															<button
																type='button'
																className='btn btn-success waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setGroupFormTitle('Editar grupo');
																	setGroupItemToEdit(item);
																	setGroupOpenCanvas(!groupOpenCanvas);
																}}>
																<i className=' fas fa-pencil-alt me-1' />
																Editar
															</button>

															<button
																type='button'
																className='btn btn-outline-danger waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setGroupItemToEdit(item);
																	setGroupDeleteModal(true);
																}}>
																<i className='fas fa-trash-alt me-1' />
																Borrar
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>

										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={groupDataFilterList}
									label='registros'
									setCurrentPage={setGroupCurrentPage}
									currentPage={groupCurrentPage}
									perPage={groupPerPage}
									setPerPage={setGroupPerPage}
								/>
								<ToastContainer />
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<AddNewSubject
				onlyClose={materiaOnlyClose}
				openCanvas={materiaOpenCanvas}
				editItem={materiaItemToEdit}
				tittle={materiaFormTitle}
				refreshList={refreshSubjectList}
			/>

			<AddNewGroupLevel
				onlyClose={groupOnlyClose}
				openCanvas={groupOpenCanvas}
				editItem={groupItemToEdit}
				tittle={groupFormTitle}
				refreshList={refreshGroupList}
			/>

			<Modal
				isOpen={materiaDeleteModal}
				toggle={() => {
					setMateriaDeleteModal(false);
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>Eliminar registro</h5>
					<button
						type='button'
						className='btn-close'
						onClick={() => {
							setMateriaDeleteModal(false);
						}}
					/>
				</div>
				<div className='modal-body'>
					<div className='d-flex justify-content-center align-items-center'>
						<h6>¿Estás seguro de querer eliminar el registro?</h6>
					</div>
				</div>
				<div className='modal-footer justify-content-center'>
					<button
						type='button'
						className='btn btn-outline-info waves-effect waves-ligh modal-buttons'
						data-bs-dismiss='modal'
						onClick={() => {
							setMateriaDeleteModal(false);
						}}>
						No
					</button>
					<button
						type='button'
						className='btn btn-outline-danger waves-effect waves-light me-2 modal-buttons'
						onClick={() => {
							setMateriaDeleteModal(false);
							deleteMateria(materiaItemToEdit._id);
						}}>
						Si
					</button>
				</div>
			</Modal>

			<Modal
				isOpen={groupDeleteModal}
				toggle={() => {
					setMateriaDeleteModal(false);
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>Eliminar registro</h5>
					<button
						type='button'
						className='btn-close'
						onClick={() => {
							setGroupDeleteModal(false);
						}}
					/>
				</div>
				<div className='modal-body'>
					<div className='d-flex justify-content-center align-items-center'>
						<h6>¿Estás seguro de querer eliminar el registro?</h6>
					</div>
				</div>
				<div className='modal-footer justify-content-center'>
					<button
						type='button'
						className='btn btn-outline-info waves-effect waves-ligh modal-buttons'
						data-bs-dismiss='modal'
						onClick={() => {
							setGroupDeleteModal(false);
						}}>
						No
					</button>
					<button
						type='button'
						className='btn btn-outline-danger waves-effect waves-light me-2 modal-buttons'
						onClick={() => {
							setGroupDeleteModal(false);
							deleteGroup(groupItemToEdit._id);
						}}>
						Si
					</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default CommonSettingsEvents;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { getHeaderSimpleToken } from '../Utility/utils';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { changeLayout } from '../../store/actions';

//constants
import { layoutTypes } from '../../constants/layout';

const EducativeOfferList = (props) => {
	const navigate = useNavigate();
	const [dataList, setDataList] = useState([]);

	async function getEducativeOfferData() {
		fetch(`${process.env.REACT_APP_API_URL}educationoffer/educationoffers`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setDataList(rawData.data);
				} else {
					setDataList([]);
				}
			});
	}

	useEffect(() => {
		getEducativeOfferData();
	}, []);

	return (
		<React.Fragment>
			<Row>
				<Col xl={3} sm={6}>
					<Card style={{ minHeight: '15vh' }}>
						<button
							type='button'
							className='btn waves-effect waves-light'
							style={{ height: '15vh', backgroundColor: '#1B2C3F' }}
							onClick={() => {
								props.addNewEducativeOffer();
							}}>
							<i className='fas fa-plus fa-3x' style={{ color: 'white' }} />
						</button>
					</Card>
				</Col>

				{dataList.map((educativeOfferItem) => (
					<Col xl={3} sm={6}>
						<Card style={{ minHeight: '15vh' }}>
							<button
								type='button'
								className='btn waves-effect waves-light'
								style={{
									height: '15vh',
									backgroundColor:
										educativeOfferItem.status === 'Suspendido'
											? '#ffff004D'
											: educativeOfferItem.status === 'Cancelado'
											? '#ff00004D'
											: '#0AC0744D',
								}}
								onClick={() => {
									localStorage.setItem(
										'EDUCATIVE_OFFER_ID',
										educativeOfferItem._id,
									);
									localStorage.setItem(
										'EDUCATIVE_OFFER_NAME',
										educativeOfferItem.name,
									);
									localStorage.setItem(
										'EDUCATIVE_OFFER_TYPE',
										educativeOfferItem.educationOfferType,
									);
									localStorage.setItem('USER_TENANT', educativeOfferItem.tenant);
									props.changeLayout(layoutTypes.VERTICAL);
									navigate('/dashboard');
								}}>
								<div
									style={{
										color: '#2C2C2C',
										fontSize: '18px',
										fontWeight: 'bold',
									}}>
									{educativeOfferItem.name}
								</div>
								<div
									className='span-tittle-text'
									style={{
										fontSize: '16px',
									}}>
									{educativeOfferItem.educationOfferType}
								</div>
							</button>
						</Card>
					</Col>
				))}
			</Row>
		</React.Fragment>
	);
};

EducativeOfferList.propTypes = {
	changeLayout: PropTypes.func,
};

const mapStateToProps = (state) => {
	return { ...state.Layout };
};

// export default withRouter(EducativeOfferList);
export default connect(mapStateToProps, {
	changeLayout,
})(EducativeOfferList);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Col, CardBody, Card } from 'reactstrap';

import withRouter from '../../components/Common/withRouter';

// imgs
import kualiLogo from '../../assets/images/kuali-logo-negro.png';

// components
import LoginForm from './forms/LoginForm';
import SignUpForm from './forms/SignUpForm';

const Login = () => {
	const [isNewUser, setIsNewUser] = useState(false);

	useEffect(() => {
		document.body.className = 'login-bg';
		// remove classname when component will unmount
		return function cleanup() {
			document.body.className = '';
		};
	});

	function onResult() {
		setIsNewUser(false);
	}

	return (
		<div id='div-style-1'>
			<Col sm={12} md={12} lg={12} xl={6} xxl={6} id='login-message'>
				<text id='p-style-1'>Gobierno del Estado de México</text>
				<div id='div-style-3'>
					<text id='p-style-2'>Nutriendo el futuro de nuestros niños</text>
				</div>
				<text id='p-style-1'>
					Programa de cuidado alimenticio para el apoyo en el combate de los problemas por
					mala alimentación en los niños de México
				</text>
			</Col>
			<Col sm={12} md={12} lg={12} xl={6} xxl={6} id='login-form'>
				<Card className='col-sm-12 col-md-8 col-lg-7 col-xl-10 col-xxl-8' id='card-style-2'>
					<CardBody className='p-4'>
						<div>
							<div className='d-flex justify-content-center' id='img-style-1'>
								<img src={kualiLogo} alt='' />
							</div>

							<div
								className='col-xl-8 col-lg-6 col-md-8 shadow-3d-container'
								id='div-style-4'>
								<div className='row row-cols-2 g-2 pb-2 px-2 mt-0' id='div-style-5'>
									<div className='col-6'>
										<button
											className='btn waves-effect waves-light w-100'
											id={isNewUser ? 'button-style-2' : 'button-style-1'}
											type='submit'
											onClick={() => {
												setIsNewUser(false);
											}}>
											Inicio de sesión
										</button>
									</div>
									<div className='col-6'>
										<button
											className='btn waves-effect waves-light w-100'
											id={isNewUser ? 'button-style-1' : 'button-style-2'}
											type='submit'
											onClick={() => {
												setIsNewUser(true);
											}}>
											Nuevo registro
										</button>
									</div>
								</div>
							</div>

							{isNewUser ? <SignUpForm onResult={onResult} /> : <LoginForm />}
						</div>
					</CardBody>
				</Card>
			</Col>
		</div>
	);
};

export default withRouter(Login);

Login.propTypes = {
	history: PropTypes.object,
};

import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';

import { NotificationsData } from '../../CommonData/Data/index';

const Notifications = () => {
	return (
		<React.Fragment>
			<Col lg={4} style={{ marginBottom: '24px' }}>
				<Card style={{ height: '100%' }}>
					<CardBody>
						<div style={{ fontWeight: 'bold', fontSize: '24px', color: '#000' }}>
							Profesores
						</div>
						<div style={{ fontSize: '16px', color: '#696D81' }}>
							Mayor número de reprobados
						</div>
						<div>
							<ul className='list-unstyled'>
								{NotificationsData.map((item, key) => (
									<li key={key} className='py-3'>
										<div className='d-flex'>
											<div className='flex-shrink-0 me-3 align-self-center'>
												<img
													className='rounded-circle avatar-xs'
													alt=''
													src={item.src}
												/>
											</div>

											<div className='flex-grow-1 overflow-hidden'>
												<div style={{ color: '#696D81', fontSize: '18px' }}>
													{item.name}
												</div>
												<div style={{ color: '#696D81', fontSize: '16px' }}>
													{item.desc}
												</div>
											</div>
											<div
												style={{
													color: '#000',
													fontSize: '18px',
													fontWeight: 'bold',
												}}>
												{item.amount + ' alumnos'}
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>

						<div style={{ textAlign: 'center' }}>
							<button
								type='button'
								className='btn'
								style={{
									backgroundColor: '#00A88D',
									color: '#fff',
									fontSize: '22px',
									fontWeight: 'bold',
									paddingLeft: '48px',
									paddingTop: '2px',
									paddingRight: '48px',
									paddingBottom: '2px',
								}}>
								Ver detalle
							</button>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};
export default Notifications;

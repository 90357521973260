import React, { useEffect, useState } from 'react';
import { Card, Col, Collapse, Row } from 'reactstrap';
import { getHeaderSimpleToken } from '../Utility/utils';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames';

const SchoolList = ({ addNewSchool }) => {
	const navigate = useNavigate();
	const [dataList, setDataList] = useState([]);
	const [updateKey, setUpdateKey] = useState(0);

	async function getSchoolData() {
		fetch(`${process.env.REACT_APP_API_URL}school/all`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					for (var q = 0; q < rawData.data.length; ++q) {
						var itemQ = rawData.data[q];

						if (q === 0) {
							itemQ.isOpen = true;
						} else {
							itemQ.isOpen = false;
						}

						rawData.data[q] = itemQ;
					}

					setDataList(rawData.data);
				} else {
					setDataList([]);
				}
			});
	}

	useEffect(() => {
		getSchoolData();
	}, []);

	const onClickAccordion = (state) => {
		for (var q = 0; q < dataList.length; ++q) {
			var itemQ = dataList[q];

			if (itemQ.state === state) {
				itemQ.isOpen = !itemQ.isOpen;
			} else {
				itemQ.isOpen = false;
			}

			dataList[q] = itemQ;
			setUpdateKey(updateKey + 1);
		}

		setDataList(dataList);
	};

	return (
		<React.Fragment>
			<div className='accordion'>
				{dataList.map((item) => (
					<Card>
						<div className='accordion-item' style={{ border: 'none' }}>
							<h2 className='accordion-header'>
								<button
									className={classnames('accordion-button', 'fw-medium', {
										collapsed: !item.isOpen,
									})}
									type='button'
									onClick={() => {
										onClickAccordion(item.state);
									}}
									style={{
										cursor: 'pointer',
										fontSize: '18px',
										color: 'black',
										fontWeight: '600',
										backgroundColor: 'white',
									}}>
									{item.state}
								</button>
							</h2>

							<Collapse isOpen={item.isOpen} className='accordion-collapse'>
								<Row
									style={{
										marginLeft: '12px',
										marginTop: '22px',
										marginRight: '12px',
									}}>
									<Col xl={3} sm={6}>
										<Card style={{ minHeight: '15vh' }}>
											<button
												type='button'
												className='btn waves-effect waves-light'
												style={{
													height: '15vh',
													backgroundColor: '#1B2C3F',
												}}
												onClick={() => {
													addNewSchool();
												}}>
												<i
													className='fas fa-plus fa-3x'
													style={{ color: 'white' }}
												/>
											</button>
										</Card>
									</Col>

									{item.schools.map((school) => (
										<Col xl={3} sm={6}>
											<Card style={{ minHeight: '15vh' }}>
												<button
													type='button'
													className='btn waves-effect waves-light'
													style={{
														height: '15vh',
														backgroundColor:
															school.status === 'Suspendido'
																? '#ffff004D'
																: school.status === 'Cancelado'
																? '#ff00004D'
																: '#0AC0744D',
													}}
													onClick={() => {
														localStorage.setItem(
															'SCHOOL_ID',
															school._id,
														);
														localStorage.setItem(
															'SCHOOL_NAME',
															school.name,
														);
														localStorage.setItem(
															'USER_SCHOOL_TENANT',
															school.tenant,
														);
														navigate('/adminEducativeOffer');
													}}>
													<div
														style={{
															color: '#2C2C2C',
															fontSize: '24px',
															fontWeight: 'bold',
														}}>
														{school.name}
													</div>
												</button>
											</Card>
										</Col>
									))}
								</Row>
							</Collapse>
						</div>
					</Card>
				))}
			</div>
		</React.Fragment>
	);
};

export default SchoolList;

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const dountchartData = {
	series: [22, 41, 18, 19],
	options: {
		labels: ['Bebidas', 'Comida', 'Botanas', 'Dulces'],
		colors: ['#01A685', '#9599B3', '#192737', '#4F8DCB'],
		dataLabels: {
			enabled: true,
			offsetX: 4,
			offsetY: 0,
			style: {
				color: '#01A685',
			},
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
			},
		},
	},
};

const DonutSaleDos = () => {
	return (
		<React.Fragment>
			<ReactApexChart
				options={dountchartData.options}
				series={dountchartData.series}
				type='donut'
				height='320'
				className='apex-charts'
			/>
		</React.Fragment>
	);
};

export default DonutSaleDos;

import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

import { OrderStatusData } from '../../CommonData/Data/index';

const OrderStatus = () => {
	return (
		<React.Fragment>
			<Col xl={4} style={{ marginBottom: '24px' }}>
				<Card style={{ height: '100%' }}>
					<CardBody>
						<div style={{ fontWeight: 'bold', fontSize: '24px', color: '#000' }}>
							Grados
						</div>
						<div style={{ fontSize: '16px', color: '#696D81' }}>
							Promedios más bajos
						</div>
						<div>
							<ul className='list-unstyled'>
								{OrderStatusData.map((item, key) => (
									<li key={key} className='py-3'>
										<div className='d-flex'>
											<div className='avatar-xs align-self-center me-3'>
												<div
													className='avatar-title rounded-circle bg-light font-size-18'
													style={{ fontWeight: 'bold', color: '#000' }}>
													{item.id}
												</div>
											</div>

											<div className='flex-grow-1'>
												<div className='text-muted'>{item.title}</div>
												<div
													className='progress progress-sm animated-progess'
													style={{ height: '15px', borderRadius: '6px' }}>
													<div
														className={'progress-bar'}
														role='progressbar'
														style={{
															width: Number(item.value) * 10 + '%',
															backgroundColor: item.color,
															borderRadius: '6px',
														}}
														aria-valuemin='0'
														aria-valuemax='100'></div>
												</div>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>

						<div style={{ textAlign: 'center', marginTop: "90px" }}>
							<button
								type='button'
								className='btn'
								style={{
									backgroundColor: '#00A88D',
									color: '#fff',
									fontSize: '22px',
									fontWeight: 'bold',
									paddingLeft: '48px',
									paddingTop: '2px',
									paddingRight: '48px',
									paddingBottom: '2px',
								}}>
								Ver detalle
							</button>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default OrderStatus;

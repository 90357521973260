
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Container,
    Card,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    CardBody,
    Input,
    Spinner,
    Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { getHeaderSimpleToken } from '../Utility/utils';
import { toInteger } from 'lodash';
import { generateKardexPdf } from '../../Utils/ExportFiles/kardex';
import { toString } from 'lodash';


const Kardex = ({ openCanvas, title, onlyClose, studentId, studentName }) => {

    const [isRight, setIsRight] = useState(false);
    const [grades, setGrades] = useState([]);
    const [countPartials, setCountPartials] = useState(0);
    const [schoolYears, setSchoolYears] = useState([]);
    const [selectedSchoolyear, setSelectedSchoolyear] = useState("");
    const [loading, setLoading] = useState(false);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const fetchKardex = async () => {

        setLoading(true);

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/grades/getKardex?studentId=${studentId}&schoolYear=${selectedSchoolyear}`, {
            headers: getHeaderSimpleToken(),
        });
        const { data } = await response.json();
        data.forEach((dataValue) => {
            dataValue.grade = dataValue.grade[0].split(",")
        });
        setGrades(data);
        if (data.length > 0) setCountPartials(data[0].grade.length);
        setLoading(false);
    };

    const getSchoolYearsByStudentId = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/grades/getAllSchoolYearsByStudentId?studentId=${studentId}`, {
            headers: getHeaderSimpleToken(),
        });
        const { data } = await response.json();
        setSchoolYears(data);
    };

    const handleSchoolYearChange = (e) => {
        setSelectedSchoolyear(e.target.value);
    }

    const search = () => {
        fetchKardex();
    }

    useEffect(() => {
        getSchoolYearsByStudentId();
    }, []);

    const exportTableToPdf = () => {
        const newGrades = grades.map((grade) => {
            return {
                _id: grade._id,
                grades: grade.grade,
                subject: grade.subjectData.name,
            }
        });
        let finalAverageCourse = getFinalAverage();
        generateKardexPdf(newGrades, studentName, selectedSchoolyear, "1", "A", localStorage.getItem('SCHOOL_NAME'),finalAverageCourse);
    }

    const getFinalAverage = () => {
        let finalAverage = 0;

        for (let i = 0; i < grades.length; i++) {
            let sum = 0;
            let countGradesAssigned = 0;
            for (let j = 0; j < grades[i].grade.length; j++) {
                if (grades[i].grade[j] === "") continue
                
                sum += parseFloat(grades[i].grade[j]);
                countGradesAssigned++;
            }
            finalAverage += sum / countGradesAssigned;
        }

        return finalAverage / grades.length;

    }
                   


useEffect(() => {
    setIsRight(openCanvas);

}, [openCanvas]);

return (
    <Offcanvas
        isOpen={isRight}
        direction='end'
        scrollable
        onClosed={() => {
            onlyClose();
        }}
        id='off-canvas-teacher'
        toggle={toggleRightCanvas}
        style={{ width: '45%' }}
    >

        <OffcanvasHeader
            id='off-canvas-header'
            onClosed={() => {
                onlyClose();
            }}
            toggle={toggleRightCanvas}>
            <span className='h3'>{studentName}</span>
        </OffcanvasHeader>
        <OffcanvasBody style={{ height: '100%' }}>
            <Container fluid style={{ height: '100%' }}>
                <div className='row' style={{ height: '100%' }}>
                    <div className='col' style={{ height: '100%' }}>
                        <Card id='card-into-off-canvas' style={{ height: '100%' }}>
                            <CardBody style={{ height: '100%' }}>

                                <div className='table-responsive table-card mt-3 mb-1' style={{ height: '100%' }}>
                                    <table
                                        className='table align-middle table-nowrap'
                                        id='customerTable'>
                                        <thead>
                                            {/* right */}
                                            <tr className='table-light'>

                                                <th className='sort ' data-sort='customer_name'>
                                                    <label className='col-form-label text-muted'>
                                                        Grado
                                                    </label>
                                                    <Input
                                                        className='form-control'
                                                        type='text'
                                                        id='level'
                                                        name='level'
                                                        value={grades.length === 0 ? ("NA") : (grades[0].level)}
                                                        disabled={true}
                                                    />

                                                </th>
                                                <th className='sort' data-sort='customer_name'>
                                                    <label className='col-form-label text-muted'>
                                                        Grupo
                                                    </label>
                                                    <Input
                                                        className='form-control'
                                                        type='text'
                                                        id='group'
                                                        name='group'
                                                        value={grades.length === 0 ? ("NA") : (grades[0].group)}
                                                        disabled={true}
                                                    />
                                                </th>
                                                <th
                                                    className='sort'
                                                    data-sort='customer_name'>

                                                    <label className='col-form-label text-muted'>
                                                        Ciclo escolar
                                                    </label>
                                                    <Input type='select' className='form-select' defaultValue="" value={selectedSchoolyear} onChange={handleSchoolYearChange}>
                                                        <option value={""} disabled={true} >Selecciona</option>
                                                        {schoolYears.map((schoolYear, index) => {
                                                            return (
                                                                <option key={index} value={schoolYear}>{schoolYear}</option>
                                                            );
                                                        }
                                                        )}
                                                    </Input>
                                                </th>
                                                <th
                                                    className='sort'
                                                    data-sort='customer_name'>
                                                    <button type='button'
                                                        className='btn btn-success waves-effect waves-light me-2'
                                                        id='create-btn'
                                                        onClick={search}
                                                        disabled={selectedSchoolyear === ""}
                                                        style={{ minWidth: '100px' }}
                                                    >
                                                        {loading ? <Spinner id='spinner-style-2' size='sm' /> : (
                                                            <>
                                                                Buscar
                                                            </>
                                                        )}

                                                    </button>
                                                </th>
                                                <th
                                                    className='sort'
                                                    data-sort='customer_name'>
                                                    <button type='button'
                                                        className='btn btn-success waves-effect waves-light me-2'
                                                        id='create-btn'
                                                        onClick={exportTableToPdf}
                                                        disabled={grades.length === 0}
                                                        style={{ minWidth: '100px' }}
                                                    >
                                                        Exportar
                                                    </button>
                                                </th>

                                            </tr>


                                        </thead>
                                        <div />
                                    </table>
                                    <>
                                        <table className='table align-middle table-nowrap'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th className='sort text-center' data-sort='customer_name'>Materia</th>
                                                    {grades.length === 0 ? (
                                                        <th className='sort text-center' data-sort='customer_name'>Parciales</th>
                                                    ) : (
                                                        <>
                                                            {grades[0].grade.map((grade, index) => (
                                                                <th key={index} className='sort text-center' data-sort='customer_name'>{`Parcial ${index + 1}`}</th>
                                                            ))}
                                                            <th className='sort text-center' data-sort='customer_name'>Promedio final</th>
                                                        </>
                                                    )}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {grades.map((grade, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='sort text-center'>{grade.subjectData.name}</td>
                                                            {grade.grade.map((grade, index) => {
                                                                return (
                                                                    <td key={index} className='sort text-center'>{grade}</td>
                                                                );
                                                            })}
                                                            <td className='sort text-center'>{(grade.grade.reduce((a, b) => {
                                                                if (b === "") return parseFloat(a)
                                                                return parseFloat(a) + parseFloat(b)
                                                                }, 0) / grade.grade.filter(grade => grade !== "").length).toFixed(2)}</td>
                                                        </tr>
                                                    );
                                                })}
                                                
                                                <tr>
                                                    {grades[0]?.grade?.length > 0 && (
                                                        Array.from({ length: countPartials }, (_, index) => {
                                                            return (
                                                                <td key={index} className='sort text-center'></td>
                                                            );
                                                        })

                                                    )}
                                                    {grades[0]?.grade?.length > 0 && (
                                                        <>
                                                            <td className='sort text-center'>Promedio final</td>
                                                            <td className='sort text-center'>{getFinalAverage().toFixed(2)}</td>
                                                        </>
                                                    )}

                                                </tr>


                                            </tbody>
                                        </table>
                                    </>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Container>

        </OffcanvasBody>
    </Offcanvas >
);
};

Kardex.propTypes = {
    openCanvas: PropTypes.bool,
};
Kardex.defaultProps = {
    openCanvas: false,
};

export default Kardex;

import React from "react";
import UsePanel from "./UserPanel";
import OrderStatus from "./OrderStatus";
import Notifications from "./Notifications";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import CategorySales from "./CategorySales";

import { Row, Container } from "reactstrap";
import MapChart from "./MapChart";

const Dashboard = () => {
  document.title = "Kuali";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* User Panel Charts */}
          <UsePanel />

          <Row>
            {/* Overview Chart */}
            <OverView />
            {/* Social Source Chart */}
            <SocialSource />
          </Row>

          <Row>
            {/* Order Stats */}
            <OrderStatus />
            {/* Notifications */}
            <Notifications />
            {/* Revenue by Location Vector Map */}
            <CategorySales />
          </Row>

          {/* Latest Transaction Table */}
          <MapChart />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Modal,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import AddNewPersonnel from '../CanvasPages/AddNewPersonnel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generatePersonnelPdf, generatePersonnelXls } from '../../Utils/ExportFiles/Personnel';
import { getHeaderSimpleToken } from '../Utility/utils';

const CommonPersonnelEvents = () => {
	const [dataList, setDataList] = useState([]);
	const [dataFilterList, setDataFilterList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const [openCanvas, setOpenCanvas] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [formTitle, setFormTitle] = useState('');
	const [openExportOptions, setOpenExportOptions] = useState(false);

	const refreshPage = async () => {
		setOpenCanvas(false);
		getPersonnelData();
	};

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const onlyClose = async () => {
		setOpenCanvas(false);
	};

	async function getPersonnelData() {
		fetch(`${process.env.REACT_APP_API_URL}personnel`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.data.length !== 0) {
					const listFromBack = rawData.data;
					const newListFromBack = [];
					for (let i = 0; i < listFromBack.length; i += 1) {
						const item = listFromBack[i];
						newListFromBack.push({
							id: i + 1,
							_id: item._id,
							name: item.name,
							lastname: item.lastname,
							phone: item.phone,
							email: item.email,
							position: item.position,
							degree: item.degree,
							startdate: item.startdate,
							typejob: item.typejob,
							status: item.status,
						});
					}

					setDataList(newListFromBack);
					setDataFilterList(newListFromBack);
				} else {
					setDataList([]);
					setDataFilterList([]);
				}
			});
	}

	const deleteData = async (id) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}personnel/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const status = await response.json();
		if (status.message === 'Ok') {
			toast.success('La operación se realizó con éxito', toastStyle);
		} else {
			toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
		}
		getPersonnelData();
	};

	useEffect(() => {
		getPersonnelData();
	}, []);

	const exportTableToExcel = () => {
		generatePersonnelXls(dataList);
	};

	const exportTableToPdf = () => {
		generatePersonnelPdf(dataList);
	};

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = Object.keys(dataList)
				.filter(
					(key) =>
						dataList[key].name
							.toString()
							.toLowerCase()
							.replace('á', 'a')
							.replace('í', 'i')
							.replace('ú', 'u')
							.replace('é', 'e')
							.replace('ó', 'o')
							.includes(search) ||
						dataList[key].lastname
							.toString()
							.toLowerCase()
							.replace('á', 'a')
							.replace('í', 'i')
							.replace('ú', 'u')
							.replace('é', 'e')
							.replace('ó', 'o')
							.includes(search),
				)
				.map((i) => dataList[i]);
			setDataFilterList(filterResult);
		} else {
			setDataFilterList(dataList);
		}
	}, 700);

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Personal</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											delayedHandleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6 h4 text-primary'>
											<i className='fas fa-user-alt' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
										<div className='col-6 container d-flex justify-content-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												id='button-style-3'
												onClick={() => {
													setItemToEdit(null);
													setFormTitle('Agregar registro');
													setOpenCanvas(!openCanvas);
												}}>
												<i className='fas fa-plus me-1'></i>
												Agregar registro
											</button>
											<Dropdown
												isOpen={openExportOptions}
												toggle={() =>
													setOpenExportOptions(!openExportOptions)
												}>
												<DropdownToggle caret color='info' outline>
													<i className='fas fa-cloud-download-alt me-1' />
													Exportar
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														onClick={() => exportTableToExcel()}>
														Excel
													</DropdownItem>
													<DropdownItem
														onClick={() => exportTableToPdf()}>
														PDF
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													<th className='sort' data-sort='customer_name'>
														No.
													</th>
													<th className='sort' data-sort='email'>
														Nombre
													</th>
													<th className='sort' data-sort='phone'>
														Cargo
													</th>
													<th className='sort' data-sort='date'>
														Teléfono
													</th>
													<th className='sort' data-sort='status'>
														Correo
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Estatus
													</th>
													<th
														className='sort text-center'
														data-sort='action'>
														Acciones
													</th>
												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{dataPagination(
													dataFilterList,
													currentPage,
													perPage,
												).map((item) => (
													<tr key={item.id}>
														<td>
															<div>{item.id}</div>
														</td>
														<td>
															<div>{`${item.name} ${item.lastname}`}</div>
														</td>
														<td>
															<div>{item.position}</div>
														</td>
														<td>
															<div>{item.phone}</div>
														</td>
														<td>
															<div>{item.email}</div>
														</td>
														<td className='text-center'>
															<div>
																<span
																	id='span-badge'
																	className={
																		(item.status === 'Activo' &&
																			'badge badge-soft-success') ||
																		(item.status ===
																			'Baja temporal' &&
																			'badge badge-soft-warning') ||
																		(item.status === 'Baja' &&
																			'badge badge-soft-danger')
																	}>
																	{item.status}
																</span>
															</div>
														</td>
														<td className='text-center'>
															<button
																type='button'
																className='btn btn-success waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setFormTitle('Editar registro');
																	setItemToEdit(item);
																	setOpenCanvas(!openCanvas);
																}}>
																<i className=' fas fa-pencil-alt me-1' />
																Editar
															</button>

															<button
																type='button'
																className='btn btn-outline-danger waves-effect waves-light me-2'
																id='create-btn'
																onClick={() => {
																	setItemToEdit(item);
																	setDeleteModal(true);
																}}>
																<i className='fas fa-trash-alt me-1' />
																Borrar
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>

										<div className='noresult d-none'>
											<div className='text-center'>
												<h5 className='mt-2'>
													No se encontraron resultados
												</h5>
											</div>
										</div>
									</div>
								</CardBody>
								<PaginationButtons
									data={dataFilterList}
									label='registros'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
								<ToastContainer />
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<AddNewPersonnel
				onlyClose={onlyClose}
				openCanvas={openCanvas}
				cleanForm={true}
				editItem={itemToEdit}
				mainTitle={formTitle}
				refreshPage={refreshPage}
			/>

			<Modal
				isOpen={deleteModal}
				toggle={() => {
					setDeleteModal(false);
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>Eliminar registro</h5>
					<button
						type='button'
						className='btn-close'
						onClick={() => {
							setDeleteModal(false);
						}}
					/>
				</div>
				<div className='modal-body'>
					<div className='d-flex justify-content-center align-items-center'>
						<h6>¿Estás seguro de querer eliminar el registro?</h6>
					</div>
				</div>
				<div className='modal-footer justify-content-center'>
					<button
						type='button'
						className='btn btn-outline-info waves-effect waves-ligh modal-buttons'
						data-bs-dismiss='modal'
						onClick={() => {
							setDeleteModal(false);
						}}>
						No
					</button>
					<button
						type='button'
						className='btn btn-outline-danger waves-effect waves-light me-2 modal-buttons'
						onClick={() => {
							setDeleteModal(false);
							deleteData(itemToEdit._id);
						}}>
						Si
					</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default CommonPersonnelEvents;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Row, Col, Alert, Form, Input, FormFeedback, Spinner } from 'reactstrap';

//redux
import { useSelector } from 'react-redux';

import withRouter from '../../../components/Common/withRouter';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { getHeaderSimple } from '../../Utility/utils';

const SignUpForm = ({onResult}) => {
	document.title = 'Kuali | Registro';

	const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			names: '',
			lastName: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object().shape({
			names: Yup.string().required('Por favor, ingresa tu nombre'),
			lastName: Yup.string().required('Por favor, ingresa tu apellido'),
			email: Yup.string()
				.email('Formato incorrecto')
				.required('Por favor, ingresa  tu email'),
			password: Yup.string()
				.required('Por favor, ingresa tu contraseña')
				.min(8, 'Mínimo 8 caracteres')
				.matches(
					passwordRules,
					'La contraseña debe contener 1 mayúscula, 1 minúscula y opcional 1 dígito',
				),
			confirmPassword: Yup.string()
				.required('Por favor, ingresa tu contraseña')
				.oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
		}),
		onSubmit: async () => {
			setLoading(true);
			const body = {
				firstName: validation.values.names,
				lastName: validation.values.lastName,
				email: validation.values.email,
				password: validation.values.password,
			};

			const response = await fetch(`${process.env.REACT_APP_API_URL}auth/singup`, {
				method: 'POST',
				headers: getHeaderSimple(),
				body: JSON.stringify(body),
			});
			const json = await response.json();

			if (json.message === 'Ok') {
				validation.resetForm();
				setLoading(false);
				onResult();
			}
		},
	});

	const { error } = useSelector((state) => ({
		error: state.login.error,
	}));

	return (
		<div>
			<p id='p-style-3'>¡Hola!</p>
			<p id='p-style-4'>Ingresa tus datos para continuar</p>
			<Form
				className='form-horizontal'
				onSubmit={(e) => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
				}}>
				{error ? (
					<Alert color='danger'>
						<div>{error}</div>
					</Alert>
				) : null}
				<Row>
					<Col md={12}>
						<div className='mb-3' id='div-style-6'>
							<text id='text-style-1'>{'Nombre (s)'}</text>
							<Input
								name='names'
								className='form-control'
								placeholder='Escribe tu (s) nombres (s)'
								type='text'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.names || ''}
								invalid={
									validation.touched.names && validation.errors.names
										? true
										: false
								}
								id='input-style-1'
							/>
							{validation.touched.names && validation.errors.names ? (
								<FormFeedback type='invalid'>
									<div>{validation.errors.names}</div>
								</FormFeedback>
							) : null}
						</div>
						<div className='mb-3' id='div-style-6'>
							<text id='text-style-1'>Apellidos</text>
							<Input
								name='lastName'
								className='form-control'
								placeholder='Escribe tus apellidos'
								type='text'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.lastName || ''}
								invalid={
									validation.touched.lastName && validation.errors.lastName
										? true
										: false
								}
								id='input-style-1'
							/>
							{validation.touched.lastName && validation.errors.lastName ? (
								<FormFeedback type='invalid'>
									<div>{validation.errors.lastName}</div>
								</FormFeedback>
							) : null}
						</div>
						<div className='mb-3' id='div-style-6'>
							<text id='text-style-1'>Correo electrónico</text>
							<Input
								name='email'
								className='form-control'
								placeholder='Escribe tu (s) nombres (s)'
								type='email'
								onChange={validation.handleChange}
								onBlur={validation.handleBlur}
								value={validation.values.email || ''}
								invalid={
									validation.touched.email && validation.errors.email
										? true
										: false
								}
								id='input-style-1'
							/>
							{validation.touched.email && validation.errors.email ? (
								<FormFeedback type='invalid'>
									<div>{validation.errors.email}</div>
								</FormFeedback>
							) : null}
						</div>
						<div className='mb-3 d-flex' id='div-style-6'>
							<div className='col-11'>
								<text id='text-style-1'>Contraseña</text>
								<Input
									name='password'
									value={validation.values.password || ''}
									type={showPassword ? 'text' : 'password'}
									placeholder='Coloca la contraseña que desees usar'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={
										validation.touched.password && validation.errors.password
											? true
											: false
									}
									id='input-style-1'
								/>
								{validation.touched.password && validation.errors.password ? (
									<FormFeedback type='invalid'>
										<div> {validation.errors.password} </div>
									</FormFeedback>
								) : null}
							</div>

							<div
								className='col-1'
								id='div-style-7'
								onClick={() => {
									setShowPassword(!showPassword);
								}}>
								<i
									className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
									id='icon-style-1'
								/>
							</div>
						</div>
						<div className='mb-3 d-flex' id='div-style-6'>
							<div className='col-11'>
								<text id='text-style-1'>Confirmar contraseña</text>
								<Input
									name='confirmPassword'
									value={validation.values.confirmPassword || ''}
									type={showConfirmPassword ? 'text' : 'password'}
									placeholder='Confirma tu contraseña'
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									invalid={
										validation.touched.confirmPassword &&
										validation.errors.confirmPassword
											? true
											: false
									}
									id='input-style-1'
								/>
								{validation.touched.confirmPassword &&
								validation.errors.confirmPassword ? (
									<FormFeedback type='invalid'>
										<div> {validation.errors.confirmPassword} </div>
									</FormFeedback>
								) : null}
							</div>

							<div
								className='col-1'
								id='div-style-7'
								onClick={() => {
									setShowConfirmPassword(!showConfirmPassword);
								}}>
								<i
									className={
										showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
									}
									id='icon-style-1'
								/>
							</div>
						</div>

						<div className='d-grid' id='div-style-2'>
							{loading ? (
								<div id='div-style-7'>
									<Spinner id='spinner-style-1' size='lg' />
								</div>
							) : (
								<button
									className='btn waves-effect waves-light'
									id='button-style-1'
									type='submit'>
									Registrarse
								</button>
							)}
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default withRouter(SignUpForm);

SignUpForm.propTypes = {
	history: PropTypes.object,
};

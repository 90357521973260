import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import 'moment/locale/es';

export function generateParentXls(list) {
	const dataType = 'application/vnd.ms-excel';
	const tbl = document.createElement('table');
	const tblHead = document.createElement('thead');
	const tblBody = document.createElement('tbody');
	const rowHeader = document.createElement('tr');
	const headers = ['No.', 'Tutor', 'Parentesco', 'Teléfono', 'Correo'];

	for (let q = 0; q < headers.length; q += 1) {
		const cellText = document.createTextNode(headers[q]);
		const cellTh = document.createElement('th');
		cellTh.appendChild(cellText);
		rowHeader.appendChild(cellTh);
	}

	tblHead.appendChild(rowHeader);

	for (let w = 0; w < list.length; w += 1) {
		const rowBody = document.createElement('tr');
		const itemW = list[w];
		const cellText1 = document.createTextNode(itemW.id);
		const cellText2 = document.createTextNode(`${itemW.name} ${itemW.lastName}`);
		const cellText3 = document.createTextNode(itemW.relationship);
		const cellText4 = document.createTextNode(itemW.phone);
		const cellText5 = document.createTextNode(itemW.email);

		const cellTd1 = document.createElement('td');
		const cellTd2 = document.createElement('td');
		const cellTd3 = document.createElement('td');
		const cellTd4 = document.createElement('td');
		const cellTd5 = document.createElement('td');

		cellTd1.appendChild(cellText1);
		cellTd2.appendChild(cellText2);
		cellTd3.appendChild(cellText3);
		cellTd4.appendChild(cellText4);
		cellTd5.appendChild(cellText5);

		rowBody.appendChild(cellTd1);
		rowBody.appendChild(cellTd2);
		rowBody.appendChild(cellTd3);
		rowBody.appendChild(cellTd4);
		rowBody.appendChild(cellTd5);

		tblBody.appendChild(rowBody);
	}

	tbl.appendChild(tblHead);
	tbl.appendChild(tblBody);
	const tableSelect = tbl;
	const tableHTML = tableSelect.outerHTML
		.replace(/ /g, '%20')
		.replace(/á/g, '%E1')
		.replace(/í/g, '%ED')
		.replace(/ú/g, '%FA')
		.replace(/é/g, '%E9')
		.replace(/ó/g, '%F3')
		.replace(/ñ/g, '%F1')
		.replace(/ü/g, '%FC')
		.replace(/Á/g, '%C1')
		.replace(/Í/g, '%CD')
		.replace(/Ú/g, '%DA')
		.replace(/É/g, '%C9')
		.replace(/Ó/g, '%D3')
		.replace(/Ñ/g, '%D1')
		.replace(/#/g, '%23')
		.replace(/Ü/g, '%DC');
	const filename = 'Padres.xls';
	const downloadLink = document.createElement('a');

	document.body.appendChild(downloadLink);

	if (navigator.msSaveOrOpenBlob) {
		const blob = new Blob(['ufeff', tableHTML], {
			type: dataType,
		});
		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		downloadLink.href = `data: ${dataType}, ${tableHTML}`;
		downloadLink.download = filename;
		downloadLink.click();
	}
}

export function generateParentPdf(list) {
	const pdf = new JsPDF();
	const totalPagesExp = '{total_pages_count_string}';
	const width = pdf.internal.pageSize.getWidth();

	pdf.setFont(undefined, 'bold');
	pdf.text('Padres', width / 2, 20, { align: 'center' });
	pdf.setFontSize(12);

	pdf.setFont(undefined, 'bold');
	pdf.text('Fecha: ', 14, 35);
	pdf.setFontSize(12);
	pdf.setFont(undefined, 'normal');
	pdf.text(
		`${moment().format('DD')} de ${moment().locale('es').format('MMMM')} del ${moment().format(
			'YYYY',
		)}.`,
		29,
		35,
	);

	pdf.autoTable({
		head: [['No.', 'Tutor', 'Parentesco', 'Teléfono', 'Correo']],
		headStyles: { fillColor: [11, 177, 151], textColor: [255, 255, 255] },
		columns: [
			{ dataKey: 'id', header: 'No.' },
			{ dataKey: 'fullName', header: 'Tutor' },
			{ dataKey: 'relationship', header: 'Parentesco' },
			{ dataKey: 'phone', header: 'Teléfono' },
			{ dataKey: 'email', header: 'Correo' },
		],
		body: bodyRows(list),
		startY: 40,
		didDrawPage: (dataq) => {
			let str = `Página ${pdf.internal.getNumberOfPages()}`;
			if (typeof pdf.putTotalPages === 'function') {
				str = `${str} de ${totalPagesExp}`;
			}
			pdf.setFontSize(10);
			pdf.setTextColor(123, 159, 255);
			pdf.text(str, dataq.settings.margin.left, pdf.internal.pageSize.getHeight() - 10);
		},
	});

	if (typeof pdf.putTotalPages === 'function') {
		pdf.putTotalPages(totalPagesExp);
	}
	pdf.save('Padres.pdf');
}

function bodyRows(list) {
	const body = [];

	list.map(({ id, name, lastName, relationship, phone, email }) =>
		body.push({
			id: id,
			fullName: `${name} ${lastName}`,
			relationship: relationship,
			phone: phone,
			email: email,
		}),
	);

	return body;
}

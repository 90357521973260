/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	CardImg,
	FormFeedback,
	Form,
	Input,
	Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderMultipart } from '../Utility/utils';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

// img
import avatarDefault from '../../assets/images/users/AvatarTaco.png';

const AddNewProduct = ({ openCanvas, tittle, editItem, refreshList, onlyClose}) => {
	const [isRight, setIsRight] = useState(false);
	const [avatarImage, setAvatarImage] = useState('');
	const avatarImageUpload = () => {
		// eslint-disable-next-line no-unused-expressions
		document.getElementById('file').files[0];
		setAvatarImage(URL.createObjectURL(document.getElementById('file').files[0]));
	};
	const [loading, setLoading] = useState(false);

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const formik = useFormik({
		initialValues: {
			code: '',
			barcode: '',
			name: '',
			description: '',
			category: '',
			type: '',
			brand: '',
			price: '',
			minimum: '',
			expiration: '',
			batch: '',
			supplier: '',
			stock: '',
			seals: '',
			status: '',
		},
		validationSchema: Yup.object({
			code: Yup.string().required('Por favor, ingrese la matrícula'),
			barcode: Yup.string().required('Por favor, ingrese la matrícula'),
			name: Yup.string().required('Por favor, ingrese la matrícula'),
			description: Yup.string().required('Por favor, ingrese la matrícula'),
			category: Yup.string().required('Por favor, ingrese la matrícula'),
			type: Yup.string().required('Por favor, ingrese la matrícula'),
			brand: Yup.string().required('Por favor, ingrese la matrícula'),
			price: Yup.string().required('Por favor, ingrese la matrícula'),
			minimum: Yup.string().required('Por favor, ingrese la matrícula'),
			expiration: Yup.string().required('Por favor, ingrese la matrícula'),
			batch: Yup.string().required('Por favor, ingrese la matrícula'),
			seals: Yup.string().required('Por favor, ingrese la matrícula'),
			supplier: Yup.string().required('Por favor, ingrese la matrícula'),
			status: Yup.string().required('Por favor, ingrese la matrícula'),
			stock: Yup.string().required('Por favor, ingrese la matrícula'),
		}),
		onSubmit: () => {
			setLoading(true);
			if (tittle === 'Editar registro') {
				const formData = new FormData();
				formData.append('code', formik.values.code);
				formData.append('barcode', formik.values.barcode);
				formData.append('name', formik.values.name);
				formData.append('description', formik.values.description);
				formData.append('category', formik.values.category);
				formData.append('type', formik.values.type);
				formData.append('brand', formik.values.brand);
				formData.append('price', formik.values.price);
				formData.append('minimum', formik.values.minimum);
				formData.append('expiration', formik.values.expiration);
				formData.append('batch', formik.values.batch);
				formData.append('seals', formik.values.seals);
				formData.append('stock', formik.values.stock);
				formData.append('status', formik.values.status);
				formData.append('supplier', formik.values.supplier);

				axios({
					method: 'PUT',
					url: `${process.env.REACT_APP_API_URL}products/${editItem._id}`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								id: editItem.id,
								_id: editItem._id,
								code: formik.values.code,
								barcode: formik.values.barcode,
								name: formik.values.name,
								description: formik.values.description,
								category: formik.values.category,
								type: formik.values.type,
								brand: formik.values.brand,
								price: formik.values.price,
								stock: formik.values.stock,
								minimum: formik.values.minimum,
								expiration: formik.values.expiration,
								batch: formik.values.batch,
								seals: formik.values.seals,
								status: formik.values.status,
								supplier: formik.values.supplier,
							};

							newItem.parentData = editItem.parentData;
							newItem.additionalParent = editItem.additionalParent;
							toast.success('La operación se realizó con éxito', toastStyle);
							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								toast.success('La operación se realizó con éxito', toastStyle);
								setLoading(false);
							} else {
								setLoading(false);
								toast.success('La operación se realizó con éxito', toastStyle);
							}
						}
					})
					.catch((error) => {});
			} else {
				const formData = new FormData();
				formData.append('code', formik.values.code);
				formData.append('barcode', formik.values.barcode);
				formData.append('name', formik.values.name);
				formData.append('description', formik.values.description);
				formData.append('category', formik.values.category);
				formData.append('type', formik.values.type);
				formData.append('brand', formik.values.brand);
				formData.append('price', formik.values.price);
				formData.append('minimum', formik.values.minimum);
				formData.append('expiration', formik.values.expiration);
				formData.append('batch', formik.values.batch);
				formData.append('seals', formik.values.seals);
				formData.append('stock', formik.values.stock);
				formData.append('status', formik.values.status);
				formData.append('supplier', formik.values.supplier);

				axios({
					method: 'POST',
					url: `${process.env.REACT_APP_API_URL}products`,
					data: formData,
					headers: getHeaderMultipart(),
				})
					.then((response) => {
						if (response.message === 'Ok') {
							const newItem = {
								_id: response.content._id,
								code: formik.values.code,
								barcode: formik.values.barcode,
								name: formik.values.name,
								description: formik.values.description,
								category: formik.values.category,
								type: formik.values.type,
								brand: formik.values.brand,
								price: formik.values.price,
								stock: formik.values.stock,
								supplier: formik.values.supplier,
								minimum: formik.values.minimum,
								expiration: formik.values.expiration,
								batch: formik.values.batch,
								seals: formik.values.seals,
								status: formik.values.status,
							};
							toast.success('La operación se realizó con éxito', toastStyle);
							refreshList(newItem);
							setLoading(false);
							formik.resetForm();
							formik.setValues({});
							toggleRightCanvas();
						} else {
							if (response.message === 'Información duplicada') {
								toast.success('La operación se realizó con éxito', toastStyle);
								setLoading(false);
							} else {
								setLoading(false);
								toast.success('La operación se realizó con éxito', toastStyle);
							}
						}
					})
					.catch((error) => {});
			}
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	useEffect(() => {
		setIsRight(openCanvas);
		formik.setValues({});
		formik.resetForm();
		setLoading(false);
		setAvatarImage(avatarDefault);

		if (editItem !== null) {
			formik.setValues({
				_id: editItem._id,
				code: editItem.code,
				barcode: editItem.barcode,
				name: editItem.name,
				description: editItem.description,
				category: editItem.category,
				type: editItem.type,
				brand: editItem.brand,
				price: editItem.price,
				stock: editItem.stock,
				minimum: editItem.minimum,
				expiration: editItem.expiration,
				batch: editItem.batch,
				seals: editItem.seals,
				status: editItem.status,
				supplier: editItem.supplier,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCanvas]);
	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			style={{
				height: '100%',
				width: '35%',
				top: '0%',
				left: '65%',
				borderRadius: '8px 0px 0px 8px',
			}}
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<CardBody>
									<div className='row d-flex  align-items-center'>
										<div className='col-2'>
											<CardImg
												id='img-bgc-g'
												src={avatarImage}
												alt='Upzet'
												className='rounded avatar-lg'
											/>
										</div>
										<div className='col-10'>
											<div className='customfile'>
												<Input
													type='file'
													name='file'
													className='inputfile'
													id='file'
													accept='image/jpg, image/jpeg, image/png'
													lang='es'
													onChange={(e) => {
														// eslint-disable-next-line no-unused-expressions
														formik.setFieldValue(
															'avatar',
															e.currentTarget.files[0],
														);
														avatarImageUpload();
													}}
												/>
											</div>
											<div className='mt-2'>
												<span>
													Colocar la imagen ayuda a identificar lo
													productos.
												</span>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							<Card id='card-into-off-canvas'>
								<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
									<CardBody>
										<div className='row'>
											<div>
												<h4>Datos del prodcuto</h4>
											</div>
											<div className='col-6 mt-0'>
												<label className='col-md-6 col-form-label text-muted'>
													Código
												</label>
												<Input
													name='code'
													type='text'
													value={formik.values.code}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.code && formik.errors.code
															? true
															: false
													}></Input>
												{formik.touched.code && formik.errors.code ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.code}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='barcode'
													className='col-form-label text-muted'>
													Código de barras
												</label>
												<Input
													className='form-control'
													type='text'
													id='barcode'
													name='barcode'
													value={formik.values.barcode}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.barcode &&
														formik.errors.barcode
															? true
															: false
													}
												/>
												{formik.touched.barcode && formik.errors.barcode ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.barcode}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>

												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-8 mt-0'>
												<label
													htmlFor='description'
													className='col-form-label text-muted'>
													Descripción
												</label>

												<Input
													className='form-control'
													type='text'
													id='description'
													name='description'
													value={formik.values.description}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.description &&
														formik.errors.description
															? true
															: false
													}
												/>
												{formik.touched.description &&
												formik.errors.description ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.description}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label className='col-md-6 col-form-label text-muted'>
													Categoría
												</label>
												<Input
													name='category'
													type='select'
													value={formik.values.category}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.category &&
														formik.errors.category
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													<option value='Dulceria'>Dulceria</option>
													<option value='Abarrote'>Abarrote</option>
													<option value='Bebidas'>Bebidas</option>
												</Input>
												{formik.touched.category &&
												formik.errors.category ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.category}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label className='col-md-6 col-form-label text-muted'>
													Tipo
												</label>
												<Input
													name='type'
													type='select'
													value={formik.values.type}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.type && formik.errors.type
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													<option value='Alimento'>Alimento</option>
													<option value='Bebida'>Bebida</option>
												</Input>
												{formik.touched.type && formik.errors.type ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.type}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='brand'
													className='col-form-label text-muted'>
													Marca
												</label>

												<Input
													className='form-control'
													type='text'
													id='brand'
													name='brand'
													value={formik.values.brand}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.brand && formik.errors.brand
															? true
															: false
													}
												/>
												{formik.touched.brand && formik.errors.brand ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.brand}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='price'
													className='col-form-label text-muted'>
													Precio
												</label>
												<Input
													className='form-control'
													type='text'
													id='price'
													name='price'
													value={formik.values.price}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.price && formik.errors.price
															? true
															: false
													}
												/>
												{formik.touched.price && formik.errors.price ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.price}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='stock'
													className='col-form-label text-muted'>
													Stock
												</label>
												<Input
													className='form-control'
													type='number'
													id='stock'
													name='stock'
													value={formik.values.stock}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.stock && formik.errors.stock
															? true
															: false
													}
												/>
												{formik.touched.stock && formik.errors.stock ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.stock}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='minimum'
													className='col-form-label text-muted'>
													Stock mínimo
												</label>
												<Input
													className='form-control'
													type='number'
													id='minimum'
													name='minimum'
													value={formik.values.minimum}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.minimum &&
														formik.errors.minimum
															? true
															: false
													}
												/>
												{formik.touched.minimum && formik.errors.minimum ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.minimum}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='expiration'
													className='col-form-label text-muted'>
													Caducidad
												</label>
												<Input
													className='form-control'
													type='date'
													id='expiration'
													name='expiration'
													value={formik.values.expiration}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.expiration &&
														formik.errors.expiration
															? true
															: false
													}
												/>
												{formik.touched.expiration &&
												formik.errors.expiration ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.expiration}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='batch'
													className='col-form-label text-muted'>
													Lote
												</label>
												<Input
													className='form-control'
													type='text'
													id='batch'
													name='batch'
													value={formik.values.batch}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.batch && formik.errors.batch
															? true
															: false
													}
												/>
											</div>
											<div className='col-12 mt-0'>
												<label
													htmlFor='supplier'
													className='col-form-label text-muted'>
													Proveedor
												</label>
												<Input
													className='form-control'
													type='text'
													id='supplier'
													name='supplier'
													value={formik.values.supplier}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.supplier &&
														formik.errors.supplier
															? true
															: false
													}
												/>
												{formik.touched.supplier &&
												formik.errors.supplier ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.supplier}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label className='col-md-6 col-form-label text-muted'>
													Sellos
												</label>
												<Input
													name='seals'
													type='select'
													value={formik.values.seals}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.seals && formik.errors.seals
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													<option value='Un Sello'>Un Sello</option>
													<option value='Dos Sellos'>Dos Sellos</option>
													<option value='Tres Sellos'>Tres Sellos</option>
													<option value='Cuatro Sellos'>
														Cuatro Sellos
													</option>
												</Input>
												{formik.touched.seals && formik.errors.seals ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.seals}</div>
													</FormFeedback>
												) : null}
											</div>

											<div className='col-6 mt-0'>
												<label className='col-md-6 col-form-label text-muted'>
													Estado
												</label>
												<Input
													name='status'
													type='select'
													value={formik.values.status}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.status &&
														formik.errors.status
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													<option value='Activo'>Activo</option>
													<option value='Pausado'>Pausado</option>
													<option value='Desactivado'>Desactivado</option>
												</Input>
												{formik.touched.status && formik.errors.status ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.status}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
									<CardFooter id='card-footer-off-canvas'>
										<div className='d-flex justify-content-center'>
											{loading ? (
												<div id='div-style-7'>
													<Spinner id='spinner-style-1' size='lg' />
												</div>
											) : (
												<button
													type='submit'
													className='btn btn-success waves-effect waves-light w-50'
													id='create-btn'>
													<i className='fas fa-save me-1' />
													{tittle === 'Editar registro'
														? 'Actualizar'
														: 'Guardar'}
												</button>
											)}
										</div>
									</CardFooter>
								</Form>
								<ToastContainer />
							</Card>
						</div>
					</div>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewProduct.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewProduct.defaultProps = {
	openCanvas: false,
};

export default AddNewProduct;

import React, { useEffect, useState } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
} from 'reactstrap';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import { debounce } from 'lodash';
import { getHeaderSimpleToken } from '../Utility/utils';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommonSchoolGradesEvents = () => {
	const [dataList, setDataList] = useState([]);
	const [materiaDataList, setMateriaDataList] = useState([]);
	const [dataFilterList, setDataFilterList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const [selectedSubjectValue, setSelectedSubjectValue] = useState(null);
	const [selectedGradeValue, setSelectedGradeValue] = useState(null);
	const [selectedGroupValue, setSelectedGroupValue] = useState(null);
	const [selectedSchoolYearValue, setSelectedSchoolYearValue] = useState(null);
	const [educationOffert, setEducationOffert] = useState([]);
	const [loading, setLoading] = useState(false);
	const [numberOfExam, setNumberOfExam] = useState(0);

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const handleChangeSubject = (event) => {
		setSelectedSubjectValue(event);
	};

	const handleChangeGrade = (event) => {
		setSelectedGradeValue(event);
	};

	const handleChangeGroup = (event) => {
		setSelectedGroupValue(event);
	};

	const handleChangeSchoolYear = (event) => {
		setSelectedSchoolYearValue(event);
	};

	const handleSearch = () => {
		const filteredData = dataList.filter(
			(item) =>
				item?.gradesData?.subjectData._id === selectedSubjectValue.value &&
				item.level === selectedGradeValue.value &&
				item.group === selectedGroupValue.value &&
				item?.gradesData.schoolYear === selectedSchoolYearValue.value,
		)
			.map((item) => ({
				_id: item._id,
				controlNumber: item.controlNumber,
				name: item.name,
				lastName: item.lastName,
				grade: item.gradesData.grade,
				idGrade: item.gradesData._id,
			}));
		setDataFilterList(filteredData);
	};

	function educationoffers() {
		fetch(`${process.env.REACT_APP_API_URL}educationoffer/school`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setEducationOffert(rawData.data);
				} else {
					setEducationOffert([]);
				};
			});
	};

	async function getGradesData() {
		fetch(`${process.env.REACT_APP_API_URL}grades`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.message === 'Ok') {
					setDataList(rawData.data);
				} else {
					setDataList([]);
				};
			});
	};

	async function getMateriaData() {
		fetch(`${process.env.REACT_APP_API_URL}subject`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((rawData) => {
				if (rawData.data.length !== 0) {
					setMateriaDataList(rawData.data);
				} else {
					setMateriaDataList([]);
				};
			});
	};

	useEffect(() => {
		getGradesData();
		getMateriaData();
		educationoffers();
	}, []);

	const delayedHandleSearch = debounce((search) => {
		if (search !== '') {
			search = search
				.replace('á', 'a')
				.replace('í', 'i')
				.replace('ú', 'u')
				.replace('é', 'e')
				.replace('ó', 'o');

			const filterResult = dataList.filter(
				(item) =>
					item.studentData[0].name
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search) ||
					item.studentData[0].lastName
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search) ||
					item.subjectData[0].name
						.toString()
						.toLowerCase()
						.replace('á', 'a')
						.replace('í', 'i')
						.replace('ú', 'u')
						.replace('é', 'e')
						.replace('ó', 'o')
						.includes(search),
			);
			setDataFilterList(filterResult);
		} else {
			setDataFilterList(dataList);
		}
	}, 700);

	const handleGradeChange = async (indexDataFilterList, indexGrades, value) => {
		setLoading(true);
		const updatedDataFilterList = [...dataFilterList];
		const gradeItem = updatedDataFilterList[indexDataFilterList];
		const x = gradeItem.grade[0]?.split(",")
		x[indexGrades] = value;
		gradeItem.grade[0] = x.join(",");
		updatedDataFilterList[indexDataFilterList] = gradeItem;
		setDataFilterList(updatedDataFilterList);
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}grades/${gradeItem.idGrade}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ grade: gradeItem.grade[0] }),
				},
				toast.success('Calificación actualizada', toastStyle),
			);

			if (!response.ok) {
				throw new Error('Failed to update grade');
			}

		} catch (error) {
			toast.error('Error al actualizar la calificación', toastStyle);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (
			!selectedSubjectValue ||
			!selectedGradeValue ||
			!selectedGroupValue ||
			!selectedSchoolYearValue
		) {
			setDataFilterList([]);
			return;
		}
	}, [
		selectedSubjectValue,
		selectedGradeValue,
		selectedGroupValue,
		selectedSchoolYearValue,
		dataList,
	]);

	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid>
					<div className='d-flex justify-content-center align-items-center'>
						<div className='col-xl-10 col-lg-9 col-md-6 col-sm-7 col-6'>
							<span className='span-tittle-text'>Calificaciones</span>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-6 col-sm-5 col-6'>
							<form className='app-search d-none d-lg-block'>
								<div className='col-12 position-relative'>
									<input
										type='text'
										className='form-control bg-white'
										placeholder='Buscar...'
										onChange={(event) => {
											delayedHandleSearch(event.target.value.toLowerCase());
										}}
									/>
									<span className='ri-search-line'></span>
								</div>
							</form>
						</div>
					</div>
					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6 h4 text-primary'>
											<i className='fas fa-user-graduate' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
									</div>
									<Row className='mt-4'>
										<Col lg={3} md={6} sm={12} className='mb-2'>
											Materia
											<Select
												placeholder='Seleccione'
												options={materiaDataList.map((subject) => ({
													label: subject.name,
													value: subject._id,
												}))}
												value={selectedSubjectValue}
												onChange={handleChangeSubject}
											/>
										</Col>
										<Col lg={3} md={6} sm={12} className='mb-2'>
											Grado
											<Select
												name='grade'
												options={[
													{ value: '1', label: '1ro' },
													{ value: '2', label: '2do' },
													{ value: '3', label: '3ro' },
													{ value: '4', label: '4to' },
													{ value: '5', label: '5to' },
													{ value: '6', label: '6to' },
													{ value: '7', label: '7mo' },
													{ value: '8', label: '8vo' },
													{ value: '9', label: '9no' },
													{ value: '10', label: '10mo' },
													{ value: '11', label: '11vo' },
													{ value: '12', label: '12vo' },
												]}
												placeholder='Seleccione'
												onChange={handleChangeGrade}
												value={selectedGradeValue}
												noOptionsMessage={() => 'No hay resultados'}
											/>
										</Col>
										<Col lg={3} md={6} sm={12} className='mb-2'>
											Grupo
											<Select
												name='group'
												options={[
													{ value: 'a', label: 'A' },
													{ value: 'b', label: 'B' },
													{ value: 'c', label: 'C' },
													{ value: 'd', label: 'D' },
													{ value: 'e', label: 'E' },
													{ value: 'f', label: 'F' },
													{ value: 'g', label: 'G' },
													{ value: 'h', label: 'H' },
													{ value: 'i', label: 'I' },
													{ value: 'j', label: 'J' },
												]}
												placeholder='Seleccione'
												onChange={handleChangeGroup}
												value={selectedGroupValue}
												noOptionsMessage={() => 'No hay resultados'}
											/>
										</Col>
										<Col lg={3} md={6} sm={12} className='mb-2'>
											Ciclo Escolar
											<Select
												name='school_year'
												options={[
													{ value: '2023-2024', label: '2023-2024' },
													{ value: '2024-2025', label: '2024-2025' },
												]}
												placeholder='Seleccione'
												onChange={handleChangeSchoolYear}
												value={selectedSchoolYearValue}
												noOptionsMessage={() => 'No hay resultados'}
											/>
										</Col>
									</Row>
									<div className='d-flex justify-content-end mt-3'>
										<button
											className='btn btn-primary'
											onClick={handleSearch}>
											Buscar
										</button>
									</div>
								</CardHeader>
								<CardBody>
									{(
										<div className='table-responsive table-card mt-3 mb-1'>
											<table
												className='table align-center table-nowrap'
												id='customerTable'>
												<thead className='table-light'>
													<tr>
														<th
															className='sort text-center'
															data-sort='customer_name'>
															No.
														</th>
														<th
															className='sort text-center'
															data-sort='customer_name'>
															Matricula
														</th>
														<th
															className='sort text-center'
															data-sort='customer_name'>
															Nombre
														</th>
														{dataFilterList.length !== 0 && (
															dataFilterList[0].grade[0].split(",").map((grade, index) => {
																return (
																	<th key={index} className='sort text-center' data-sort='customer_name'>{`Parcial ${index + 1}`}</th>
																);
															}))}
													</tr>
												</thead>
												<tbody className='list form-check-all'>
													{dataPagination(
														dataFilterList,
														currentPage,
														perPage,
													).map((item, index) => (
														<tr key={item._id}>
															<td>
																<div className='text-center'>
																	{index + 1}
																</div>
															</td>
															<td>
																<div className='text-center'>
																	{item.controlNumber}
																</div>
															</td>
															<td className='text-center'>
																<div>
																	{item.name +
																		' ' +
																		item.lastName}
																</div>
															</td>
															{
																dataFilterList[index].grade[0].split(",").map((grade, i) => (
																	<td key={i} className='text-center'>
																		<input
																			style={{ border: "none", textAlign: "center" }}
																			value={grade}
																			onChange={(e) => handleGradeChange(index, i, e.target.value)}
																		/>
																	</td>
																))
															}
														</tr>
													))}
												</tbody>
											</table>
											<div className='noresult d-none'>
												<div className='text-center'>
													<h5 className='mt-1'>
														No se encontraron resultados
													</h5>
												</div>
											</div>
										</div>
									)}
								</CardBody>
								<div className='d-flex justify-content-center'>
									<button
										type='submit'
										className='btn btn-success waves-effect waves-light w-30'
										id='create-btn'
										onClick={handleGradeChange}>
										<i className='fas fa-save me-1' />
										Guardar
									</button>

								</div>
								<PaginationButtons
									data={dataFilterList}
									label='registros'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</Col>
					</Row>
				</Container>
				<ToastContainer />
			</div>
		</React.Fragment>
	);
};

export default CommonSchoolGradesEvents;

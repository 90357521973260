import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import estadosJson from '../../assets/json/estadoDeMexicoYDf';
import dataMapJson from '../../assets/json/cincodelegacinesmapdata';

echarts.registerMap('USA', estadosJson, {});

dataMapJson.sort(function (a, b) {
	return a.value - b.value;
});

const MapChart = () => {
	const mapOption = {
		visualMap: {
			left: 'right',
			min: 0,
			max: 1,
			inRange: {
				color: [
					'#313695',
					'#4575b4',
					'#74add1',
					'#abd9e9',
					'#e0f3f8',
					'#ffffbf',
					'#fee090',
					'#fdae61',
					'#f46d43',
					'#d73027',
					'#a50026',
				],
			},
			text: ['Máx.', 'Mín.'],
			calculable: true,
		},
		series: [
			{
				id: 'population',
				type: 'map',
				map: 'USA',
				animationDurationUpdate: 1000,
				universalTransition: true,
				data: dataMapJson,
				roam: 'move',
				nodeClick: false,
			},
		],
	};

	return (
		<React.Fragment>
			<Col lg={12}>
				<Card>
					<CardBody>
						<div style={{ fontWeight: 'bold', fontSize: '24px', color: '#000' }}>
							Índices de salud por zona
						</div>

						<ReactEcharts style={{ height: '65vh' }} option={mapOption} />

						<div style={{ textAlign: 'center', marginTop: '30px' }}>
							<button
								type='button'
								className='btn'
								style={{
									backgroundColor: '#00A88D',
									color: '#fff',
									fontSize: '22px',
									fontWeight: 'bold',
									paddingLeft: '48px',
									paddingTop: '2px',
									paddingRight: '48px',
									paddingBottom: '2px',
								}}>
								Ver detalle
							</button>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default MapChart;

export function getHeaderSimple() {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};
}

export function getHeaderSimpleToken() {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		institutetenant: `${localStorage.getItem('USER_INSTITUTE_TENANT')}`,
		schooltenant: `${localStorage.getItem('USER_SCHOOL_TENANT')}`,
		dbname: `${localStorage.getItem('USER_TENANT')}`,
		// schoolid: `${localStorage.getItem('SCHOOL_ID')}`,
		// educativeofferid: `${localStorage.getItem('EDUCATIVE_OFFER_ID')}`,
	};
}

export function getHeaderMultipart() {
	return {
		'Content-Type': 'multipart/form-data',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		institutetenant: `${localStorage.getItem('USER_INSTITUTE_TENANT')}`,
		schooltenant: `${localStorage.getItem('USER_SCHOOL_TENANT')}`,
		dbname: `${localStorage.getItem('USER_TENANT')}`,
		// schoolid: `${localStorage.getItem('SCHOOL_ID')}`,
		// educativeofferid: `${localStorage.getItem('EDUCATIVE_OFFER_ID')}`,
	};
}


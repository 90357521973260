import React from 'react';
import RadialChart from './RadialChart';

import { Card, CardBody, Col, Row } from 'reactstrap';

import { SocialSourceData } from '../../CommonData/Data/index';

const SocialSource = () => {
	return (
		<React.Fragment>
			<Col xl={4}>
				<Card>
					<CardBody>
						<div className='d-flex  align-items-center'>
							<div className='flex-grow-1'>
								<div
									style={{ fontSize: '24px', fontWeight: 'bold', color: '#000' }}>
									Salud
								</div>
							</div>
						</div>
						{/* RadialChart */}
						<RadialChart />
						<Row>
							{SocialSourceData.map((item, key) => (
								<div key={key} className='col-6'>
									<div
										style={{
											display: 'flex',
											marginLeft: '25px',
											marginBottom: '36px',
										}}>
										<div
											className={'avatar-title rounded-circle'}
											style={{
												backgroundColor: item.bgcolor,
												height: '25px',
												width: '25px',
												marginTop: '2px',
											}}
										/>
										<div style={{ marginLeft: '10px' }}>
											<div
												style={{
													color: '#2C2C2C',
													fontSize: '20px',
													fontWeight: '500',
												}}>
												{item.title}
											</div>
											<div style={{ color: '#696D81', fontSize: '14px' }}>
												{item.count} alumnos
											</div>
										</div>
									</div>
								</div>
							))}
						</Row>
						<div style={{ textAlign: 'center' }}>
							<button
								type='button'
								className='btn'
								style={{
									backgroundColor: '#00A88D',
									color: '#fff',
									fontSize: '22px',
									fontWeight: 'bold',
									paddingLeft: '48px',
									paddingTop: '2px',
									paddingRight: '48px',
									paddingBottom: '2px',
								}}>
								Ver más
							</button>
						</div>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default SocialSource;

import React from 'react';
import PropTypes from 'prop-types';
import { CardFooter } from 'reactstrap';
import { Pagination, PaginationItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export const PER_COUNT = {
	3: 3,
	5: 5,
	10: 10,
	25: 25,
	50: 50,
};

export const dataPagination = (data, currentPage, perPage) =>
	data.filter(
		(i, index) => index + 1 > (currentPage - 1) * perPage && index + 1 <= currentPage * perPage,
	);

const PaginationButtons = ({ setCurrentPage, currentPage, perPage, setPerPage, data, label }) => {
	const totalItems = data.length;
	const totalPage = Math.ceil(totalItems / perPage);

	const pagination = () => {
		let items = [];

		let i = currentPage - 1;
		while (i >= currentPage - 1 && i > 0) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage - 1)}>
					<ul className='pagination pagination-rounded mb-0'>
						<li className='page-item'>
							<Link className='page-link' to='#'>
								{i}
							</Link>
						</li>
					</ul>
				</PaginationItem>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<PaginationItem key={currentPage} isActive onClick={() => setCurrentPage(currentPage)}>
				<ul className='pagination pagination-rounded mb-0'>
					<li className='page-item active'>
						<Link className='page-link' to='#'>
							{currentPage}
						</Link>
					</li>
				</ul>
			</PaginationItem>,
		);

		i = currentPage + 1;
		while (i <= currentPage + 1 && i <= totalPage) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage + 1)}>
					<ul className='pagination pagination-rounded mb-0'>
						<li className='page-item'>
							<Link className='page-link' to='#'>
								{i}
							</Link>
						</li>
					</ul>
				</PaginationItem>,
			);

			i += 1;
		}

		return items;
	};

	const getInfo = () => {
		const end = perPage * currentPage;

		return (
			<span className='pagination__desc'>
				{end > totalItems ? totalItems : end} de {totalItems} {label}
			</span>
		);
	};

	return (
		<CardFooter style={{ backgroundColor: 'white' }}>
			<div className='d-flex'>
				<div className='col-0' style={{ marginTop: '10px'}}>
					<span className='text-muted'>{getInfo()}</span>
				</div>
               
				<div className='col-0' style={{marginLeft: '20px', marginTop: '5px', width: '25'}}>
					{totalPage > 1 && (
						<Pagination arialabel={label}>
							<PaginationItem
								isFirst
								isDisabled={!(currentPage - 1 > 0)}
								onClick={() => setCurrentPage(1)}
							/>
							<PaginationItem
								isPrev
								isDisabled={!(currentPage - 1 > 0)}
								onClick={() => setCurrentPage(currentPage - 1)}
							/>
							{currentPage - 1 > 1 && (
								<PaginationItem onClick={() => setCurrentPage(currentPage - 2)}>
									...
								</PaginationItem>
							)}
							{pagination()}
							{currentPage + 1 < totalPage && (
								<PaginationItem onClick={() => setCurrentPage(currentPage + 2)}>
									...
								</PaginationItem>
							)}
							<PaginationItem
								isNext
								isDisabled={!(currentPage + 1 <= totalPage)}
								onClick={() => setCurrentPage(currentPage + 1)}
							/>
							<PaginationItem
								isLast
								isDisabled={!(currentPage + 1 <= totalPage)}
								onClick={() => setCurrentPage(totalPage)}
							/>
						</Pagination>
					)}
				</div>
                <div className='col-0' style={{marginLeft: '20px'}}>
					<select
                        style={{width: '60px'}}
                        className='form-select'
						size='sm'
						arialabel='Per'
						onChange={(e) => {
							setPerPage(parseInt(e.target.value, 10));
							setCurrentPage(1);
						}}
						value={perPage.toString()}>
						{Object.keys(PER_COUNT).map((i) => (
							<option key={i} value={i}>
								{i}
							</option>
						))}
					</select>
				</div>
			</div>
		</CardFooter>
	);
};
PaginationButtons.propTypes = {
	setCurrentPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	setPerPage: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	label: PropTypes.string,
};
PaginationButtons.defaultProps = {
	label: 'items',
};

export default PaginationButtons;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardHeader,
	Modal,
	Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { getHeaderSimpleToken } from '../Utility/utils';
import EditTeacherSchedule from './EditTeacherSchedule';

const numToDay = {
	0: 'Lunes',
	1: 'Martes',
	2: 'Miércoles',
	3: 'Jueves',
	4: 'Viernes',
	5: 'Sábado',
	6: 'Domingo',
};

const CalendarTeacherSchedule = ({ openCanvas, tittle, onlyClose, teacherId }) => {
	const [isRight, setIsRight] = useState(false);
	const [schedulesData, setSchedulesData] = useState([]);
	const [maxRows, setMaxRows] = useState(null);
	const [scheduleSelectedEdit, setScheduleSelectedEdit] = useState(null);
	const [openCanvasEditTeacherSchedule, setOpenCanvasEditTeacherSchedule] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false)
	const [selecItemDeleted, setSelectItemDeleted] = useState(null);
	const [titleEditTeacherSchedule, setTitleEditTeacherSchedule] = useState('Editar registro');
	const [schoolYears, setSchoolYears] = useState(["2022-2023", "2023-2024"]);
	const [schoolYearSelected, setSchoolYearSelected] = useState("2023-2024");

	const handleSchoolyearChange = async (event) => {
		setSchoolYearSelected(event.target.value);
	}


	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	const onlyCloseEditTeacherSchedule = async () => {
		setOpenCanvasEditTeacherSchedule(false);
		setScheduleSelectedEdit(null);
		setTitleEditTeacherSchedule('Editar registro');
	};
	const selectSchedule = (scheduleInput) => {
		setScheduleSelectedEdit(scheduleInput)
		setOpenCanvasEditTeacherSchedule(true);
	}
	useEffect(() => {
		setMaxRows(Math.max(...schedulesData.map(schedule => schedule.length)));
	}, [schedulesData]);
	useEffect(() => {
		setIsRight(openCanvas);
		const getTeacherSchedule = async () => {
			const response = await fetch(`${process.env.REACT_APP_API_URL}teacherSchedule/getAllSchedulesByTeacher?teacherId=${teacherId}&schoolYear=${schoolYearSelected}`, {
				headers: getHeaderSimpleToken(),
			});

			const { message, data } = await response.json();
			setMaxRows(Math.max(...data.map(schedule => schedule.length)));
			if (message === 'Ok') setSchedulesData(data)
		}
		getTeacherSchedule();

	}, [openCanvas]);

	// delete schedule but give a message to confirm and then delete
	const deleteSchedule = async (scheduleId) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}teacherSchedule/${scheduleId}`, {
			method: 'DELETE',
			headers: getHeaderSimpleToken(),
		});
		const { message } = await response.json();
		if (message === 'Ok') {
			const newCalendar = schedulesData.map((day) => day.filter((scheduleMap) => scheduleMap._id !== scheduleId));

			setSchedulesData(newCalendar);
		}
	}

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-teacher'
			toggle={toggleRightCanvas}
			style={{ width: '80%' }} // Ajustar el ancho del Offcanvas

		>

			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{tittle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<div className='col'>
							<Card id='card-into-off-canvas'>
								<CardHeader className='bg-white'>
									<div className='d-flex'>
										<div className='col-6  h4 text-primary'>
											<i className='fas fa-calendar-day' />
											<span className='span-tittle-text-table'>
												Registros
											</span>
										</div>
										<div className='col-4 h4 text-end'>
											<button
												type='button'
												className='btn btn-primary waves-effect waves-light'
												id='button-style-3'
												onClick={() => {
													setScheduleSelectedEdit([]);
													setOpenCanvasEditTeacherSchedule(true);
													setTitleEditTeacherSchedule('Agregar registro');
												}}>
												<i className='fas fa-plus me-1' />
												Agregar registro
											</button>

										</div>
										<div className='col-2 h3 text-end'>
											<Input type='select' className='form-select' defaultValue="" value={schoolYearSelected} onChange={handleSchoolyearChange}>
												<option value="" disabled>Seleccione un año escolar</option>
												{schoolYears.map((schoolYear, idx) => (
													<option key={idx} value={schoolYear}>{schoolYear}</option>
												))}
											</Input>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<div className='table-responsive table-card mt-3 mb-1'>
										<table
											className='table align-middle table-nowrap'
											id='customerTable'>
											<thead className='table-light'>
												<tr>
													{Object.keys(numToDay).map((day) => (
														<th style={{ border: '1px solid #dee2e6' }}
															className='sort text-center'
															data-sort='customer_name'>
															{numToDay[day]}
														</th>
													))
													}

												</tr>
											</thead>
											<tbody className='list form-check-all'>
												{

													Array.from({ length: maxRows }).map((_, rowIndex) => (
														<tr key={rowIndex}  >
															{schedulesData.map((schedule, dayIndex) => (
																// add hover


																<td key={dayIndex} className='text-center td-teacher-column-schedule' >
																	{schedule[rowIndex]?.subject && (

																		<i className='fas fa-trash-alt me-2 mt-1 td-teacher-column-schedule-icon-delete' onClick={() => {
																			setDeleteModal(true)
																			setSelectItemDeleted(schedule[rowIndex]._id)
																		}} />

																	)}
																	<div onClick={() => selectSchedule(schedule[rowIndex])}>

																		{schedule[rowIndex]?.subject?.name.substring(0, 3)} {schedule[rowIndex]?.grade}{schedule[rowIndex]?.group?.name}
																		<br />
																		{[schedule[rowIndex]?.startTime, schedule[rowIndex]?.endTime].filter(x => x !== undefined).join(' - ')}

																	</div>

																</td>

															))}
														</tr>
													))

												}
											</tbody>
										</table>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
					{
						scheduleSelectedEdit && (
							<EditTeacherSchedule
								onlyClose={onlyCloseEditTeacherSchedule}
								openCanvas={openCanvasEditTeacherSchedule}
								title={titleEditTeacherSchedule}
								calendar={structuredClone(schedulesData)}
								originalCalendar={schedulesData}
								setOriginalCalendar={setSchedulesData}
								subject={scheduleSelectedEdit.subject}
								group={scheduleSelectedEdit.group}
								level={scheduleSelectedEdit.grade}
								day={scheduleSelectedEdit.day}
								teacherId={teacherId}
								schoolYearSelected={schoolYearSelected}
							/>)
					}
					<Modal
						isOpen={deleteModal}
						toggle={() => {
							setDeleteModal(false);
							setSelectItemDeleted(null);
						}}>
						<div className='modal-header'>
							<h5 className='modal-title'>Eliminar registro</h5>
							<button
								type='button'
								className='btn-close'
								onClick={() => {
									setDeleteModal(false);
									setSelectItemDeleted(null);
								}}
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>
							<div className='mb-3'>
								<p>¿Estás seguro de querer eliminar el registro?</p>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-secondary'
								data-bs-dismiss='modal'
								onClick={() => {
									setDeleteModal(false);
									setSelectItemDeleted(null);
								}}>
								No
							</button>
							<button
								type='button'
								className='btn btn-primary'
								onClick={() => {
									deleteSchedule(selecItemDeleted);
									setDeleteModal(false);
									setSelectItemDeleted(null);
								}}>
								Si
							</button>
						</div>
					</Modal>
				</Container>
			</OffcanvasBody>
		</Offcanvas>
	);
};

CalendarTeacherSchedule.propTypes = {
	openCanvas: PropTypes.bool,
};
CalendarTeacherSchedule.defaultProps = {
	openCanvas: false,
};

export default CalendarTeacherSchedule;

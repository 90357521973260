
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	Input,
	Spinner,
	CardFooter,
} from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { getHeaderSimpleToken } from '../Utility/utils';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const numToDay = {
	0: 'Lunes',
	1: 'Martes',
	2: 'Miércoles',
	3: 'Jueves',
	4: 'Viernes',
	5: 'Sábado',
	6: 'Domingo',
};

const toastStyle = {
	position: 'top-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'light',
};

const EditTeacherSchedule = ({ openCanvas, title, onlyClose, calendar, subject, group, level, day, originalCalendar, teacherId, setOriginalCalendar,schoolYearSelected }) => {
	const [selectedDays, setSelectedDays] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([])
	const [initialValues, setInitialValues] = useState({});

	const handleCheckboxChange = (checkedItems) => {
		setSelectedOptions(checkedItems)
		checkedItems = checkedItems.sort((a, b) => a.value - b.value);
		let calendarFilter = checkedItems.map(item => {

			const res = calendar[item.value]?.filter(day => day.subject._id === selectedSubject && day.group._id === selectedGroup && day.grade === selectedLevel)
			if (res?.length === 0) {
				return [{ _id: item.value, day: item.value, startTime: "00:00", endTime: "00:00", subject: selectedSubject, group: selectedGroup, level: selectedLevel }]
			}
			return res
		}).flat()
		// delete undefined from calendarFilter
		calendarFilter = calendarFilter.filter((day) => day !== undefined);
		setSelectedDays(calendarFilter)
	};


	// get the objects of all days from the group and subject and level from the calendar
	const getAllSchedulesBySujectLevelGroup = () => {
		const schedulesSubjectLevelGroup = [];

		originalCalendar.forEach((day) => {
			day.forEach((schedule) => {
				if (schedule.subject._id === selectedSubject && schedule.group._id === selectedGroup && schedule.grade === selectedLevel) {
					schedulesSubjectLevelGroup.push(schedule);
				}
			})
		})

		return schedulesSubjectLevelGroup;
	}

	const checkChanges = () => {
		const schedulesBySubjectLevelGroup = getAllSchedulesBySujectLevelGroup();
		const schedulesBySubjectLevelGroupIds = schedulesBySubjectLevelGroup.map((schedule) => schedule._id);
		const schedulesToAdd = selectedDays.filter((day) => !schedulesBySubjectLevelGroupIds.includes(day._id));
		const schedulesToUpdate = selectedDays.filter((day) => schedulesBySubjectLevelGroupIds.includes(day._id) && (day.startTime !== schedulesBySubjectLevelGroup.find((schedule) => schedule._id === day._id).startTime || day.endTime !== schedulesBySubjectLevelGroup.find((schedule) => schedule._id === day._id).endTime));
		return [schedulesToAdd, schedulesToUpdate];
	}


	useEffect(() => {
		if (day === undefined || day === null) return

		handleCheckboxChange([{ value: day, label: numToDay[day] }])

	}, []);


	const options = [
		{ value: 0, label: 'Lunes' },
		{ value: 1, label: 'Martes' },
		{ value: 2, label: 'Miércoles' },
		{ value: 3, label: 'Jueves' },
		{ value: 4, label: 'Viernes' },
		{ value: 5, label: 'Sábado' },
		{ value: 6, label: 'Domingo' },
	];


	const [isRight, setIsRight] = useState(false);
	const [subjects, setSubjects] = useState([]);
	const [groups, setGroups] = useState([]);
	const [levels, setLevels] = useState([]);
	const [selectedSubject, setSelectedSubject] = useState(subject?._id === undefined ? "" : subject._id);
	const [selectedGroup, setSelectedGroup] = useState(group?._id === undefined ? "" : group._id);
	const [selectedLevel, setSelectedLevel] = useState(level === undefined ? "" : level);

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};


	function getLevelData() {
		const body = {
			educativeOfferType: localStorage.getItem('EDUCATIVE_OFFER_TYPE'),
		};

		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}level`,
			data: body,
			headers: getHeaderSimpleToken(),
		})
			.then((response) => {
				if (response.message === 'Ok') {
					setLevels(response.data.map((level) => level.name));
				} else {
					setLevels([]);
				}
			})
			.catch((error) => { });
	}

	function getSubjects() {
		fetch(`${process.env.REACT_APP_API_URL}subject`, {
			headers: getHeaderSimpleToken(),

		})
			.then((response) => response.json())
			.then((subjectsData) => {
				setSubjects(subjectsData.data)
			});
	}

	function getGroups() {
		fetch(`${process.env.REACT_APP_API_URL}grouplevel`, {
			headers: getHeaderSimpleToken(),
		})
			.then((response) => response.json())
			.then((groupsData) => {
				setGroups(groupsData.data);
			});
	}
	const handleGroupChange = (e) => {
		setSelectedDays([]);
		setSelectedGroup(e.target.value);
		setSelectedOptions([])
	}

	const handleSubjectChange = e => {
		setSelectedDays([]);
		setSelectedSubject(e.target.value);
		setSelectedOptions([])
	}

	const handleLevelChange = e => {
		setSelectedDays([]);
		setSelectedLevel(e.target.value);
		setSelectedOptions([])
	}

	const handleStartTimeChange = (index, event) => {
		const newSelectedDays = selectedDays.map((day) => {
			if (day.day === index) {
				day.startTime = event.target.value;
			}
			return day;
		})

		setSelectedDays(newSelectedDays);
	};

	// Función para manejar el cambio de hora de fin
	const handleEndTimeChange = (index, event) => {
		const newSelectedDays = selectedDays.map((day) => {
			if (day.day === index) {
				day.endTime = event.target.value;
			}
			return day;
		});
		setSelectedDays(newSelectedDays);
	};


	const showSubjects = (subjects) => {
		return subjects.map((subject) => (
			<option key={subject._id} value={subject._id}>
				{subject.name}
			</option>
		));
	};

	const showGroups = (groups) => {
		return groups.map((group) => (
			<option key={group._id} value={group._id}>
				{group.name}
			</option>
		));
	};

	const showLevels = (levelsInput) => {
		return levelsInput.map((level) => (
			<option key={level} value={level}>
				{level}
			</option>
		));
	}

	useEffect(() => {
		setIsRight(openCanvas);
		getSubjects();
		getGroups();
		getLevelData();
	}, [openCanvas]);

	const validationSchema = Yup.object().shape({
		schedules: Yup.array().of(
			Yup.object().shape({
				day: Yup.number().required('Required'),
			})
		).min(1, "Selecciona al menos un día")
		,
		subject: Yup.string().required('Por favor, ingrese la materia'),
		group: Yup.string().required('Por favor, ingrese el grupo'),
		level: Yup.string().required('Por favor, ingrese el grado'),
	});
	useEffect(() => {

		// Initial form values
		const initialValuesTmp = {
			schedules: selectedDays.map(schedule => ({
				day: schedule.day,
				startTime: schedule.startTime,
				endTime: schedule.endTime
			})),
			subject: selectedSubject,
			group: selectedGroup,
			level: selectedLevel,
		};

		setInitialValues(initialValuesTmp);
	}, [selectedDays, selectedSubject, selectedGroup, selectedLevel]);



	const saveSchedules = (values, { setSubmitting }) => {
		const [schedulesToAdd, schedulesToUpdate] = checkChanges();
		if (schedulesToAdd.length === 0 && schedulesToUpdate.length === 0) {
			toast.error(`No se han realizado cambios`, toastStyle);
			setSubmitting(false);
			return;
		}

		fetch(`${process.env.REACT_APP_API_URL}teacherSchedule/updateCreateItems`, {
			method: 'POST',
			body: JSON.stringify({
				scheduleUpdate: schedulesToUpdate,
				scheduleCreate: schedulesToAdd,
				groupId: selectedGroup,
				subjectId: selectedSubject,
				level: selectedLevel,
				schoolYear: schoolYearSelected,
				teacherId: teacherId
			}),
			headers: getHeaderSimpleToken()
		}).then((response) => response.json())
			.then((sched) => {
				sched.data.updatedSchedules.forEach((schedule) => {
					originalCalendar[schedule.schedule.day].forEach((day) => {
						if (day._id === schedule._id) {
							day.startTime = schedule.schedule.startTime;
							day.endTime = schedule.schedule.endTime;
						}
					})
				})

				sched.data.createdSchedules.forEach((schedule) => {
					const newSchedule = {
						_id: schedule._id,
						day: schedule.schedule.day,
						startTime: schedule.schedule.startTime,
						endTime: schedule.schedule.endTime,
						subject: { _id: schedule.subject, name: subjects.find((subject) => subject._id === schedule.subject).name },
						group: { _id: schedule.group, name: groups.find((group) => group._id === schedule.group).name },
						grade: schedule.grade

					}
					originalCalendar[schedule.schedule.day].push(newSchedule);
					let newSelectedDays = selectedDays.map((day) => {
						if (day._id === schedule.schedule.day) {
							day._id = schedule._id;
						}
						return day
					})
					setSelectedDays(newSelectedDays);
				})

				originalCalendar.forEach((day) => {
					day.sort((a, b) => a.startTime.localeCompare(b.startTime));
				})
				sched.data.updatedSchedules.forEach((schedule) => {
					toast.success(`Horario ${numToDay[schedule.schedule.day]} actualizado`, toastStyle);
				})
				sched.data.createdSchedules.forEach((schedule) => {
					toast.success(`Horario ${numToDay[schedule.schedule.day]} creado`, toastStyle);
				})
				sched.invalidsSchedulesIds.forEach((invalidSche) => {
					toast.error(`Error en el día ${numToDay[invalidSche.day]} ${invalidSche.message} `, toastStyle);
				})

				setOriginalCalendar([...originalCalendar])
				setSubmitting(false);

			})
	}

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-teacher'
			toggle={toggleRightCanvas}
			style={{ width: '45%' }} // Ajustar el ancho del Offcanvas
		>

			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{title}</span>
			</OffcanvasHeader>
			<OffcanvasBody style={{ height: '100%' }}>
				<Container fluid style={{ height: '100%' }}>

					<div className='row' style={{ height: '100%' }}>
						<div className='col' style={{ height: '100%' }}>
							<Card id='card-into-off-canvas' style={{ height: '100%' }}>
								<CardBody style={{ height: '100%' }}>
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={saveSchedules}
										enableReinitialize={true}
										style={{ height: '100%' }}
									>
										{({ values, handleChange, isSubmitting, errors }) => (
											<Form style={{ height: '100%' }}>
												<div className='table-responsive table-card mt-3 mb-1' style={{ height: '100%' }}>
													<table
														className='table align-middle table-nowrap'
														id='customerTable'>
														<thead>
															<tr>
																<th
																	className='sort'
																	data-sort='customer_name'>
																	<label className='col-form-label text-muted'>
																		Materia
																	</label>
																	<Input type='select' className='form-select' defaultValue="" value={selectedSubject} onChange={handleSubjectChange}
																		invalid={errors.subject ? true : false}
																	>
																		<option value={""} disabled={true} >Selecciona</option>
																		{showSubjects(subjects)}
																	</Input>
																	<ErrorMessage name='subject' component='span' className='text-danger small font-weight-lighter' />
																</th>
																<th
																	className='sort'
																	data-sort='customer_name'>
																	<label className='col-form-label text-muted'>
																		Grado
																	</label>
																	<Input type='select' className='form-select' defaultValue="" value={selectedLevel} onChange={handleLevelChange}
																		invalid={errors.level ? true : false}
																	>
																		<option value={""} disabled={true}>Selecciona</option>
																		{showLevels(levels)}
																	</Input>
																	<ErrorMessage name='level' component='span' className='text-danger small font-weight-lighter'/>
																</th>

																<th
																	className='sort'
																	data-sort='customer_name'>
																	<label className='col-form-label text-muted'>
																		Grupo
																	</label>
																	<Input type='select' className='form-select' defaultValue="" value={selectedGroup} onChange={handleGroupChange}
																		invalid={errors.group ? true : false}
																	>
																		<option value={""} disabled={true} >Selecciona</option>
																		{showGroups(groups)}
																	</Input>
																	<ErrorMessage name='group' component='span' className='text-danger small font-weight-lighter' />
																</th>
															</tr>
														</thead>
														<div />
													</table>
													<label className='col-form-label text-muted'>
														Días
													</label>
													<Select placeholder='Selecciona' menuPlacement='auto'
														defaultValue={options[day]}
														isMulti
														onChange={handleCheckboxChange}
														options={options}
														value={selectedOptions}
														name='days'

													/>
													<strong>
														<ErrorMessage name='schedules' component='span' className='text-danger small font-weight-lighter' />
													</strong>

													<FieldArray name="schedules">
														{({ remove, }) => (
															<>
																<table className='table align-middle table-nowrap'>
																	<thead className='table-light'>
																		<tr>
																			<th className='sort text-center' data-sort='customer_name'>Día</th>
																			<th className='sort text-center' data-sort='customer_name'>Hora de inicio</th>
																			<th className='sort text-center' data-sort='customer_name'>Hora de fin</th>
																		</tr>
																	</thead>
																	<tbody className='list'>
																		{values.schedules.map((schedule, index) => {
																			return (
																				<tr key={index}>
																					<td className='text-center'>{numToDay[schedule.day]}</td>
																					<td className=''>
																						<Input
																							type='time'
																							className='form-control'
																							name={`schedules.${index}.startTime`}
																							placeholder='Hora de inicio'
																							value={schedule.startTime}
																							onChange={(e) => {
																								handleChange(e);
																								handleStartTimeChange(schedule.day, e);
																							}} />
																					</td>


																					<td className='text-center'>
																						<Input
																							type='time'
																							className='form-control'
																							name={`schedules.${index}.endTime`}
																							placeholder='Hora de fin'
																							value={schedule.endTime}
																							onChange={(e) => {
																								handleChange(e);
																								handleEndTimeChange(schedule.day, e);
																							}} />

																					</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</table>
															</>
														)}
													</FieldArray>
													<CardFooter id='card-footer-off-canvas'>
														<div className='d-flex justify-content-center'>
															{isSubmitting ? <Spinner id='spinner-style-1' size='lg' /> : (
																<>
																	<button type='submit' className='btn btn-success waves-effect waves-light w-50' id='create-btn'>
																		<i className='fas fa-save me-1' /> Guardar

																	</button>

																</>
															)}
														</div>
													</CardFooter>
												</div>
											</Form>
										)}
									</Formik>

								</CardBody>
							</Card>
						</div>
					</div>
				</Container>
				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

EditTeacherSchedule.propTypes = {
	openCanvas: PropTypes.bool,
};
EditTeacherSchedule.defaultProps = {
	openCanvas: false,
};

export default EditTeacherSchedule;

import React from "react";
import ReactApexChart from "react-apexcharts";


const LineColumnAreaData = {
  series: [
    {
      name: "Ingresos",
      type: "column",
      data: [87, 75, 89, 49, 59, 78, 53, 95, 78, 58, 45, 75],
    },
    {
      name: "Gastos",
      type: "area",
      data: [25, 32, 24, 24, 14, 27, 13, 26, 32, 17, 25, 17],
    },
    {
      name: "Utilidad",
      type: "line",
      data: [52, 73, 78, 60, 53, 74, 91, 81, 85, 87, 77, 71],
    },
  ],
  options: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 0.5, 1],
      curve: "smooth",
      dashArray: [0, 8, 5]
    },
    plotOptions: {
      bar: {
        columnWidth: "18%",
      },
    },
    colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      type: "month",
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0)
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  },
}

const LineColumnArea = () => {
  return(
    <React.Fragment>
        <ReactApexChart
          options={LineColumnAreaData.options}
          series={LineColumnAreaData.series}
          type="line"
          height="350"
          stacked= "false"
          className="apex-charts"
        />
      </React.Fragment>
  )
}

export default LineColumnArea;
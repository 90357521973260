import React from 'react';
import { Col, Container } from 'reactstrap';

const CommonSuperDashboard = () => {
	document.title = 'Kuali';
	return (
		<React.Fragment>
			<div className='page-content'>
				<Container fluid={true}>
					{/* User Panel Charts */}
					<Col md={12} lg={12} xl={12}>
						<div className='text-center'>
							<div>
								<h1 className='display-2 error-text fw-bold'>Kuali</h1>
							</div>
						</div>
					</Col>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CommonSuperDashboard;

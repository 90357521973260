import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Form, FormFeedback, Toast} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getHeaderSimpleToken } from '../Utility/utils';

const AddNewSale = ({ show, handleClose, studentId, saleTotalAmount, orderList }) => {
	const formatter = new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
		minimumFractionDigits: 2,
	});
	const paymentMethods = ['Efectivo', 'Tarjeta', 'Kuali Pay', 'PayPal'];
	const [totalRecievedAmount, setTotalRecievedAmount] = useState(0);
	const [changeAmount, setChangeAmount] = useState(0);
	const [studentWallet, setStudentWallet] = useState({});
	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const validationSchema = Yup.object().shape({
		rows: Yup.array().of(
			Yup.object().shape({
				cantidad: Yup.number()
					.positive('La cantidad debe ser mayor a 0')
					.when('metodoPago', {
						is: 'Kuali Pay',
						then: Yup.number().max(studentWallet, 'El saldo es insuficiente'),
					}),
				referencia: Yup.string(),
			}),
		),
	});

	const initialValues = {
		rows: [{ id: 1, metodoPago: 'Efectivo', cantidad: '', referencia: '' }, { id: 2, metodoPago: 'Tarjeta', cantidad: '', referencia: '' }, { id: 3, metodoPago: 'Kuali Pay', cantidad: '', referencia: '' }],
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			submitForm();
		},
	});

	const handleRowChange = (index, field, value) => {
		const newRows = [...formik.values.rows];
		newRows[index][field] = value;
		formik.setFieldValue('rows', newRows);
	};

	const updateStudentWalletBalance = async () => {
		try {
			const kualiPayRow = formik.values.rows.find((row) => row.metodoPago === 'Kuali Pay');

			if (kualiPayRow === undefined) {
				return;
			}

			const kualiPayAmount = kualiPayRow.cantidad;

			const response = await axios({
				method: 'put',
				url: `${process.env.REACT_APP_API_URL}/wallet/`,
				headers: getHeaderSimpleToken(),
				data: {
					studentId: studentId,
					amountToSubtract: kualiPayAmount,
				},
			});
		} catch (error) {
			console.log(error);
		} /*  */
	};

	const getStudentWalletBalance = async () => {
		try {
			const response = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/wallet`,
				headers: getHeaderSimpleToken(),
				data: {
					studentId: studentId,
				},
			});

			setStudentWallet(response[0]?.balance);
		} catch (error) {
			console.log(error);
		}
	};

	const calculateRecievedTotal = () => {
		// Calcular el total recibido y el cambio con formik values
		let totalRecieved = 0;
		formik.values.rows.forEach((row) => {
			if (row.cantidad !== '') {
				totalRecieved += parseFloat(row.cantidad);
			}
		});
		setTotalRecievedAmount(totalRecieved);
		setChangeAmount(totalRecieved - saleTotalAmount);
	};

	const deleteActiveOrders = () => {
		const activeOrders = orderList.filter((order) => order.isOrder === true);

		if (activeOrders.length > 0) {
			activeOrders.forEach((order) => {
				axios({
					method: 'delete',
					url: `${process.env.REACT_APP_API_URL}/activeOrders/${studentId}`,
					headers: getHeaderSimpleToken(),
				})
					.then((response) => {
						console.log('Orden eliminada:', response);
					})
					.catch((error) => {
						console.log('Error al eliminar orden:', error);
					});
			});
		}
	};

	const submitForm = () => {
		if (formik.isValid) {
			if (totalRecievedAmount < saleTotalAmount) {
				toast.error('Monto recibido incompleto', toastStyle);
				return;
			}

			// Realizar el cargo a la cuenta Kuali
			updateStudentWalletBalance();

			// Eliminar en caso de haber las ordenes activas
			deleteActiveOrders();

			const formData = new FormData();
			formData.append('studentId', studentId);
			formData.append('schoolId', localStorage.getItem('SCHOOL_ID'));
			formData.append('educativeOfferId', localStorage.getItem('EDUCATIVE_OFFER_ID'));
			formData.append('saleDate', new Date());
			formData.append('saleAmount', saleTotalAmount);
			formData.append('paymentMethod', JSON.stringify(formik.values.rows));
			formData.append('orderList', JSON.stringify(orderList));
			formData.append('status', 'Pendiente');
			formData.append('tenant', localStorage.getItem('USER_TENANT'));

			axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/sale`,
				headers: getHeaderSimpleToken(),
				data: formData,
			})
				.then((response) => {
					toast.success('Venta realizada con éxito', toastStyle);
					handleClose();
				})
				.catch((error) => {
					console.log(error);
					toast.error('Error al realizar la venta', toastStyle);
				});
		}
	};

	useEffect(() => {
		console.log('studentId', studentId);
		console.log('saleTotalAmount', saleTotalAmount);
		console.log('orderList', orderList);
	}, [show]);

	useEffect(() => {
		getStudentWalletBalance();
	}, [studentId]);

	useEffect(() => {
		calculateRecievedTotal();
	}, [formik.values]);
	return (
		<Modal isOpen={show} size='lg'>
			<div className='modal-header'>
				<h3 className='modal-title'><strong>Realizar pago</strong></h3>
				<button
					type='button'
					className='btn-close'
					aria-label='Close'
					onClick={() => handleClose()}
				/>
			</div>
			<div className='modal-body'>
				{/* Encabezados estáticos */}
				<div className='d-flex row justify-content-between px-4 mb-3'>
					<div className='col-md-3'>
						<div className='mb-3'>
							<h5 className='form-label'></h5>
						</div>
					</div>
					<div className='col-md-3'>
						<div className='mb-3 text-center'>
							<h5 className='form-label'>Saldo</h5>
						</div>
					</div>
					<div className='col-md-3 text-center'>
						<div className='mb-3'>
							<h5 className='form-label'>Cantidad</h5>
						</div>
					</div>
					<div className='col-md-3'>
						<div className='mb-3'>
							<h5 className='form-label text-center'>Referencia</h5>
						</div>
					</div>
				</div>

				{/* Contenedor de las filas de campos */}
				<Form>
					{formik.values.rows.map((fila, index) => (
						<div
							key={fila.id}
							className='d-flex row justify-content-between align-items-baseline px-4 mb-3'>
							{/* Campo de Método de pago */}
							<div className='col-md-3'>
								<div className='mb-3'>
									<h5 className='form-label'>
										<strong>{fila.metodoPago}</strong>
									</h5>
								</div>
							</div>
							{/* Campo de Saldo */}
							<div className='col-md-3'>
								<div className='mb-3 text-center'>
									{fila.metodoPago === 'Kuali Pay' && (
										<h5 className='form-label'>
											<strong>{formatter.format(studentWallet)}</strong>
										</h5>
									)}
								</div>
							</div>
							{/* Campo de Cantidad */}
							<div className='col-md-3'>
								<div className='mb-3'>
									<div className='input-group mb-3'>
										<span className='input-group-text'>$</span>
										<Input
											type='text'
											className='form-control'
											value={fila.cantidad}
											maxLength={9}
											invalid={
												formik.errors.rows &&
												formik.errors.rows[index]?.cantidad
													? true
													: false
											}
											onChange={(e) => {
												handleRowChange(index, 'cantidad', e.target.value);
											}}
										/>
										{formik.errors.rows && formik.errors.rows[index] && (
											<FormFeedback type='invalid'>
												<div>{formik.errors.rows[index].cantidad}</div>
											</FormFeedback>
										)}
									</div>
								</div>
							</div>
							{/* Campo de Referencia */}
							<div className='col-md-3'>
								<div className='mb-3'>
									<Input
										type='text'
										className='form-control'
										hidden={fila.metodoPago !== 'Tarjeta'}
										value={fila.referencia}
										placeholder='(Opcional)'
										onChange={(e) => {
											handleRowChange(index, 'referencia', e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
					))}
				</Form>
				<div
					className='col-12 mt-5'
					style={{
						display: 'flex',
						alignContent: 'end',
						justifyContent: 'end',
					}}>
					<div className='col-10 text-end'>
						<div>
							<h4>Total</h4>
						</div>
						<div>
							<h4>Recibido</h4>
						</div>
						<div>
							<h4>Cambio</h4>
						</div>
					</div>
					<div className='col-2 text-end'>
						<div>
							<h4>
								<strong>{formatter.format(saleTotalAmount)}</strong>
							</h4>
						</div>
						<div>
							<h4>
								<strong>
									{totalRecievedAmount > 0
										? formatter.format(totalRecievedAmount)
										: '$0.00'}
								</strong>
							</h4>
						</div>
						<div>
							<h4>
								<strong>
									{changeAmount > 0 ? formatter.format(changeAmount) : '$0.00'}
								</strong>
							</h4>
						</div>
					</div>
				</div>
			</div>
			<div className='modal-footer'>
				<button
					type='button'
					className='btn btn-secondary'
					data-bs-dismiss='modal'
					onClick={() => {
						handleClose();
					}}>
					Cancelar
				</button>
				<button
					type='button'
					className='btn btn-primary'
					style={{ backgroundColor: '#40C074' }}
					onClick={formik.handleSubmit}>
					Pagar
				</button>
			</div>
			<ToastContainer />
		</Modal>
	);
};

export default AddNewSale;

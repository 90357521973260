/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Container,
	Card,
	Offcanvas,
	OffcanvasHeader,
	OffcanvasBody,
	CardBody,
	CardFooter,
	CardImg,
	Input,
	FormFeedback,
	Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import avatarDefault from '../../assets/images/users/AdminAvatar.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getHeaderSimpleToken } from '../Utility/utils';
import moment from 'moment';

const AddNewPersonnel = ({
	openCanvas,
	mainTitle,
	editItem,
	cleanForm,
	refreshPage,
	onlyClose,
}) => {
	const [isRight, setIsRight] = useState(false);
	const [avatarImage, setAvatarImage] = useState('');
	const [canvasTitle, setCanvasTitle] = useState('Agregar registro');

	const dummyStatus = [
		{ label: 'Activo', value: 'Activo' },
		{ label: 'Baja', value: 'Baja' },
		{ label: 'Baja temporal', value: 'Baja temporal' },
	];

	const dummyDegrees = [
		{ label: 'Preparatoria', value: 'Preparatoria' },
		{ label: 'Licenciatura', value: 'Licenciatura' },
		{ label: 'Maestría', value: 'Maestría' },
		{ label: 'Doctorado', value: 'Doctorado' },
	];

	const toastStyle = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	};

	const avatarImageUpload = () => {
		// eslint-disable-next-line no-unused-expressions
		document.getElementById('file').files[0];
		setAvatarImage(URL.createObjectURL(document.getElementById('file').files[0]));
	};

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: '',
			lastname: '',
			phone: '',
			email: '',
			position: '',
			degree: '',
			startdate: '',
			status: '',
			typejob: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Por favor, ingrese el nombre'),
			lastname: Yup.string().required('Por favor, ingrese su apellido'),
			phone: Yup.string()
				.matches(phoneRegExp, 'No es un numero de teléfono valido')
				.required('Por favor ingrese un número de teléfono valido'),
			email: Yup.string()
				.required('Se requiere ingresar un correo válido')
				.email('El formato de correo no es correcto'),
			position: Yup.string().required('Por favor, ingrese el cargo'),
			degree: Yup.string().required('Por favor, ingrese el grado académico'),
			startdate: Yup.string().required('Por favor, ingrese el día de inicio de labores'),
			status: Yup.string().required('Campo requerido, favor de seleccionar una opción'),
			typejob: Yup.string().required('Por favor, ingrese el tipo de plaza'),
		}),
		onSubmit: () => {
			if (editItem !== null) {
				updatePersonnel(editItem);
			} else {
				addPersonnel();
			}

			onlyClose();
		},
	});

	const toggleRightCanvas = () => {
		setIsRight(!isRight);
	};

	const cleanFields = () => {
		formik.setValues({});
		formik.resetForm();
	};

	const addPersonnel = async () => {
		const formData = new FormData();
		formData.append('name', formik.values.name);
		formData.append('lastname', formik.values.lastname);
		formData.append('phone', formik.values.phone);
		formData.append('email', formik.values.email);
		formData.append('position', formik.values.position);
		formData.append('degree', formik.values.degree);
		formData.append('startdate', formik.values.startdate);
		formData.append('status', formik.values.status);
		formData.append('typejob', formik.values.typejob);
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}personnel`,
			data: formData,
			headers: getHeaderSimpleToken(),
		}).then((response) => {
			formik.setValues({});
			if (response.message === 'Ok') {
				toast.success('La operación se realizó con éxito', toastStyle);
				refreshPage();
			} else {
				toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
			}
		});
	};
	const updatePersonnel = async (item) => {
		const body = {
			name: formik.values.name,
			lastname: formik.values.lastname,
			phone: formik.values.phone,
			email: formik.values.email,
			position: formik.values.position,
			degree: formik.values.degree,
			startdate: formik.values.startdate,
			typejob: formik.values.typejob,
			status: formik.values.status,
			image: formik.values.image,
		};

		axios({
			method: 'PUT',
			url: `${process.env.REACT_APP_API_URL}personnel/${item._id}`,
			data: body,
			headers: getHeaderSimpleToken(),
		})
			.then((response) => {
				if (response.message === 'Ok') {
					toast.success('La operación se realizó con éxito', toastStyle);
					toggleRightCanvas();
					refreshPage();
				} else {
					toast.error('ERROR, no se realizó la operación correctamete', toastStyle);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (cleanForm) {
			cleanFields();
		}
		setIsRight(openCanvas);
		setAvatarImage(avatarDefault);
		setCanvasTitle(mainTitle);

		if (editItem !== null) {
			formik.setValues({
				_id: editItem._id,
				name: editItem.name,
				lastname: editItem.lastname,
				phone: editItem.phone,
				email: editItem.email,
				position: editItem.position,
				degree: editItem.degree,
				startdate: moment(editItem.startdate).format(moment.HTML5_FMT.DATE),
				status: editItem.status,
				typejob: editItem.typejob,
			});
		}
	}, [openCanvas, editItem]);

	return (
		<Offcanvas
			isOpen={isRight}
			direction='end'
			scrollable
			onClosed={() => {
				onlyClose();
			}}
			id='off-canvas-personnel'
			toggle={toggleRightCanvas}>
			<OffcanvasHeader
				id='off-canvas-header'
				onClosed={() => {
					onlyClose();
				}}
				toggle={toggleRightCanvas}>
				<span className='h3'>{canvasTitle}</span>
			</OffcanvasHeader>
			<OffcanvasBody>
				<Container fluid>
					<div className='row'>
						<Form className='form-horizontal' onSubmit={formik.handleSubmit}>
							<div>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div className='row d-flex justify-content-between'>
											<div className='col-2'>
												<CardImg
													id='img-bgc-g'
													src={avatarImage}
													className='rounded avatar-lg'
												/>
											</div>
											<div className='col-9'>
												<div className='customfile'>
													<Input
														type='file'
														name='file'
														className='inputfile'
														id='file'
														accept='image/jpg, image/jpeg, image/png'
														lang='es'
														onChange={(e) => {
															// eslint-disable-next-line no-unused-expressions
															formik.setFieldValue(
																'avatar',
																e.currentTarget.files[0],
															);
															avatarImageUpload();
														}}
													/>
												</div>
												<div className='mt-2'>
													<span>
														Colocar la imagen ayuda a aumentar la
														seguridad en la institución.
													</span>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<Card id='card-into-off-canvas'>
									<CardBody>
										<div>
											<h4>Datos Personales</h4>
										</div>
										<div className='row'>
											<div className='col-6 mt-0'>
												<label
													htmlFor='name'
													className='col-form-label text-muted'>
													Nombre
												</label>
												<Input
													className='form-control'
													type='text'
													id='name'
													name='name'
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.name && formik.errors.name
															? true
															: false
													}
												/>
												{formik.touched.name && formik.errors.name ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.name}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='lastname'
													className='col-form-label text-muted'>
													Apellidos
												</label>

												<Input
													className='form-control'
													type='text'
													id='lastname'
													name='lastname'
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.lastname &&
														formik.errors.lastname
															? true
															: false
													}
												/>
												{formik.touched.lastname &&
												formik.errors.lastname ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.lastname}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='phone'
													className='col-form-label text-muted'>
													Teléfono
												</label>
												<Input
													className='form-control'
													type='text'
													id='phone'
													name='phone'
													value={formik.values.phone}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.phone && formik.errors.phone
															? true
															: false
													}
												/>
												{formik.touched.phone && formik.errors.phone ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.phone}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='email'
													className='col-form-label text-muted'>
													Correo
												</label>

												<Input
													className='form-control'
													type='text'
													id='email'
													name='email'
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.email && formik.errors.email
															? true
															: false
													}
												/>
												{formik.touched.email && formik.errors.email ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.email}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='mt-3'>
												<h4>Datos laborales</h4>
											</div>
											<div className='col-6 mt-0'>
												<label
													htmlFor='position'
													className='col-form-label text-muted'>
													Cargo
												</label>
												<Input
													className='form-control'
													type='text'
													id='position'
													name='position'
													value={formik.values.position}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.position &&
														formik.errors.position
															? true
															: false
													}
												/>
												{formik.touched.position &&
												formik.errors.position ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.position}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-6 mt-0'>
												<label className='col-form-label text-muted'>
													Grado académico
												</label>
												<Input
													type='select'
													name='degree'
													value={formik.values.degree}
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													invalid={
														formik.touched.degree &&
														formik.errors.degree
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{dummyDegrees.map((s) => (
														<option key={s.value} value={s.value}>
															{s.label}
														</option>
													))}
												</Input>

												{formik.touched.degree && formik.errors.degree ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.degree}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='startdate'
													className='col-md-12 col-form-label text-muted'>
													Fecha de inicio
												</label>
												<Input
													className='form-control'
													type='date'
													id='startdate'
													name='startdate'
													value={formik.values.startdate}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.startdate &&
														formik.errors.startdate
															? true
															: false
													}
												/>
												{formik.touched.startdate &&
												formik.errors.startdate ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.startdate}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='typejob'
													className='col-md-12 col-form-label text-muted'>
													Tipo de plaza
												</label>
												<Input
													className='form-control'
													type='text'
													id='typejob'
													name='typejob'
													value={formik.values.typejob}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													invalid={
														formik.touched.typejob &&
														formik.errors.typejob
															? true
															: false
													}
												/>
												{formik.touched.typejob && formik.errors.typejob ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.typejob}</div>
													</FormFeedback>
												) : null}
											</div>
											<div className='col-4 mt-0'>
												<label
													htmlFor='status'
													className='col-md-12 col-form-label text-muted'>
													Estado
												</label>
												<Input
													type='select'
													name='status'
													value={formik.values.status}
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													invalid={
														formik.touched.status &&
														formik.errors.status
															? true
															: false
													}>
													<option value=''>Selecciona</option>
													{dummyStatus.map((s) => (
														<option key={s.label} value={s.value}>
															{s.label}
														</option>
													))}
												</Input>

												{formik.touched.status && formik.errors.status ? (
													<FormFeedback type='invalid'>
														<div>{formik.errors.status}</div>
													</FormFeedback>
												) : null}
											</div>
										</div>
									</CardBody>
								</Card>
								<CardFooter id='card-footer-off-canvas'>
									<div className='d-flex justify-content-center'>
										<button
											type='submit'
											className='btn btn-success waves-effect waves-light w-50'
											id='create-btn'>
											<i className='fas fa-save me-1' />
											Guardar
										</button>
									</div>
								</CardFooter>
							</div>
						</Form>
					</div>
				</Container>
				<ToastContainer />
			</OffcanvasBody>
		</Offcanvas>
	);
};

AddNewPersonnel.propTypes = {
	openCanvas: PropTypes.bool,
};
AddNewPersonnel.defaultProps = {
	openCanvas: false,
};

export default AddNewPersonnel;

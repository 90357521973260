const SidebarData = [
	{
		label: 'Dashboard',
		icon: 'mdi mdi-home-variant-outline',
		url: '/dashboardAdmin',
		issubMenubadge: true,
	},
	{
		label: 'Escuelas',
		icon: 'fas fa-school',
		url: '/adminSchool',
		issubMenubadge: true,
	},
	{
		label: 'Docentes',
		icon: 'fas fa-chalkboard-teacher',
		url: '/adminPersonnel',
		issubMenubadge: true,
	},
];

export default SidebarData;
